import { useEffect, useState } from "react";
import { getApi } from "../hooks/getApi";

export function useGetCompanies(country, companyId, search, pagination, limit) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [companiesData, setCompaniesData] = useState([]);
  const [metaData, setMetaData] = useState(null);

  useEffect(() => {
    const getCompany = async () => {
      if (country && search.length > 0) {
        const data = await getApi(
          `admin/companies/${country.id}/search?type=${companyId}&page=${pagination}&search=${search}`,
          setDataLoading
        );
        if (data) {
          setCompaniesData(data.data);
          setMetaData(data.meta);
        }
      } else if (country && search.length == 0) {
        const data = await getApi(
          `admin/companies/${country.id}?type=${companyId}&page=${pagination}&limit=${limit}`,
          setDataLoading
        );
        if (data) {
          setCompaniesData(data?.data);
          setMetaData(data?.meta);
        }
      }
    };
    getCompany();
  }, [pagination, companyId, search, limit]);

  return [companiesData, metaData, dataLoading, error];
}

export function useGetCompanyTypes() {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [companyTypeList, setCompanyTypeList] = useState([]);

  useEffect(() => {
    const getCompanyType = async () => {
      const data = await getApi("/company/types", setDataLoading);

      if (data) {
        setCompanyTypeList(data.data);
      }
    };
    getCompanyType();
  }, []);

  return [companyTypeList, dataLoading, error];
}

export function useGetCompanyBranch(storeID) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [storeInfo, setStoreInfo] = useState([]);

  useEffect(() => {
    const getCompanyBranches = async () => {
      const data = await getApi(`/branches/${storeID}`, setDataLoading);

      if (data) {
        setStoreInfo(data.data);
      }
    };
    getCompanyBranches();
  }, []);

  return [storeInfo, dataLoading, error];
}

export function useGetCompanyRequests(countryId, pagination) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [companiesData, setCompaniesData] = useState([]);
  const [metaData, setMetaData] = useState(null);

  useEffect(() => {
    const getCompanyRequests = async () => {
      const data = await getApi(
        `admin/company/approvals/${countryId}?limit=15&page=${pagination}`,
        setDataLoading
      );

      if (data) {
        setCompaniesData(data.data);
        setMetaData(data.meta);
      }
    };
    getCompanyRequests();
  }, [pagination]);

  return [companiesData, metaData, dataLoading, error];
}

export function useGetCompanyTypesReport(countryId, user) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [companyTotal, setCompanyTotal] = useState([]);

  useEffect(() => {
    const getTotal = async () => {
      const data = await getApi(
        `admin/reports/company-type/total/${countryId}`,
        setDataLoading
      );

      if (data) {
        setCompanyTotal(data.data);
      }
    };
    getTotal();
  }, [user]);

  return [companyTotal, dataLoading, error];
}
export function useGetPlatformCompanies() {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [platformCompanies, setPlatformCompanies] = useState([]);

  useEffect(() => {
    const getPlatformCompanies = async () => {
      const data = await getApi(
        `admin/company/platform-company`,
        setDataLoading
      );

      if (data) {
        setPlatformCompanies(data.data);
      }
    };
    getPlatformCompanies();
  }, []);

  return [platformCompanies, dataLoading, error];
}
