import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../auth/authHandler";
import image from "../assets/AFAP-Logo.png";

export default function Sidebar() {
  const { user, logout } = useContext(AuthContext);
  const [dropDown, setdropDown] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div
      className={`md:w-64 absolute z-20 md:relative ${
        openMenu ? "w-64 bg-green-800" : "w-12 bg-white"
      }`}
    >
      <div
        className="w-2/12 h-4/12 p-4 fixed  block md:hidden"
        onClick={() => setOpenMenu((prev) => !prev)}
      >
        {openMenu ? (
          <i class="fas fa-times text-green-700"></i>
        ) : (
          <i class="fas fa-bars text-green-800"></i>
        )}
      </div>
      <aside
        className={`w-64 h-screen bg-green-900 shadow-md md:block ${
          openMenu ? "block" : "hidden"
        }`}
      >
        <div className="w-64 h-screen fixed md:py-8 pb-8 px-6 bg-green-800">
          <nav className="md:flex justify-center items-center -ml-6 hidden ">
            <img alt="" className=" ml-4 h-10 text-center" src={image} />
          </nav>
          <div className="text-sm h-full  flex flex-col justify-between md:py-8 pb-10">
            <div>
              <div className="bg-green-900 w-full flex justify-between items-center text-white py-2 px-6   rounded-full mt-4 cursor-pointer hover:bg-green-700 hover:text-blue-300">
                <Link className="w-full" to="/">
                  Dashboard
                </Link>
              </div>
              <div
                className={
                  window.location.href.indexOf("/products") !== -1
                    ? "bg-green-700 text-blue-300 w-full py-2 px-6  flex justify-between items-center  rounded-full mt-4 cursor-pointer "
                    : "text-white  bg-green-900 w-full py-2 px-6  flex justify-between items-center  rounded-full mt-4 cursor-pointer hover:bg-green-700 hover:text-blue-300"
                }
              >
                <Link className="w-full" to="/products">
                  Products
                </Link>
              </div>
              {/* <div
                className={
                  window.location.href.indexOf("/sales") !== -1
                    ? "bg-green-700 text-blue-300 py-2 px-6 flex justify-between items-center  w-full rounded-full mt-4 cursor-pointer "
                    : "text-white  bg-green-900  py-2 px-6 flex justify-between items-center  w-full rounded-full mt-4 cursor-pointer hover:bg-green-700 hover:text-blue-300"
                }
              >
                <Link className="w-full" to="/sales">
                  Sales
                </Link>
              </div> */}
              <div
                className={
                  window.location.href.indexOf("/company_requests") !== -1
                    ? "bg-green-700 text-blue-300 py-2 px-6 w-full flex justify-between items-center  rounded-full mt-4 cursor-pointer "
                    : "text-white  bg-green-900  py-2 px-6 flex justify-between items-center  w-full rounded-full mt-4 cursor-pointer hover:bg-green-700 hover:text-blue-300"
                }
              >
                <Link className="w-full" to="/company_requests">
                  <span>Company Requests</span>
                  {/* <span className="w-4 h-4 bg-red-600 rounded-full text-white text-center font-normal text-xs">
                  5
                </span> */}
                </Link>
              </div>
              {/* <div className="bg-green-900 text-white py-2 px-6   rounded-full mt-4 cursor-pointer hover:bg-green-700 hover:text-blue-300 "> */}
              <div
                className={
                  window.location.href.indexOf("/companies") !== -1
                    ? "bg-green-700 text-blue-300 py-2 px-6  flex justify-between items-center   w-full rounded-full mt-4 cursor-pointer "
                    : "text-white  bg-green-900  py-2 px-6 w-full  flex justify-between items-center  rounded-full mt-4 cursor-pointer hover:bg-green-700 hover:text-blue-300"
                }
              >
                <Link className="w-full" to="/companies">
                  Company Management
                </Link>
              </div>
              <div
                className={
                  window.location.href.indexOf("/reports") !== -1
                    ? "bg-green-700 text-blue-300 py-2 px-6 w-full  flex justify-between  rounded-full mt-4 cursor-pointer "
                    : "bg-green-900 text-white py-2 px-6 w-full flex justify-between rounded-full mt-4 cursor-pointer hover:bg-green-700 hover:text-blue-300"
                }
                // className="bg-green-900 text-white py-2 px-6  flex justify-between rounded-full mt-4 cursor-pointer hover:bg-green-700 hover:text-blue-300 "
                onClick={() => {
                  setdropDown(!dropDown);
                }}
              >
                Reports
                {dropDown || window.location.href.indexOf("/reports") !== -1 ? (
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                )}
              </div>

              <div
                className={
                  dropDown || window.location.href.indexOf("/reports") !== -1
                    ? "block pl-4"
                    : "hidden"
                }
              >
                <div
                  className={
                    window.location.href.indexOf(
                      "/reports/stock_availability"
                    ) !== -1
                      ? "bg-green-700 text-blue-300 py-2 px-6 w-full flex justify-between items-center   rounded-full mt-4 cursor-pointer "
                      : "text-white  bg-green-900  py-2 px-6  w-full flex justify-between items-center  rounded-full mt-4 cursor-pointer hover:bg-green-700 hover:text-blue-300"
                  }
                >
                  <Link className="w-full" to="/reports/stock_availability">
                    Stock Availability
                  </Link>
                </div>
                <div
                  className={
                    window.location.href.indexOf("/reports/sale_volume") !== -1
                      ? "bg-green-700 text-blue-300 py-2 px-6 w-full flex justify-between items-center   rounded-full mt-4 cursor-pointer "
                      : "text-white  bg-green-900  py-2 px-6 w-full flex justify-between items-center   rounded-full mt-4 cursor-pointer hover:bg-green-700 hover:text-blue-300"
                  }
                >
                  <Link className="w-full" to="/reports/sale_volume">
                    Sales Volume
                  </Link>
                </div>
                <div
                  className={
                    window.location.href.indexOf("/reports/sale_value") !== -1
                      ? "bg-green-700 text-blue-300 py-2 px-6 w-full flex justify-between items-center   rounded-full mt-4 cursor-pointer "
                      : "text-white  bg-green-900  py-2 px-6 w-full flex justify-between items-center   rounded-full mt-4 cursor-pointer hover:bg-green-700 hover:text-blue-300"
                  }
                >
                  <Link className="w-full" to="/reports/sale_value">
                    Sales Value
                  </Link>
                </div>

                {/* <div className="text-white py-2 px-6  ">Product Volume</div> */}
              </div>
              <div
                className={
                  window.location.href.indexOf("/user/maps") !== -1
                    ? "bg-green-700 text-blue-300 py-2 px-6 w-full flex justify-between items-center  rounded-full mt-4 cursor-pointer "
                    : "text-white  bg-green-900  py-2 px-6  w-full flex justify-between items-center rounded-full mt-4 cursor-pointer hover:bg-green-700 hover:text-blue-300"
                }
              >
                <Link className="w-full" to="/user/maps">
                  Map
                </Link>
              </div>
              <div
                className={
                  window.location.href.indexOf("/announcements") !== -1
                    ? "bg-green-700 text-blue-300 w-full py-2 px-6  flex justify-between items-center  rounded-full mt-4 cursor-pointer "
                    : "text-white  bg-green-900 w-full py-2 px-6  flex justify-between items-center  rounded-full mt-4 cursor-pointer hover:bg-green-700 hover:text-blue-300"
                }
              >
                <Link className="w-full" to="/announcements">
                  Announcements
                </Link>
              </div>
              <div
                className={
                  window.location.href.indexOf("/user_management") !== -1
                    ? "bg-green-700 text-blue-300 w-full py-2 px-6  flex justify-between items-center  rounded-full mt-4 cursor-pointer "
                    : "text-white  bg-green-900 w-full py-2 px-6  flex justify-between items-center  rounded-full mt-4 cursor-pointer hover:bg-green-700 hover:text-blue-300"
                }
              >
                <Link className="w-full" to="/user_management">
                  User Management
                </Link>
              </div>
            </div>
            <div className="flex py-2 px-6 w-full text-white bg-red-500 rounded-full  cursor-pointer text-center text-sm ">
              <button
                className="rounded-full inline-flex items-center "
                onClick={() => logout()}
              >
                <svg
                  className="w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="font-semibold w-full h-full">Logout</span>
              </button>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
}
