import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Template from "../components/template";
import axios from "axios";
import moment from "moment";
import SaleByID from "./SaleById";
import Pagination from "../components/pagination";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export default function Sales() {
  const [sales, setSales] = useState([]);
  const [selected, setSelected] = useState(null);
  const [metaData, setMetaData] = useState(null);
  const [pagination, setPagination] = useState(1);

  useEffect(() => {
    function getUser() {
      const userString = sessionStorage.getItem("user");
      const userCred = JSON.parse(userString);
      return userCred;
    }
    getUser();

    const getSales = async () => {
      // const token = await getToken();
      axios.defaults.headers.common["Authorization"] = `Bearer ${
        getUser().token
      }`;
      const data = await axios
        .get(`admin/sales?page=${pagination}`)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.log("company types", err?.response?.data?.message);
        });
      setSales(data.data);
      setMetaData(data.meta);
    };
    getSales();
  }, []);
  return (
    <Template>
      {!selected && (
        <div className="w-full mt-10  p-4 text-md">
          <h1 className="text-center text-4xl mb-8 text-green-700">Sales</h1>

          <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 pr-10 lg:px-8">
            <div className="align-middle rounded-tl-lg rounded-tr-lg inline-block w-full py-4 overflow-hidden bg-white shadow-lg px-12">
              <div className="flex justify-between">
                <div className="flex flex-row mb-1 sm:mb-0">
                  <div className="relative justify-center items-center pl-2 flex border rounded focus:outline-none focus:bg-white focus:border-gray-500">
                    <svg
                      className="w-6 h-6 text-gray-600"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 6h16M4 10h16M4 14h16M4 18h16"
                      />
                    </svg>
                    <svg
                      className="w-6 h-6 text-gray-600"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M8 9l4-4 4 4m0 6l-4 4-4-4"
                      />
                    </svg>
                    <select className="appearance-none h-full  block  w-full bg-white  text-gray-700 py-2 px-4 pr-8 leading-tight ">
                      <option>5</option>
                      <option>10</option>
                      <option>20</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                  <div className="relative justify-center items-center pl-2 flex border rounded focus:outline-none focus:bg-white focus:border-gray-500">
                    <svg
                      className="w-6 h-6 text-gray-600 "
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                      />
                    </svg>
                    <select className="appearance-none h-full  block  w-full bg-white  text-gray-700 py-2 px-4 pr-8 leading-tight ">
                      <option>All</option>
                      <option>Active</option>
                      <option>Inactive</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="inline-flex border rounded w-4/12 px-2 lg:px-6 h-12 bg-transparent">
                  <div className="flex flex-wrap items-stretch w-full h-full mb-6 relative">
                    <div className="flex">
                      <span className="flex items-center leading-normal bg-transparent rounded rounded-r-none border border-r-0 border-none lg:px-3 py-2 whitespace-no-wrap text-grey-500 text-sm">
                        <svg
                          width="18"
                          height="18"
                          className="w-4 lg:w-auto"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.11086 15.2217C12.0381 15.2217 15.2217 12.0381 15.2217 8.11086C15.2217 4.18364 12.0381 1 8.11086 1C4.18364 1 1 4.18364 1 8.11086C1 12.0381 4.18364 15.2217 8.11086 15.2217Z"
                            stroke="#455A64"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16.9993 16.9993L13.1328 13.1328"
                            stroke="#455A64"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="flex-shrink flex-grow flex-auto leading-normal tracking-wide w-px border border-none border-l-0 rounded rounded-l-none px-3 relative focus:outline-none text-xxs xl:text-xs lg:text-base text-gray-500 font-thin"
                      placeholder="Search"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="align-middle inline-block min-w-full shadow overflow-hidden bg-white shadow-dashboard px-8 pt-3 rounded-bl-lg rounded-br-lg">
              <div className="table-height overflow-y-scroll">
                <table className="min-w-full">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-green-600 tracking-wider">
                        ID
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                        Store Name
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                        Buyer Name
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                        Sale Amount
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                        Sale Type
                      </th>
                      {/* <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                    Request At
                  </th> */}
                      <th className="px-6 py-3 border-b-2 border-gray-300"></th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {sales &&
                      sales.map((sale) => {
                        return (
                          <tr>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                              <div className="flex items-center">
                                <div>
                                  <div className="text-sm leading-5 text-gray-800">
                                    {sale.id}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                              <div className="text-sm leading-5 text-green-900">
                                {sale.invoice.branch.name}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5">
                              {sale.customer.name}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5">
                              GH ¢ {sale.amount_received}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5">
                              {sale.sale_type.type}
                            </td>
                            {/* <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500 text-green-900 text-sm leading-5">
                          {moment(sales.created_at).format("MM/DD/YYYY")}
                        </td> */}
                            <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-500 text-sm leading-5">
                              <button
                                onClick={() => {
                                  setSelected(sale);
                                }}
                                className="px-5 py-2 border-green-600 border text-green-600 rounded transition duration-300 hover:bg-blue-700 hover:text-white focus:outline-none"
                              >
                                View Details
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <Pagination metaData={metaData} setPagination={setPagination} />
            </div>
          </div>
        </div>
      )}
      {selected && (
        <div>
          <button
            className="flex rounded bg-green-800 px-4 absolute mt-12 ml-16  py-2 text-white justify-end mr-2"
            onClick={() => {
              setSelected(null);
            }}
          >
            <svg
              className="w-6 h-6 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M7 16l-4-4m0 0l4-4m-4 4h18"
              />
            </svg>
            Back
          </button>
          <SaleByID sale={selected} />
        </div>
      )}
    </Template>
  );
}
