import React, { useEffect, useState } from "react";
import StoreByID from "./StoreById";
import axios from "axios";
import moment from "moment";
import Map from "../components/Map";
import Template from "../components/template";
import { Link, useLocation, useNavigate } from "react-router-dom";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export default function CompanyByID() {
  const [selected, setSelected] = useState(null);
  const [mapModal, setMapModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const company = location.state;

  console.log(company);

  const modalSwitch = () => {
    if (mapModal === true) {
      setMapModal(false);
    } else setMapModal(true);
  };
  return (
    <Template>
      <div>
        {/* {!selected && ( */}
        <div className="w-full mt-10  p-4 text-md">
          <h1 className="text-center mt-8 text-4xl mb-8 text-green-700">
            {company.name}
          </h1>
          {mapModal ? (
            <Map
              modalSwitch={modalSwitch}
              show={mapModal}
              company={company}
              company_type={company.company_type.type}
              type="Company"
            />
          ) : null}
          <div className="w-full flex justify-start mb-8 px-12">
            <Link
              className="flex rounded bg-green-800 px-4 py-2 text-white "
              to="/companies/"
            >
              <svg
                className="w-6 h-6 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M7 16l-4-4m0 0l4-4m-4 4h18"
                />
              </svg>
              Back
            </Link>
          </div>

          <div className="pt-10 px-10 ">
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Company Name
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {company.name ? company.name : "not available"}
                    </dd>
                  </div>

                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Email address
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {company.email ? company.email : "not available"}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Address
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {company.address ? company.address : "not available"}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Phone Number
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {company.phone_number
                        ? company.phone_number
                        : "not available"}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      AFAP Number
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {company.afap_number
                        ? company.afap_number
                        : "not available"}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Business Certificate
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {company.business_certificate ? (
                        <a
                          className=" ml-4  bg-green-700 text-sm px-2 py-1 text-white hover:bg-green-900 hover:text-yellow-300 rounded-md"
                          href={company.business_certificate}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          view on document
                        </a>
                      ) : (
                        <p>not available</p>
                      )}
                    </dd>
                  </div>

                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Type</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {company.company_type
                        ? company.company_type.type
                        : "not available"}
                    </dd>
                  </div>

                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Stores
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {company.branches && company.branches.length > 0 ? (
                        <table className="min-w-full">
                          <thead>
                            <tr>
                              <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-green-600 tracking-wider">
                                Store Name
                              </th>
                              <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                                Type
                              </th>
                            </tr>
                          </thead>

                          <tbody className="bg-white">
                            {company.branches.map((branch) => {
                              return (
                                <tr
                                  onClick={() => {
                                    // setSelected(branch);
                                    navigate({
                                      pathname: `/company/${company.name}/branch/${branch.name}`,
                                      state: {
                                        store: branch,
                                        company: company,
                                      },
                                    });
                                  }}
                                  className="hover:bg-green-300 hover:bg-opacity-40 cursor-pointer"
                                >
                                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                                    <div className="text-sm leading-5 text-green-900">
                                      {branch.name
                                        ? branch.name
                                        : "not available"}
                                    </div>
                                  </td>
                                  <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5">
                                    {branch.type
                                      ? branch.type.type
                                      : "not available"}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <p>no registered stores</p>
                      )}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
}
