import React from "react";
import AuthHandler from "../auth/authHandler";
import MyRoutes from "./Routes";
import { BrowserRouter } from "react-router-dom";

export const Providers = () => {
  return (
    <BrowserRouter>
      <AuthHandler>
        <MyRoutes />
      </AuthHandler>
    </BrowserRouter>
  );
};
