import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../auth/authHandler";
import Template from "../components/template";
import axios from "axios";
import debounce from "lodash.debounce";
import { Link, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import Table from "../components/table";
import Loading from "../components/Loading";
import { useGetCompanies, useGetCompanyTypes } from "./CompanyService";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export default function Companies() {
  const { company_type } = useParams();
  const [limit, setLimit] = useState(15);
  const [pagination, setPagination] = useState(1);
  const [companyId, setCompanyId] = useState("");
  const [search, setSearch] = useState("");

  const { user } = useContext(AuthContext);

  function getCountry() {
    const userString = sessionStorage.getItem("country");
    const userCountry = JSON.parse(userString);
    return userCountry;
  }
  const [companiesData, metaData, dataLoading, error] = useGetCompanies(
    getCountry(),
    companyId,
    search,
    pagination,
    limit
  );

  const [companyTypeList] = useGetCompanyTypes();

  useEffect(() => {
    function getUser() {
      const userString = sessionStorage.getItem("user");
      const userCred = JSON.parse(userString);
      return userCred;
    }
    getUser();

    getCountry();
  }, []);

  return (
    <Template>
      <div className="w-full mt-10  p-4 text-md">
        <h1 className="text-center text-4xl text-green-700">Company</h1>
        <div className="w-full flex justify-end mb-8 px-12">
          <Link
            to="/comapnies/manufacturer/add"
            className="flex rounded bg-green-800 px-4  py-2 text-white justify-end "
          >
            <svg
              className="w-6 h-6 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            Add Manufacturer
          </Link>
        </div>
        {dataLoading ? (
          <Loading text={"Companies"} />
        ) : (
          <Table>
            <Table.Header
              searchInput={
                <Table.Search
                  setState={setSearch}
                  placeholder="Search by Name and AFAP number"
                  searchValue={search}
                  clearSearch={() => setSearch("")}
                />
              }
              limitOnChange={debounce((e) => setLimit(e.target.value), 2000)}
            >
              <Table.Dropdown>
                <select
                  onChange={(e) => setCompanyId(e.target.value)}
                  className="appearance-none h-full  block w-full bg-white  text-gray-700 py-2 px-4 pr-8 leading-tight "
                >
                  <option value="">All</option>
                  {companyTypeList && companyTypeList.length !== 0
                    ? companyTypeList.map((companyType) => (
                        <option value={companyType.id} key={companyType.id}>
                          {companyType.type}
                        </option>
                      ))
                    : null}
                </select>
              </Table.Dropdown>
            </Table.Header>
            <div className="align-middle inline-block min-w-full shadow overflow-hidden bg-white shadow-dashboard px-8 pt-3 rounded-bl-lg rounded-br-lg">
              {companiesData && companiesData.length > 0 ? (
                <div className="table-height overflow-y-scroll">
                  <table className="min-w-full">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-green-600 tracking-wider">
                          ID
                        </th>
                        <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                          Company Name
                        </th>
                        <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                          Email
                        </th>
                        <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                          Company Type
                        </th>
                        <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                          AFAP Number
                        </th>
                        <th className="px-3 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                          Total Stores
                        </th>
                        <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                          Phone Number
                        </th>
                        <th className="px-6 py-3 border-b-2 border-gray-300"></th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {companiesData.map((company, index) => {
                        return (
                          <tr key={company.id}>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                              <div className="flex items-center">
                                <div>
                                  <div className="text-sm leading-5 text-gray-800">
                                    {(pagination - 1) * limit + index + 1}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                              <div className="text-sm leading-5 text-green-900">
                                {company.name}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap  border-b text-green-900 border-gray-500 text-sm leading-5">
                              <div className="w-32  truncate">
                                {company.email}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5">
                              {company.company_type.type}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5">
                              {company.afap_number}
                            </td>
                            <td className="px-2 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5">
                              {company.branches.length}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500 text-green-900 text-sm leading-5">
                              <div className="w-24  truncate">
                                {company.phone_number}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-500 text-sm leading-5">
                              <div className=" border-green-600 border text-green-600 rounded transition duration-300 hover:bg-green-600 hover:text-white focus:outline-none">
                                <Link
                                  to={{
                                    pathname: `/company/${company.name}`,
                                    state: company,
                                  }}
                                  className="w-full text-center justify-center flex px-3 py-2 h-full"
                                >
                                  View Details
                                </Link>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="text-center text-green-900 text-sm leading-5">
                  No companies available, please add a company
                </p>
              )}
              <Pagination metaData={metaData} setPagination={setPagination} />
            </div>
          </Table>
        )}
      </div>
    </Template>
  );
}
