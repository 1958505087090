import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Template from "../../components/template";
import axios from "axios";
import moment from "moment";
import SalesValue from "./SalesValue";
import SalesVolumePerProduct from "./SalesVolumePerProduct";
import Table from "../../components/table";
import Pagination from "../../components/pagination";
import Loading from "../../components/Loading";
import { useGetSalesVolume } from "./SalesService";
import {
  useGetAllCountries,
  useGetAllDistricts,
  useGetAllRegions,
} from "../../Settings/CountryConfiguration/CountryService";
import { yearRange } from "../../utilities/calculateYearRange";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export default function SalesVolume() {
  const [sortPeriod, setSortPeriod] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [pagination, setPagination] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState(getCountry().id);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);

  function getCountry() {
    const userString = sessionStorage.getItem("country");
    const userCountry = JSON.parse(userString);

    return userCountry;
  }
  getCountry();

  function getUser() {
    const userString = sessionStorage.getItem("user");
    const userCred = JSON.parse(userString);
    return userCred;
  }

  const [sales, dataLoading, error] = useGetSalesVolume(
    getCountry().id,
    selectedRegion,
    selectedDistrict,
    selectedYear,
    sortPeriod
  );
  const [countries] = useGetAllCountries();
  const [regions] = useGetAllRegions(selectedCountry);
  const [districts] = useGetAllDistricts(selectedRegion, regions);

  return (
    <Template>
      <div className="w-full p-4 mt-10 text-md">
        <h1 className="mb-8 text-4xl text-center text-green-700">
          Sales Volume
        </h1>
        {dataLoading ? (
          <Loading text={"Sales Volume"} />
        ) : (
          <Table>
            <Table.Header>
              {countries && countries.length !== 0 ? (
                <Table.Dropdown>
                  <select
                    onChange={(e) => {
                      setSelectedCountry(e.target.value);
                    }}
                    value={selectedCountry}
                    className="block w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white appearance-none "
                  >
                    {countries.map((country) => (
                      <option value={country.id} key={country.id}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </Table.Dropdown>
              ) : null}
              {regions && regions.length !== 0 ? (
                <Table.Dropdown>
                  <select
                    onChange={(e) => setSelectedRegion(e.target.value)}
                    value={selectedRegion}
                    className="block w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white appearance-none "
                  >
                    <option value="">Select Region</option>
                    {regions.map((region) => (
                      <option value={region.id} key={region.id}>
                        {region.name}
                      </option>
                    ))}
                  </select>
                </Table.Dropdown>
              ) : null}
              {selectedRegion && districts && districts.length !== 0 ? (
                <Table.Dropdown>
                  <select
                    onChange={(e) => setSelectedDistrict(e.target.value)}
                    value={selectedDistrict}
                    className="block w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white appearance-none "
                  >
                    <option value="">Select District</option>
                    {districts.map((district) => (
                      <option value={district.id} key={district.id}>
                        {district.name}
                      </option>
                    ))}
                  </select>
                </Table.Dropdown>
              ) : null}
              {yearRange().length > 0 ? (
                <Table.Dropdown>
                  <select
                    onChange={(e) => setSelectedYear(e.target.value)}
                    value={selectedYear}
                    className="block w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white appearance-none "
                  >
                    {yearRange().map((year) => (
                      <option value={year}>{year}</option>
                    ))}
                  </select>
                </Table.Dropdown>
              ) : null}
            </Table.Header>
            <div className="inline-block min-w-full px-8 pt-3 overflow-hidden align-middle bg-white rounded-bl-lg rounded-br-lg shadow shadow-dashboard">
              {sales && sales.length > 0 ? (
                <div className="overflow-y-scroll table-height">
                  <table className="min-w-full">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 leading-4 tracking-wider text-left text-green-600 border-b-2 border-gray-300">
                          ID
                        </th>
                        <th className="px-6 py-3 text-sm leading-4 tracking-wider text-left text-green-600 border-b-2 border-gray-300">
                          Product Name
                        </th>
                        <th className="px-6 py-3 text-sm leading-4 tracking-wider text-left text-green-600 border-b-2 border-gray-300">
                          Weight
                        </th>

                        <th className="px-6 py-3 text-sm leading-4 tracking-wider text-left text-green-600 border-b-2 border-gray-300">
                          Amount Sold (bags)
                        </th>

                        <th className="px-6 py-3 border-b-2 border-gray-300"></th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {sales &&
                        sales.length > 0 &&
                        sales.map((sale, index) => {
                          return (
                            <tr>
                              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                                <div className="flex items-center">
                                  <div>
                                    <div className="text-sm leading-5 text-gray-800">
                                      {(pagination - 1) * 15 + index + 1}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                                <div className="text-sm leading-5 text-green-900">
                                  {sale.product_name}
                                </div>
                              </td>
                              <td className="px-6 py-4 text-sm leading-5 text-green-900 whitespace-no-wrap border-b border-gray-500">
                                {sale.weight} {sale.unit}
                              </td>
                              <td className="px-6 py-4 text-sm leading-5 text-green-900 whitespace-no-wrap border-b border-gray-500">
                                {sale.total_sales_volume}
                              </td>

                              <td className="px-6 py-4 text-sm leading-5 text-right whitespace-no-wrap border-b border-gray-500">
                                <div className="items-center text-green-600 transition duration-300 border border-green-600 rounded hover:bg-green-600 hover:text-white focus:outline-none">
                                  <Link
                                    to={{
                                      pathname: `/reports/sale_volume/${sale.product_name}`,
                                      state: { sale, selectedYear },
                                    }}
                                    className="flex justify-center w-full h-full px-3 py-2"
                                  >
                                    View Details
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="text-sm leading-5 text-center text-green-900">
                  No sales recorded
                </p>
              )}
              <Pagination />
            </div>
          </Table>
        )}
      </div>
    </Template>
  );
}
