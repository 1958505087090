export const africanCountires = [
  { name: "Algeria", iso_code: "dz" },
  { name: "Angola", iso_code: "ao" },
  { name: "Benin", iso_code: "bj" },
  { name: "Botswana", iso_code: "bw" },
  { name: "Burkina Faso", iso_code: "bf" },
  { name: "Burundi", iso_code: "bi" },
  { name: "Cameroon", iso_code: "cm" },
  { name: "Cape Verde", iso_code: "cv" },
  { name: "Central African Republic", iso_code: "cf" },
  { name: "Chad", iso_code: "td" },
  { name: "Comoros", iso_code: "km" },
  { name: "Republic of the Congo", iso_code: "cg" },
  { name: "Democratic Republic of the Congo", iso_code: "cd" },
  { name: "Côte d’Ivoire", iso_code: "ci" },
  { name: "Djibouti", iso_code: "dj" },
  { name: "Egypt", iso_code: "eg" },
  { name: "Equatorial Guinea", iso_code: "gq" },
  { name: "Eritrea", iso_code: "er" },
  { name: "Ethiopia", iso_code: "et" },
  { name: "Gabon", iso_code: "ga" },
  { name: "The Gambia", iso_code: "gm" },
  { name: "Ghana", iso_code: "gh" },
  { name: "Guinea", iso_code: "gn" },
  { name: "Guinea-Bissau", iso_code: "gw" },
  { name: "Kenya", iso_code: "ke" },
  { name: "Lesotho", iso_code: "ls" },
  { name: "Liberia", iso_code: "lr" },
  { name: "Libya", iso_code: "ly" },
  { name: "Madagascar", iso_code: "mg" },
  { name: "Malawi", iso_code: "mw" },
  { name: "Mali", iso_code: "ml" },
  { name: "Mauritania", iso_code: "mr" },
  { name: "Mauritius", iso_code: "mu" },
  { name: "Morocco", iso_code: "ma" },
  { name: "Mozambique", iso_code: "mz" },
  { name: "Namibia", iso_code: "na" },
  { name: "Niger", iso_code: "ne" },
  { name: "Nigeria", iso_code: "ng" },
  { name: "Rwanda", iso_code: "rw" },
  { name: "São Tomé and Príncipe", iso_code: "st" },
  { name: "Senegal", iso_code: "sn" },
  { name: "Seychelles", iso_code: "sc" },
  { name: "Sierra Leone", iso_code: "sl" },
  { name: "Somalia", iso_code: "so" },
  { name: "South Africa", iso_code: "za" },
  { name: "Sudan", iso_code: "sd" },
  { name: "Eswatini", iso_code: "sz" },
  { name: "Tanzania", iso_code: "tz" },
  { name: "Togo", iso_code: "tg" },
  { name: "Tunisia", iso_code: "tn" },
  { name: "Uganda", iso_code: "ug" },
  { name: "Sahrawi Arab Democratic Republic (SADR)", iso_code: "sd" },
  { name: "Zambia", iso_code: "zm" },
  { name: "Zimbabwe", iso_code: "zw" },
];

export const languages = [
  {
    name: "English",
    code: "en",
  },
  {
    name: "French",
    code: "fr",
  },
];
