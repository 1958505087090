import React from "react";

export default function Pagination({ metaData, setPagination }) {
  return (
    <div className="sm:flex-1 sm:flex sm:items-center sm:justify-end mt-4 work-sans py-4">
      <div>
        <nav className="relative z-0 inline-flex shadow-sm">
          <div>
            {metaData
              ? metaData.links.map((link, index) =>
                  link.url ? (
                    <button
                      key={index}
                      // href={link.url}
                      onClick={() => {
                        let page_number = link.url.split("page=");
                        setPagination(page_number[1]);
                      }}
                      className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-green-700 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-tertiary active:text-gray-700 transition ease-in-out duration-150 hover:bg-tertiary"
                    >
                      {link.label}
                    </button>
                  ) : (
                    <button
                      key={index}
                      // href="null"
                      className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-green-700 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-tertiary active:text-gray-700 transition ease-in-out duration-150 hover:bg-tertiary"
                    >
                      {link.label}
                    </button>
                  )
                )
              : null}
          </div>
        </nav>
      </div>
    </div>
  );
}
