import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import Chart from "react-apexcharts";
import axios from "axios";
import { useGetProductCategories } from "../Products/ProductsService";
import { getCategoriesDataApi } from "../Reports/Stock Availability/StockAvailabilityService";

export default function StockAvailabilityGraph({ country_id, user }) {
  const navigate = useNavigate();
  const [productCategory, setProductCategory] = useState(1);
  const [graphCategory, setGraphCategory] = useState([]);
  const [graphCount, setGraphCount] = useState([]);
  const [graphId, setGraphId] = useState([]);
  const [graphName, setGraphName] = useState("");
  const [dataLoading, setDataLoading] = useState(false);

  axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;

  const [categories, error] = useGetProductCategories();

  useEffect(() => {
    const getCategoriesData = async () => {
      if (user.token != null && country_id) {
        const data = await getCategoriesDataApi(
          productCategory,
          country_id,
          setDataLoading
        );

        let catValues = [];
        let catCount = [];
        let catId = [];
        let catName = [];
        if (data && data.length > 0) {
          data.map((i) => {
            catValues.push(i?.label);
            catCount.push(i?.total);
            catId.push(i?.id);
            catName.push(i?.category);
            return null;
          });
        }
        setGraphCount(catCount);
        setGraphCategory(catValues);
        setGraphId(catId);
        setGraphName(catName);
      }
    };
    getCategoriesData();
  }, [user, productCategory]);

  let chartFertilizer = {
    options: {
      responsive: [
        {
          breakpoint: 1536,
          options: {
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
            chart: {
              width: "350",
              height: "325",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      title: {
        text: "Stock Availability",
        align: "center",
      },
      colors: ["#059669"],

      chart: {
        width: "550",
        events: {
          dataPointSelection: (event, chartContext, config) => {
            navigate(
              `/reports/stock_availability/${
                config.w.config.series[0].name[config.dataPointIndex]
              }`
            );
          },
        },
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: graphCategory,
        title: {
          text: "Products",
        },
      },
      yaxis: {
        title: {
          text: "product in stock (per bag)",
        },
      },
    },
    series: [
      {
        name: graphId,
        data: graphCount,
        // id: graphId,
      },
    ],
  };

  return (
    <div className="flex justify-center relative w-full items-center h-96">
      <div className="absolute top-0 right-0 2lx:-mt-8 mr-2">
        {/* <label htmlFor="country" className="block text-sm font-medium text-gray-700">
          Product Category
        </label> */}

        <select
          id="category"
          name="category"
          onChange={(e) => {
            setProductCategory(e.target.value);
          }}
          value={productCategory}
          // autocomplete="category"
          className="mt-1 block w-full border py-2 border-green-600  px-3 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          {categories &&
            categories.map((category, index) => {
              return (
                <option key={index} value={category.id}>
                  {category.name}
                </option>
              );
            })}
        </select>
      </div>

      <Chart
        className="mt-14"
        options={chartFertilizer.options}
        series={chartFertilizer.series}
        type="bar"
        width="550"
      />
    </div>
  );
}
