import React from "react";
import Template from "../components/template";
import image from "../assets/404.svg";
import { Link } from "react-router-dom";

export default function PageNotFound() {
  return (
    <div className="w-full mt-5 text-center flex flex-col justify-center items-center">
      <h1 className="text-8xl font-semibold text-green-700">404</h1>
      <img src={image} alt="404" className="w-1/3 " />
      <Link
        to="/"
        className="flex rounded bg-green-800 px-4  py-2 text-white justify-end "
      >
        go back to dashboard
      </Link>
    </div>
  );
}
