import React, { useEffect, useState } from "react";
import axios from "axios";
import Template from "../../components/template";
import { Link } from "react-router-dom";
import ViewCountry from "./ViewCountry";
import Pagination from "../../components/pagination";
import Table from "../../components/table";
import { useGetAllCountries } from "./CountryService";

export default function CountryConfig() {
  const [countries, dataLoading, error] = useGetAllCountries();

  return (
    <Template>
      <div className="w-full mt-10  p-4 text-md">
        <h1 className="text-center text-4xl mb-8 text-green-700">
          Country Configuration
        </h1>
        <div className="w-full flex justify-end mb-8 px-12">
          <Link
            to="/settings/country_configuration/add"
            className="flex rounded bg-green-800 px-4  py-2 text-white justify-end "
          >
            <svg
              className="w-6 h-6 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            Add Country
          </Link>
        </div>
        <Table>
          <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 pr-10  lg:px-8">
            <div className="align-middle rounded-tl-lg rounded-tr-lg inline-block w-full py-4 overflow-hidden bg-white shadow-lg px-12"></div>
            <div className="align-middle  inline-block min-w-full shadow  bg-white shadow-dashboard px-8 pt-3 rounded-bl-lg rounded-br-lg">
              <div className="table-height overflow-y-scroll">
                <table className="min-w-full ">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-green-600 tracking-wider">
                        ID
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                        Country Name
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                        Language
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                        Currency
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300"></th>
                    </tr>
                  </thead>
                  <tbody className="bg-white  ">
                    {countries &&
                      countries.map((country, index) => {
                        return (
                          <tr>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                              <div className="flex items-center">
                                <div>
                                  <div className="text-sm leading-5 text-gray-800">
                                    {(1 - 1) * 15 + index + 1}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                              <div className="text-sm leading-5 text-green-900">
                                {country.name}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5">
                              {country.language}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5">
                              {country.currency} {country.currency_symbol}
                            </td>

                            <td className="px-6 py-4 flex justify-end items-center h-full whitespace-no-wrap text-right border-b border-gray-500 text-sm leading-5">
                              <button
                                // onClick={() => {
                                //   setSelected(country);
                                // }}
                                className="px-5 py-2 border-red-600 border text-red-600 rounded transition duration-300 hover:bg-red-600 hover:text-white focus:outline-none mr-2"
                              >
                                <svg
                                  className="w-6 h-6"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                                  />
                                </svg>
                              </button>
                              <Link
                                to={{
                                  pathname: `/settings/country_configuration/edit/${country.name}`,
                                  state: country,
                                }}
                                className="px-5 py-2 border-yellow-600 border text-yellow-600 rounded transition duration-300 hover:bg-yellow-600 hover:text-white focus:outline-none mr-2"
                              >
                                <svg
                                  className="w-6 h-6"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                  />
                                </svg>
                              </Link>
                              <Link
                                to={{
                                  pathname: `/settings/country_configuration/${country.name}/regions`,
                                  state: country,
                                }}
                                className="px-3 py-2 border-green-600 border text-green-600 rounded transition duration-300 hover:bg-green-600 hover:text-white focus:outline-none"
                              >
                                View Details
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <Pagination />
            </div>
          </div>
        </Table>
      </div>
    </Template>
  );
}
