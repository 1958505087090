import axios from "axios";
import { useEffect, useState } from "react";
import Navbar from "../components/navbar";
import { useGetAllCountries } from "../Settings/CountryConfiguration/CountryService";

export default function HelpAndSupport() {
  const [user, setUser] = useState({
    name: "",
    email: "",
    helpText: "",
    country: "",
    phoneNumber: "",
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const [countries, dataLoading] = useGetAllCountries();

  const onSubmit = () => {
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios
      .post(
        "/support",
        {
          full_name: user.name,
          email: user.email,
          message: user.helpText,
          country_id: user.country,
          phone_number: user.phoneNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            // Accept: "application/json",
          },
        }
      )
      .then((response) => {
        setSuccess(true);
      })
      .catch((error) => {
        if (error?.response?.status >= 500) {
          setError("Well, this unexpected. We are sorry");
        } else {
          setError(error?.response?.data?.message);
        }
      });
  };

  return (
    <>
      {/* <Navbar /> */}

      {!success ? (
        <div className="flex items-center bg-fixed h-full ">
          <div className="container my-20 mx-auto">
            <div className="max-w-md mx-auto my-10">
              <div className="text-center">
                <h1 className="my-3 text-3xl font-semibold text-gray-700 ">
                  Help and Support
                </h1>
                {/* <p className="text-gray-500 ">
                Sign in to access your account
              </p> */}
                {error && <p className="text-red-500  mt-2">{error}</p>}
              </div>
              <div className="m-7">
                <form>
                  <div className="mb-1">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm text-gray-600 "
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      onChange={handleChange}
                      value={user.name}
                      className="w-full mb-8 placeholder-white justify-center text-green-800 p-4 border-2 border-green-800 rounded"
                    />
                  </div>
                  <div className="mb-1">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm text-gray-600 "
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      onChange={handleChange}
                      value={user.email}
                      className="w-full mb-8 placeholder-white justify-center text-green-800 p-4 border-2 border-green-800 rounded"
                    />
                  </div>
                  <div className="mb-1">
                    <label
                      htmlFor="phoneNumber"
                      className="block mb-2 text-sm text-gray-600 "
                    >
                      Phone Number
                    </label>
                    <input
                      type="phoneNumber"
                      name="phoneNumber"
                      id="phoneNumber"
                      onChange={handleChange}
                      value={user.phoneNumber}
                      className="w-full mb-8 placeholder-white justify-center text-green-800 p-4 border-2 border-green-800 rounded"
                    />
                  </div>
                  <div className="mb-1">
                    <label
                      htmlFor="country"
                      className="block mb-2 text-sm text-gray-600 "
                    >
                      Country
                    </label>
                    <select
                      name="country"
                      id="country"
                      onChange={handleChange}
                      value={user.country}
                      className="w-full appearance-none mb-8 placeholder-white justify-center text-green-800 p-4 border-2 border-green-800 rounded"
                    >
                      <option>Please select Country</option>
                      {countries.length !== 0 &&
                        countries.map((country) => (
                          <option key={country.id} value={country.id}>
                            {country.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="mb-1">
                    <label
                      htmlFor="helpText"
                      className="block mb-2 text-sm text-gray-600 "
                    >
                      How can we help you today?
                    </label>
                    <textarea
                      // type="password"
                      name="helpText"
                      id="helpText"
                      value={user.helpText}
                      className="w-full mb-8 placeholder-white justify-center text-green-800 p-4 border-2 border-green-800 rounded"
                      onChange={handleChange}
                      rows="4"
                      cols="50"
                    />
                  </div>

                  <div className="mb-4">
                    <button
                      onClick={onSubmit}
                      className="w-full bg-green-800 text-white  p-4 items-center justify-center  mb-4 rounded"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center min-h-screen  ">
          <div className="container mx-auto">
            <div className="max-w-md mx-auto my-10">
              <div className="text-center">
                <h1 className="my-3 text-3xl font-semibold text-gray-700 ">
                  Help and Support
                </h1>
                <p className="text-gray-500 ">
                  Hello {user.name}, Thanks for trying to reach us! We
                  appreciate it. Your complaint has successfully been sent to
                  our team and you will receive an email with a Ticket number to
                  initiate the support process.
                  <br /> Your issue(s) is a priority to us and will be addressed
                  as such. <br />
                  Thank You.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
