import React, { useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const AuthContext = React.createContext({});

export default function AuthHandler({ children }) {
  const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [country, setCountry] = useState(null);
  const [id, setId] = useState(null);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        userDetails,
        setUserDetails,
        country,
        setCountry,
        error,
        login: async (email, password) => {
          try {
            let response = await axios.post("/login", {
              email,
              password,
            });

            const data = response.data;
            if (
              data.data.role.role === "Admin" ||
              data.data.role.role === "Platform Company Admin" ||
              data.data.role.role === "Platform Company Staff"
            ) {
              const userRes = {
                email: data.data.email,
                token: data.token,
                company: data.data.company,
                role: data.data.role,
              };
              const countryRes = {
                id: data.data.country.id,
                name: data.data.country.name,
              };

              setUser(userRes);
              setUserDetails(data.data);
              setCountry(countryRes);
              setError(null);
              sessionStorage.setItem("user", JSON.stringify(userRes));
              sessionStorage.setItem("country", JSON.stringify(countryRes));
              navigate("/");
            } else {
              setError("Not authorized to perform this action");
            }
          } catch (error) {
            setError("Email or Password is Invalid");
          }
        },
        logout: async () => {
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${user.token}`;
          try {
            let response = await axios.post("/logout");
            setUser(null);
            sessionStorage.removeItem("user");
            navigate("/login");
          } catch (error) {
            console.log(error?.response);
          }
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
