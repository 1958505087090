import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import Template from "../../components/template";
import StockAvailabilityGraph from "../../DashboardGraphs/StockAvailability";

import PieCharts from "../../reportGraphs/PieCharts";
import Pagination from "../../components/pagination";
import Loading from "../../components/Loading";
import { useGetStockAvailability } from "./StockAvailabilityService";
import { useGetCompanyTypes } from "../../CompanyManagement/CompanyService";
import {
  useGetProductCategories,
  useGetProductCountPerCategory,
  useGetSupportedProductCategories,
} from "../../Products/ProductsService";
import { useGetAllCountries } from "../../Settings/CountryConfiguration/CountryService";

export default function StockAvailability({ match }) {
  const [branchType, setBranchType] = useState("");
  const [user, setUser] = useState("");
  const [sort, setSort] = useState("products");
  const [allStores, setAllStores] = useState(null);
  const [supportedCategoryId, setSupportedCategoryId] = useState("");
  const [productId, setProductId] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [category, setCategory] = useState("");
  const [productsPagination, setProductsPagination] = useState(1);
  const [branchPagination, setBranchPagination] = useState(1);
  const [branchMetaData, setBranchMetaData] = useState(null);

  const { id } = useParams();

  const [companyTypeList] = useGetCompanyTypes();
  const [categories] = useGetProductCategories();
  const [supportedProductById] = useGetSupportedProductCategories(category);
  const [
    products,
    productsMetaData,
    dataLoading,
    error,
  ] = useGetStockAvailability(
    supportedCategoryId,
    selectedCountry,
    productId,
    productsPagination
  );
  const [countries] = useGetAllCountries();
  const [productCategoryCount] = useGetProductCountPerCategory();

  useEffect(() => {
    function getUser() {
      const userString = sessionStorage.getItem("user");
      const userCred = JSON.parse(userString);
      setUser(userCred);
      return userCred;
    }
    getUser();

    function getCountry() {
      const userString = sessionStorage.getItem("country");
      const userCountry = JSON.parse(userString);
      setSelectedCountry(userCountry.id);
      return userCountry;
    }
    getCountry();

    if (id) {
      setSupportedCategoryId(id);
    }
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    // setUser({ category: branchType, company: company });
  };

  return (
    <Template>
      <div className="w-full mt-10  p-4 text-md">
        <h1 className="text-center text-4xl mb-8 text-green-700">
          Stock Availability
        </h1>

        <div className="w-full py-4  text-md">
          <div className="grid  grid-cols-1 md:grid-cols-2 gap-6 mb-5">
            <div className="bg-white py-2 flex rounded-tl-lg rounded-tr-lg justify-center items-center shadow-lg">
              <PieCharts
                data={productCategoryCount}
                colors={["#6EE7B7", "#059669", "#047857"]}
                title="Total Stock Count Per Category"
              />
            </div>
            <div className="bg-white py-2 flex rounded-tl-lg rounded-tr-lg justify-center items-center shadow-lg">
              <StockAvailabilityGraph
                user={user}
                country_id={selectedCountry}
              />
            </div>
          </div>
        </div>
        <div className="relative bg-green-700  justify-center items-center pl-2 flex border rounded focus:outline-none focus:bg-white focus:border-green-700">
          <p className="pr-2 text-white">Country</p>
          <select
            className="appearance-none h-full  block  w-full bg-white  text-gray-700 py-2 px-4 pr-8 leading-tight "
            onChange={(e) => setSelectedCountry(e.target.value)}
            value={selectedCountry}
          >
            {countries && countries.length != 0
              ? countries.map((country) => {
                  return (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  );
                })
              : null}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
        <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 pr-10 lg:px-8">
          <div className="align-middle  inline-block w-full py-4 overflow-hidden bg-white shadow-lg px-12">
            <div className="flex justify-between">
              <div className="flex flex-row mb-1 sm:mb-0">
                <div className="relative justify-center items-center pl-2 flex border rounded focus:outline-none focus:bg-white focus:border-gray-500">
                  <svg
                    className="w-6 h-6 text-gray-600 "
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                    />
                  </svg>
                  <select
                    className="appearance-none outline-none h-full  block  w-full bg-white  text-gray-700 py-2 px-4 pr-8 leading-tight "
                    onChange={(e) => setSort(e.target.value)}
                  >
                    <option value="products">Products</option>
                    <option value="branches">Branches</option>
                    {/* <option>Inactive</option> */}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            {sort == "branches" ? (
              <div>
                <div className="">
                  <form onSubmit={onSubmit}>
                    <div className="mt-4 mb-8">
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Company Type
                      </label>

                      <select
                        id="category"
                        name="category"
                        onChange={(e) => {
                          setBranchType(e.target.value);
                        }}
                        value={branchType}
                        // autocomplete="category"
                        className="mt-1 block w-full border py-2 border-green-600  px-3 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option value={""}>Select a value</option>
                        {companyTypeList &&
                          companyTypeList.map((branchType, index) => {
                            if (branchType != null) {
                              return (
                                <option key={index} value={branchType.id}>
                                  {branchType.type}
                                </option>
                              );
                            } else return null;
                          })}
                      </select>
                    </div>
                  </form>
                </div>
                {dataLoading ? (
                  <Loading text={"Stock Availability Report"} />
                ) : products && products.length > 0 ? (
                  <div className="align-middle inline-block min-w-full shadow overflow-hidden bg-white shadow-dashboard px-8 pt-3 rounded-bl-lg rounded-br-lg">
                    <table className="min-w-full">
                      <thead className="w-full">
                        <tr>
                          <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-green-600 tracking-wider">
                            ID
                          </th>

                          <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                            Company Name
                          </th>
                          <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                            Store Name
                          </th>
                          <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                            Stock Available (per bag)
                          </th>
                          <th className="px-6 py-3 border-b-2 border-gray-300"></th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {allStores &&
                          allStores.length > 0 &&
                          allStores.map((store, index) => {
                            return (
                              <tr key={index}>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                                  <div className="flex items-center">
                                    <div>
                                      <div className="text-sm leading-5 text-gray-800">
                                        {(branchPagination - 1) * 15 +
                                          index +
                                          1}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                                  <div className="text-sm leading-5 text-green-900">
                                    {store.company.name}
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5">
                                  {store.name}
                                </td>

                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                                  <div className="text-sm leading-5 text-green-900">
                                    {store.total_products_count}
                                  </div>
                                </td>

                                <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-500 text-sm leading-5">
                                  <div className=" border-green-600 border text-green-600 rounded transition duration-300 hover:bg-green-600 hover:text-white focus:outline-none">
                                    <Link
                                      to={{
                                        pathname: `/reports/stock_availability/branch/${store.name}`,
                                        state: { store, allStores },
                                      }}
                                      className="w-full text-center justify-center flex px-3 py-2 h-full"
                                    >
                                      View Details
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    <Pagination
                      metaData={branchMetaData}
                      setPagination={setBranchPagination}
                    />
                  </div>
                ) : (
                  <p className="text-center text-green-900 text-sm leading-5">
                    No products are in stock
                  </p>
                )}
              </div>
            ) : (
              <div>
                <div className="">
                  <form onSubmit={onSubmit}>
                    <div className="mt-4 ">
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Product Category
                      </label>

                      <select
                        id="category"
                        name="category"
                        onChange={(e) => {
                          setCategory(e.target.value);
                        }}
                        value={category}
                        // autocomplete="category"
                        className="mt-1 block w-full border py-2 border-green-600  px-3 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option>Select a value</option>
                        {categories &&
                          categories.map((category, index) => {
                            return (
                              <option key={index} value={category.id}>
                                {category.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="mt-4 ">
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Supported Product By Cartegory(Sub Category)
                      </label>

                      <select
                        id="category"
                        name="category"
                        onChange={(e) => {
                          setSupportedCategoryId(e.target.value);
                        }}
                        value={supportedCategoryId}
                        // autocomplete="category"
                        className="mt-1 block w-full border py-2 border-green-600  px-3 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option>Select a value</option>

                        {supportedProductById &&
                          supportedProductById.map((category, index) => {
                            return (
                              <option key={index} value={category.id}>
                                {category.label}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="w-full flex justify-center">
                      <button className="bg-green-700 px-6 py-2 my-8 text-white ">
                        Apply
                      </button>
                    </div>
                  </form>
                </div>
                {dataLoading ? (
                  <Loading text={"Stock Availability Report"} />
                ) : products && products.length > 0 ? (
                  <div className="align-middle inline-block min-w-full shadow overflow-hidden bg-white shadow-dashboard px-8 pt-3 rounded-bl-lg rounded-br-lg">
                    <table className="min-w-full">
                      <thead className="w-full">
                        <tr>
                          <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-green-600 tracking-wider">
                            ID
                          </th>

                          {/* <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                            Company Name
                          </th> */}
                          <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                            Product Name
                          </th>
                          <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                            Stock Available (per bag)
                          </th>
                          {/* <th className="px-6 py-3 border-b-2 border-gray-300"></th> */}
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {products &&
                          products.map((product, index) => {
                            return (
                              <tr key={index}>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                                  <div className="flex items-center">
                                    <div>
                                      <div className="text-sm leading-5 text-gray-800">
                                        {(productsPagination - 1) * 15 +
                                          index +
                                          1}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                                  <div className="text-sm leading-5 text-green-900">
                                    {product.name} ({product.weight}{" "}
                                    {product.unit})
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5">
                                  {product.total_stock_available}
                                </td>

                                {/* <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                                  <div className="text-sm leading-5 text-green-900"></div>
                                </td> */}
                                {/* 
                                <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-500 text-sm leading-5">
                                  <button
                                    // onClick={() => {
                                    //   setSelected(store);
                                    // }}
                                    className="px-5 py-2 border-green-600 border text-green-600 rounded transition duration-300 hover:bg-blue-700 hover:text-white focus:outline-none"
                                  >
                                    View Details
                                  </button>
                                </td> */}
                                {/* <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5">
                          {product.supported_products.category.name}
                        </td> */}
                                {/* <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5"> */}
                                {/* {`${product.weight} ${product.unit}`} */}
                                {/* </td> */}
                                {/* <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500 text-green-900 text-sm leading-5"> */}
                                {/* {moment(product.created_at).format("MM/DD/YYYY")} */}
                                {/* </td> */}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    <Pagination
                      metaData={productsMetaData}
                      setPagination={setProductsPagination}
                    />
                  </div>
                ) : (
                  <p className="text-center text-green-900 text-sm leading-5">
                    No products are in stock
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Template>
  );
}
