import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export function getApi(url, setLoading, setError) {
  function getUser() {
    const userString = sessionStorage.getItem("user");
    const userCred = JSON.parse(userString);
    return userCred;
  }
  if (getUser()) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${
      getUser().token
    }`;
  }

  setLoading(true);
  return axios
    .get(url)
    .then((r) => {
      if (r.data) {
        setLoading(false);
        return r.data;
      }
    })
    .catch((error) => {
      setLoading(false);
      console.log(error.response);
      // if (error.response) {
      //   setError(error.response.data);
      // } else {
      //   setError(error.message);
      // }
    });
}
