import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Template from "../components/template";
import axios from "axios";
import {
  useGetProductCategories,
  useGetSupportedProductCategories,
  useGetProductManufacturers,
  useGetProductUnits,
} from "./ProductsService";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export default function AddProduct() {
  const [user, setUser] = useState({
    name: "",
    manufacturer_name: "",
    category: "",
    description: "",
    variations: "",
    barcode: "",
    code: "",
  });
  const [packaging, setPackaging] = useState({
    packaging_unit_id: "",
    label: "",
    weight: "",
    count: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [category, setCategory] = useState("");
  const [showModal, setShowModal] = React.useState(false);

  const [error, setError] = useState(null);

  const navigate = useNavigate();
  function getCountry() {
    const userString = sessionStorage.getItem("country");
    const userCountry = JSON.parse(userString);

    return userCountry;
  }
  const [categories] = useGetProductCategories();
  const [supportedProductById] = useGetSupportedProductCategories(category);
  const [manufacturers] = useGetProductManufacturers(getCountry().id);
  const [units] = useGetProductUnits();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handlePackageChange = (e) => {
    const { id, value } = e.target;
    setPackaging((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      let response = await axios.post(
        "admin/products",
        {
          supported_products_by_categories_id: user.category,
          name: user.name,
          made_by: user.manufacturer_name,
          variations: user.variations,
          description: user.description,
          barcode: user.barcode,
          code: user.code,
          packaging: packaging,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("post successful", response);
      setShowModal(true);
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      if (error.response.status >= 500) {
        setError("Well, this unexpected. We are sorry");
      } else {
        setError(error.response.data.message);
      }
    }
  };

  return (
    <Template>
      <div className="w-full mt-10 mb-10  p-4 text-md">
        <h1 className="text-center text-4xl mb-8 text-green-700">
          Add Product
        </h1>
        <div className="mt-5 md:mt-0 md:col-span-2">
          {showModal ? (
            <>
              <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                // onClick={() => setShowModal(false)}
              >
                <div className="relative w-auto my-6  mx-auto max-w-3xl">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5  rounded-t">
                      <h3 className="text-3xl font-semibold px-6 pt-10">
                        Successfully Added Product
                      </h3>
                      {/* <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowModal(false)}
                      >
                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button> */}
                    </div>
                    {/*body*/}
                    <div className="w-full flex justify-center">
                      <svg
                        className="w-64 h-64 text-green-600 flex text-center"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      {/* <h1 className="text-green-600 text-2xl text-center">
                        Approved
                      </h1> */}
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6  rounded-b">
                      <button
                        className="bg-green-600 w-full text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        style={{ transition: "all .15s ease" }}
                        onClick={() => navigate("/products")}
                      >
                        Okay
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
          <form onSubmit={onSubmit}>
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                {error && <p className="text-red-500  mt-2">{error}</p>}
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 ">
                    <label
                      htmlFor="manufacturer_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Manufacturer Name
                    </label>
                    <select
                      id="manufacturer_name"
                      name="manufacturer_name"
                      value={user.manufacturer_name}
                      onChange={handleChange}
                      className="mt-1 block w-full py-2 px-3 border border-green-600 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option>Select a Manufacturer</option>
                      {manufacturers.map((manufacturer) => {
                        return (
                          <option value={manufacturer.id}>
                            {manufacturer.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-span-6 ">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Category
                    </label>

                    <select
                      id="category"
                      name="category"
                      onChange={(e) => {
                        setCategory(e.target.value);
                      }}
                      value={category}
                      // autocomplete="category"
                      className="mt-1 block w-full border py-2 border-green-600  px-3 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option>Select a value</option>
                      {categories &&
                        categories.map((category) => {
                          return (
                            <>
                              <option value={category.id}>
                                {category.name}
                              </option>
                            </>
                          );
                        })}
                    </select>
                  </div>
                  {supportedProductById && (
                    <div className="col-span-6 ">
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Supported Product By Category
                      </label>

                      <select
                        id="category"
                        name="category"
                        onChange={handleChange}
                        value={user.category}
                        // autocomplete="category"
                        className="mt-1 block w-full border py-2 border-green-600  px-3 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option>Select a value</option>

                        {supportedProductById.map((category) => {
                          return (
                            <>
                              <option value={category.id}>
                                {category.label}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                  )}
                  <div className="col-span-6 ">
                    <label
                      htmlFor="last_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Product Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      onChange={handleChange}
                      value={user.name}
                      className="mt-1 focus:ring-indigo-500 px-3  focus:border-indigo-500 block w-full  sm:text-sm border py-2 border-green-600 rounded"
                    />
                  </div>
                  <div className="col-span-6 ">
                    <label
                      htmlFor="last_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Description
                    </label>
                    <input
                      type="text"
                      name="description"
                      id="description"
                      onChange={handleChange}
                      value={user.description}
                      // autocomplete="family-name"
                      className="mt-1 focus:ring-indigo-500 px-3 focus:border-indigo-500 block w-full  sm:text-sm border py-2 border-green-600 rounded"
                    />
                  </div>
                  <div className="col-span-6 ">
                    <label
                      htmlFor="variations"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Variations
                    </label>
                    <input
                      type="text"
                      name="variations"
                      id="variations"
                      onChange={handleChange}
                      value={user.variations}
                      // autocomplete="family-name"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full px-3  sm:text-sm border py-2 border-green-600 rounded"
                    />
                  </div>
                  {/* {showForm()} */}
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="email_address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Package Label
                    </label>
                    <input
                      type="text"
                      name="label"
                      id="label"
                      onChange={handlePackageChange}
                      value={packaging.label}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full px-3  sm:text-sm border py-2 border-green-600 rounded"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-1">
                    <label
                      htmlFor="email_address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Weight Per Unit
                    </label>
                    <input
                      type="text"
                      name="weight"
                      id="weight"
                      onChange={handlePackageChange}
                      value={packaging.weight}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full px-3  sm:text-sm border py-2 border-green-600 rounded"
                    />
                  </div>
                  {units && (
                    <div className="col-span-6 sm:col-span-1">
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Unit of Measurement
                      </label>
                      <select
                        id="packaging_unit_id"
                        name="packaging_unit_id"
                        onChange={handlePackageChange}
                        value={packaging.packaging_unit_id}
                        // autocomplete="country"
                        className="mt-1 block w-full border py-2 border-green-600  px-3 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option>Select Unit</option>
                        {units.map((unit) => {
                          return <option value={unit.id}>{unit.unit}</option>;
                        })}
                      </select>
                    </div>
                  )}
                  <div className="col-span-6 sm:col-span-1">
                    <label
                      htmlFor="email_address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      No. Of Units Per Package
                    </label>
                    <input
                      type="text"
                      name="count"
                      id="count"
                      onChange={handlePackageChange}
                      value={packaging.count}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full px-3  sm:text-sm border py-2 border-green-600 rounded"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3 ">
                    <label
                      htmlFor="last_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Barcode
                    </label>
                    <input
                      type="text"
                      name="barcode"
                      id="barcode"
                      onChange={handleChange}
                      value={user.barcode}
                      // autocomplete="family-name"
                      className="mt-1 focus:ring-indigo-500 px-3 focus:border-indigo-500 block w-full  sm:text-sm border py-2 border-green-600 rounded"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="last_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Code
                    </label>
                    <input
                      type="text"
                      name="code"
                      id="code"
                      onChange={handleChange}
                      value={user.code}
                      // autocomplete="family-name"
                      className="mt-1 focus:ring-indigo-500 px-3 focus:border-indigo-500 block w-full  sm:text-sm border py-2 border-green-600 rounded"
                    />
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 flex justify-between text-right sm:px-6">
                <Link
                  to="/products"
                  className="inline-flex justify-center rounded bg-red-800 px-4  py-2 text-white  mr-2"
                >
                  Cancel
                </Link>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="inline-flex justify-center rounded bg-green-800 px-4  py-2 text-white  mr-2"
                >
                  {isSubmitting ? "saving..." : "save"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Template>
  );
}
