import React from "react";
import image from "../assets/AFAP-Logo-white 1.png";

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col justify-between w-screen min-h-screen py-20 bg-green-600">
      <div></div>
      <div className="flex items-center ">
        <div className="container mx-auto">
          <div className="flex flex-col items-center justify-center mb-10 space-y-2">
            <div className="p-4 space-x-4 bg-white rounded-lg w-fit">
              <img alt="" className="text-center " src={image} />
            </div>
            <h1 className="text-4xl text-center text-white">Privacy Policy</h1>
          </div>
          <div className="space-y-10 text-white ">
            <div className="space-y-4">
              <div>
                <h2 className="text-2xl">Introduction</h2>
                <p>
                  African Fertilizer and Agribusiness Partnership Inc
                  (AFAP-Ghana), is an external company registered in Ghana,
                  dedicated to promoting private sector investment and
                  development partnerships for the creation of sustainable
                  markets that facilitate the provision of affordable
                  fertilizers to enhance crop production and food security for
                  African smallholder farmers. In accordance with the Data
                  Protection Act, 2012 (Act 843), AFAP-Ghana serves as the data
                  controller for your Personal Data (as defined by the Data
                  Protection Act, 2012 (Act 843).
                </p>
                <p>
                  For any requests to access, inquiries, or questions regarding
                  the utilization of your Personal data, please reach out to our
                  Data Protection Officer, through email at{" "}
                  <a href="mailto:nmohammed@afap-partnership.org">
                    nmohammed@afap-partnership.org
                  </a>{" "}
                  or come by our office at No. 7935/W, Laryea Ania Street, West
                  Legon, Opposite CIC Building, GPS ADDRESS: GE-350-49-89.
                  Alternatively, you can contact us via phone on +233 (0)54 646
                  7620, +233 (0)302 940 424,
                </p>
              </div>
              <div>
                <h2 className="text-2xl ">Overview</h2>
                <p>
                  This Privacy Policy, in conjunction with our terms and
                  conditions, outlines our information collection process from
                  you through our mobile application, how we may utilize or
                  disclose that information, why we collect the information and
                  the protective measures we employ to safeguard it.
                </p>
                <p>
                  We've crafted this Privacy Policy with clarity and conciseness
                  in mind. Please take the time to read it attentively to
                  understand our policies concerning your information and its
                  treatment. By using or accessing this mobile application you
                  affirm your agreement with the collection, utilization, and
                  disclosure of information in accordance with this Privacy
                  Policy.
                </p>
                <p>
                  Periodically, we may modify, amend, or review this Privacy
                  Policy to accommodate new services or align with changes in
                  privacy and data protection laws. Any updates will be posted
                  on this page. Kindly review this Privacy Policy regularly to
                  ensure your understanding. Through your continued use of our
                  mobile application, you express your consent to this Privacy
                  Policy. If you do not agree with or accept this Privacy
                  Policy, we kindly request that you refrain from using this
                  mobile application.
                </p>
              </div>
              <div>
                <h2 className="text-2xl ">Representations and Warranties</h2>
                <p>
                  You affirm and guarantee, during your use of AFAP-IMS, the
                  following:
                </p>
                <ul class="list-disc ml-4 mb-2">
                  <li>
                    You possess legal capacity under the laws of Ghana and agree
                    to this privacy policy voluntarily. Furthermore, you have
                    the full power, authority, and capacity to comply with this
                    Privacy Policy and its contained obligations.
                  </li>
                  <li>
                    Your adherence to this Privacy Policy is lawful, and the
                    obligations herein are legally binding and valid.
                  </li>
                  <li>
                    You have diligently read, comprehended, and thoroughly
                    understood this Privacy Policy.
                  </li>
                  <li>
                    You commit to continuous compliance with this Privacy
                    Policy.
                  </li>
                  <li>
                    In accepting this privacy policy, you have not relied on any
                    representation, warranty, statement, undertaking, or conduct
                    of any kind other than what is expressly provided in this
                    privacy policy.
                  </li>
                  <li>
                    All information provided by you is true, complete, valid,
                    and not misleading.
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="text-2xl">Personal Data We May Collect</h2>
                <p>
                  We may collect and record information about you in both
                  physical and electronic formats, handling and processing this
                  personal data in adherence to the Data Protection Act 2012
                  (Act 843) and as outlined in this Privacy Policy. The types of
                  personal data we may collect include:
                </p>

                <ul class="list-disc ml-4 mb-2">
                  <li>
                    <strong>Identity Data:</strong> Information such as your
                    full name(s), government-issued identity number, certificate
                    of incorporation for companies, date of birth, and gender.
                    This information is essential for identity verification to
                    facilitate the use of our mobile application.
                  </li>

                  <li>
                    <strong>Contact Data:</strong> Details used for
                    communication, including your name, organization's name,
                    address, email address, telephone number, and GPS location
                    provided upon registration as a company, branch store, or
                    customer.
                  </li>

                  <li>
                    <strong>Identification Documents:</strong> Documents like
                    passport, driver's license, certificate of incorporation, or
                    any government-issued identity card, including EPA
                    Certificate and PPRS Certificate.
                  </li>

                  <li>
                    <strong>Geo-Location Information:</strong> We may request
                    access to pinpoint the location of your organization, branch
                    store, or customer. Location-based information from your
                    mobile device is tracked to offer location-based services,
                    and you can manage access permissions in your device
                    settings.
                  </li>

                  <li>
                    <strong>Mobile Device Access:</strong> Access or permission
                    to features on your mobile device, including Bluetooth,
                    calendar, camera, contacts, microphone, reminders, sensors,
                    SMS messages, social media accounts, and storage. Access to
                    these features may be granted or denied at your discretion,
                    and settings can be adjusted in your device settings.
                  </li>

                  <li>
                    <strong>Information from Other Sources:</strong> Personal
                    data from external sources may be obtained to confirm basic
                    information or for identity verification. This may include
                    limited data from public databases, marketing partners,
                    social media platforms, and other external sources. Examples
                    of information received from other sources encompass social
                    media profile details (name, gender, birthday, email,
                    current city, state, and country, user identification
                    numbers for contacts, profile picture URL, and any other
                    publicly shared information)
                  </li>

                  <li>
                    <strong>Records of Discussions:</strong> Conversations
                    between you and us, whether initiated by us or you, will be
                    recorded for reference.
                  </li>

                  <li>
                    <strong>Transaction Data:</strong> This includes specifics
                    about sales and other details related to trade transactions
                    carried out on our mobile application.
                  </li>
                </ul>

                <p>
                  As a guiding principle, we refrain from collecting information
                  related to your race, color, tribal origin, ethnicity,
                  religious beliefs, philosophical beliefs, alleged criminal
                  behavior, criminal record, sex life, sexual orientation,
                  political opinions, trade union membership, physical, medical,
                  mental health, or mental condition, genetic and biometric
                  data. In the event of legislative changes necessitating the
                  collection of such information, we assure compliance with the
                  Data Protection Act and applicable laws in Ghana.
                </p>

                <p>
                  You are responsible for updating personally identifying
                  information, including email addresses, passwords, phone
                  numbers, and the initial registrant's name. Regular password
                  changes are encouraged for account security. Ensuring the
                  accuracy of your Personal data is vital; kindly inform us of
                  any changes during your relationship with us.
                </p>
              </div>
              <div>
                <h2 className="text-2xl">
                  How We Collect And Use Your Personal Data
                </h2>
                <p>
                  The Personal data we possess about you is obtained directly
                  when you:
                </p>

                <ul class="list-disc ml-4 mb-2">
                  <li>Fill sign-up forms</li>
                  <li>Sign up for an Account</li>
                  <li>Complete the onboarding process</li>
                  <li>Fill in sale forms</li>
                  <li>Your employer signs you on the mobile application</li>
                  <li>Visit our website</li>
                  <li>Contact our customer support team</li>
                  <li>Request marketing materials</li>
                  <li>Enter a competition, promotion, or survey</li>
                </ul>

                <p>
                  Additionally, depending on your privacy settings on social
                  media and messaging services such as Facebook, LinkedIn, and
                  Instagram, you may grant us permission to access information
                  from those accounts or services. We may also receive
                  information about you from other sources, including publicly
                  available databases, which we may combine with the data we
                  already have about you.
                </p>

                <p>
                  We process your personal data for various purposes, including
                  providing, improving, and administering our services,
                  communicating with you, ensuring security and fraud
                  prevention, and complying with the law. Furthermore, we may
                  process your information for other purposes with your consent,
                  and we may verify the personal data you provide against public
                  databases.
                </p>
              </div>
              <div>
                <h2 className="text-2xl">
                  Our Legal Basis For Processing Your Personal Data
                </h2>
                <p>
                  We rely on your consent for collecting and processing your
                  personal data. Before you use the application, you voluntarily
                  provided your personal data for you to access the mobile
                  application. The verification of personal data is crucial for
                  ensuring the protection of your interests and that of your
                  business. Additionally, it serves to protect the legitimate
                  business interests and credibility of AFAP-Ghana, as well as
                  safeguarding you against fraudulent activities.
                </p>

                <p>
                  We collect your personal data because it is necessary for
                  helping us fulfill our contractual obligations to you. Without
                  your personal data, you will not be able to use the mobile
                  application.
                </p>

                <p>
                  We also have a legal obligation to ensure that we are fully
                  compliant with the Data Protection Act, 2012 (Act 843) and
                  other relevant legislation.
                </p>

                <p>
                  Information is gathered when you register on our mobile
                  application, request our services, place an order, file a
                  complaint, or fill out a form. During the registration, you
                  may be prompted to enter details such as your name,
                  organization name, email address, home or office address,
                  physical address, GPS location, date of birth, or certificate
                  of incorporation.
                </p>

                <p>
                  The collection of your personal data aids in verifying the
                  identity of application users and assists in identifying
                  agrodealers, retailers, manufacturers, customers, and all
                  parties utilizing our service. It enables us to provide the
                  necessary services and continually enhance our mobile
                  application to improve your personal experience.
                </p>
              </div>
              <div>
                <h2 className="text-2xl">How We Utilize Your Personal Data</h2>
                <p>
                  We process your information to provide, enhance, and
                  administer our Services, communicate with you, ensure security
                  and fraud prevention, and comply with legal requirements. The
                  processing of your personal data varies based on your
                  interaction with our Services, including but not limited to:
                </p>

                <ul class="list-disc ml-4 mb-2">
                  <li>
                    <strong>
                      Facilitating Account Creation and Authentication:
                    </strong>{" "}
                    Processing your information to enable account creation,
                    authentication, and overall management of user accounts,
                    ensuring smooth account functionality.
                  </li>
                  <li>
                    <strong>Service Delivery:</strong> Processing your
                    information to deliver and facilitate the requested
                    services, ensuring a seamless user experience.
                  </li>
                  <li>
                    <strong>
                      Responding to User Inquiries/Providing Support:
                    </strong>{" "}
                    Addressing user inquiries and offering support to resolve
                    potential issues related to the requested services.
                  </li>
                  <li>
                    <strong>Sending Administrative Information:</strong>{" "}
                    Processing your information to send essential details about
                    our products, services, changes to terms, policies, and
                    other relevant information.
                  </li>
                  <li>
                    <strong>Transaction Tracking and Management:</strong>{" "}
                    Monitoring and managing your transactions for a
                    comprehensive view and effective service provision.
                  </li>
                  <li>
                    <strong>Enabling User-to-User Communications:</strong>{" "}
                    Processing your information when utilizing services that
                    facilitate communication between users.
                  </li>
                  <li>
                    <strong>Requesting Feedback:</strong> Processing your
                    information as needed to request feedback and engage with
                    you regarding your use of our Services.
                  </li>
                  <li>
                    <strong>Protecting Our Services:</strong> Utilizing your
                    information to contribute to the safety and security of our
                    Services, including activities such as fraud monitoring and
                    prevention.
                  </li>
                  <li>
                    <strong>Compliance with Legal Obligations:</strong>{" "}
                    Processing your information to fulfill our legal
                    obligations, respond to legal requests, and establish,
                    exercise, or defend our legal rights.
                  </li>
                </ul>

                <p>
                  To share testimonials [with your consent], we display
                  testimonials on our websites, which may include personal data.
                  Before showcasing a testimonial, we will seek your approval to
                  use your name and testimonial. If you wish to revise or remove
                  your testimonial, kindly reach out to us at{" "}
                  <a href="mailto:nmohammed@afap-partnership.org">
                    nmohammed@afap-partnership.org
                  </a>
                  . Please include your name, the location of the testimonial,
                  and your contact information.
                </p>

                <p>
                  Assess and enhance our business operations, which may involve
                  the creation of innovative products and services.
                </p>

                <p>
                  We will solely utilize your Personal data for the specified
                  purposes for which we initially collected it, unless we
                  reasonably determine that another purpose is compatible with
                  the original one. In such cases, if you seek an explanation
                  regarding the compatibility of the new purpose with the
                  original one, please contact us. If we find the need to use
                  your Personal data for an unrelated purpose, we will inform
                  you, providing an explanation of the legal basis that permits
                  us to do so.
                </p>

                <p>
                  It's important to note that, in accordance with the
                  aforementioned principles, we may process your Personal data
                  without your knowledge or consent when required or permitted
                  by law.
                </p>
              </div>
              <div>
                <h2 className="text-2xl">Data Retention And Destruction </h2>
                <p>
                  We retain your information for the duration necessary to
                  fulfill the outlined purposes in this privacy notice, unless
                  otherwise mandated by law. The specifics of data retention are
                  as follows:
                </p>

                <ul class="list-disc ml-4 mb-2">
                  <li>
                    <strong>
                      We will retain your personal data as long as you maintain
                      an account with us and utilize our services.
                    </strong>
                  </li>
                  <li>
                    <strong>
                      Upon the termination of your account, we may continue to
                      hold your personal data if deemed necessary for historical
                      records. Adequate protection measures will be implemented
                      to prevent unauthorized access or use.
                    </strong>
                  </li>
                  <li>
                    <strong>
                      Should the decision be made not to keep your personal data
                      for historical purposes, we will delete or destroy the
                      information in a manner that prevents its reconstruction
                      in an intelligible form.
                    </strong>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="text-2xl">
                  When and How We Share Your Personal Data
                </h2>
                <p>
                  We may share your personal data in specific situations
                  outlined in this section and/or with the following third
                  parties:
                </p>

                <ul class="list-disc ml-4 mb-2">
                  <li>
                    <strong>Business Transfers:</strong> We may share or
                    transfer your information during negotiations or in
                    connection with any merger, sale of company assets,
                    financing, or acquisition involving all or part of our
                    business.
                  </li>
                  <li>
                    <strong>Google Maps Platform APIs:</strong> Certain Google
                    Maps Platform APIs, such as Google Maps API and Places API,
                    may necessitate the sharing of your information.
                  </li>
                  <li>
                    <strong>Affiliates:</strong> Your information may be shared
                    with our affiliates, including our parent company,
                    subsidiaries, joint venture partners, or other entities
                    under common control. We require these affiliates to adhere
                    to the privacy notice.
                  </li>
                  <li>
                    <strong>Business Partners:</strong> To provide you with
                    specific products, services, or promotions, we may share
                    your information with our business partners.
                  </li>
                  <li>
                    <strong>Other Users:</strong> When you share personal data,
                    such as comments or contributions, in public areas of our
                    Services, that information may be viewed by all users and
                    could be publicly accessible outside the Services
                    indefinitely. Other users may also view your activity
                    descriptions, communicate with you within our Services, and
                    access your profile.
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="text-2xl">How We Safeguard Your Information</h2>
                <p>
                  We employ a combination of organizational and technical
                  security measures to protect your personal data. Key aspects
                  of our approach include:
                </p>

                <ul class="list-disc ml-4 mb-2">
                  <li>
                    <strong>Appropriate Measures:</strong> We have implemented
                    reasonable and suitable technical and organizational
                    security measures to safeguard the personal data we process.
                    These controls are specifically designed to safeguard the
                    Personal data you provide or that we collect, minimizing the
                    risks of loss, theft, unauthorized access, disclosure,
                    copying, misuse, or modification. Our security protocols
                    extend beyond secure servers and firewalls to include data
                    encryption. Additionally, access is restricted to employees
                    fulfilling their job responsibilities, ensuring a layered
                    approach to information protection.
                  </li>
                  <li>
                    <strong>No Absolute Guarantee:</strong> Despite our efforts,
                    no electronic transmission over the Internet or information
                    storage technology can be guaranteed to be 100% secure.
                    Therefore, we cannot assure you that hackers,
                    cybercriminals, or other unauthorized third parties will be
                    unable to defeat our security measures and access, steal, or
                    modify your information.
                  </li>
                  <li>
                    <strong>Risk Acknowledgment:</strong> While we strive to
                    protect your personal data, the transmission of such
                    information to and from our Services is at your own risk. It
                    is advisable to access the Services within a secure
                    environment. In cases where a password is associated with
                    your Account, it is imperative that you maintain its
                    confidentiality and refrain from sharing it with any third
                    party. AFAP-Ghana disclaims any responsibility for losses
                    arising from the disclosure of your password.
                  </li>
                </ul>

                <p>
                  Rest assured, we are committed to doing our best to protect
                  your personal data, and we continually assess and update our
                  security measures to address emerging risks.
                </p>

                <p>
                  Our commitment to conducting business aligns with the
                  principles articulated in this Privacy Policy, emphasizing the
                  preservation of the confidentiality and protection of your
                  Personal data. While we undertake all reasonable measures to
                  secure and protect your data, it is important to acknowledge
                  that online information transmission cannot be guaranteed as
                  entirely secure. Despite this, we consistently strive to
                  ensure the secure and well-protected handling of your Personal
                  data.
                </p>
              </div>
              <div>
                <h2 className="text-2xl">Updates To Our Privacy Policy</h2>
                <p>
                  We will update this privacy policy when necessary to remain
                  compliant with relevant laws and ensure transparency in our
                  privacy practices.
                </p>

                <p>
                  We may periodically revise this privacy notice. The updated
                  version will be marked with a revised date and will become
                  effective as soon as it is accessible. If substantial changes
                  are made to this privacy notice, we may notify you by
                  prominently posting a notice of these changes or directly
                  sending you a notification. We strongly encourage you to
                  regularly review this privacy notice to stay informed about
                  how we protect and handle your information.
                </p>
              </div>
              <div>
                <h2 className="text-2xl">Your privacy Rights</h2>
                <p>
                  In addition to the rights enumerated in Article 18 of the 1992
                  Constitution of the Republic of Ghana and Section 35 of the
                  Data Protection Act, 2012 (Act 843), you, as a user, possess
                  the following rights regarding your Personal data:
                </p>

                <ul class="list-disc ml-4 mb-2">
                  <li>
                    <strong>Right to be informed:</strong> You have the right to
                    be informed about the processing of your Personal data.
                  </li>
                  <li>
                    <strong>
                      Right to request access or copies to Your Personal data:
                    </strong>{" "}
                    You can request access to or copies of your Personal data by
                    signing into your account or contacting us.
                  </li>
                  <li>
                    <strong>
                      Right to request erasure of Your Personal data:
                    </strong>{" "}
                    You have the right to request the deletion of your Personal
                    data. This right is limited and applies when the data is no
                    longer necessary for the purpose for which it was collected,
                    or the processing lacks legal justification. Exceptions
                    include situations where retaining the data is required for
                    public interest, legal claims, or to ensure non-contact for
                    marketing purposes.
                  </li>
                  <li>
                    <strong>Right to correct or rectify Personal data:</strong>{" "}
                    You can request corrections to any incorrect, outdated, or
                    inaccurate Personal data. You also have the right to ask us
                    to complete information that you believe is incomplete.
                  </li>
                  <li>
                    <strong>
                      Right to object to the processing of Personal data for
                      marketing purposes:
                    </strong>{" "}
                    You can object to the processing of your Personal data for
                    marketing purposes. Adjust your notification preferences on
                    the settings page or opt out via the unsubscribe link in
                    marketing emails.
                  </li>
                  <li>
                    <strong>Right to object to processing:</strong> You have the
                    right to object to the processing of your Personal data in
                    certain circumstances. Note that objecting may result in an
                    inability to provide certain services.
                  </li>
                </ul>

                <p>
                  If you wish to exercise any of the rights mentioned above,
                  please contact us using the provided contact information. For
                  security reasons, we may ask for further proof of your
                  identity if there is uncertainty.
                </p>
              </div>
              <div>
                <h2 className="text-2xl">
                  Dispute Resolution And Filing A Complaint
                </h2>
                <p>
                  If you have complaints regarding this Privacy Policy, please
                  contact our Data Protection Officer via email at
                  <a href="mailto:nmohammed@afap-partnership.org">
                    nmohammed@afap-partnership.org
                  </a>{" "}
                  or
                  <a href="mailto:ghana@afap-partnership.org">
                    ghana@afap-partnership.org
                  </a>
                  , titling the email “Privacy Policy Complaint”. We will
                  investigate and work on resolutions for complaints and
                  disputes within a reasonable time, following this Privacy
                  Policy and applicable data protection laws.
                </p>

                <p>
                  All access requests, questions, comments, complaints, and
                  other inquiries regarding the Privacy Policy should be sent to
                  our Data Protection Officer.
                </p>

                <h2 className="text-2xl">
                  HOW TO CONTACT US ABOUT THIS NOTICE
                </h2>
                <p>
                  If you have questions or comments about this notice, you may
                  contact our Data Protection Officer (DPO), via email at{" "}
                  <a href="mailto:nmohammed@afap-partnership.org">
                    nmohammed@afap-partnership.org
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
