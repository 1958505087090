import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../auth/authHandler";
import Template from "../components/template";
import CompanyTypes from "../DashboardGraphs/CompanyTypes";
import HeaderStats from "../DashboardGraphs/HeaderStats";
import StockAvailabilityGraph from "../DashboardGraphs/StockAvailability";

export default function Home() {
  const { user, country, setCountry, userDetails } = useContext(AuthContext);
  const [selectedCountry, setSelectedCountry] = useState(
    country ? country.id : null
  );

  useEffect(() => {
    getCountry();
  }, []);

  function getCountry() {
    const userString = sessionStorage.getItem("country");
    const userCountry = JSON.parse(userString);
    if (userCountry) {
      setSelectedCountry(userCountry.id);
    }
    return userCountry;
  }
  return (
    <Template>
      {/* <div className="w-full mt-10  p-4 text-md">
        <div className="grid  grid-cols-1 md:grid-cols-2 gap-6">
          <div className=" bg-gray-100  h-64 rounded shadow-xl"></div>
        </div>
      </div> */}
      <div className=" w-full mt-20  py-4 text-md">
        {selectedCountry ? (
          <HeaderStats country_id={selectedCountry} user={user} />
        ) : null}
      </div>
      <div className="w-full p-4 mt-10 text-md">
        <div className="grid  grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-white py-8  flex justify-center items-center  rounded shadow-xl">
            {/* <Chart
              options={chartLine.options}
              series={chartLine.series}
              type="line"
              width="550"
            /> */}
            {selectedCountry ? (
              <CompanyTypes country_id={selectedCountry} user={user} />
            ) : null}
          </div>
          <div className="bg-white py-8 flex justify-center   rounded shadow-xl">
            {selectedCountry ? (
              <StockAvailabilityGraph
                country_id={selectedCountry}
                user={user}
              />
            ) : null}
          </div>
        </div>
      </div>
    </Template>
  );
}
