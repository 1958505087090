import React, { useState, useEffect, useRef } from "react";
import "@tomtom-international/web-sdk-maps/dist/maps.css";
import * as tt from "@tomtom-international/web-sdk-maps";

export default function Map({
  show,
  company,
  company_type,
  modalSwitch,
  type,
}) {
  const mapElement = useRef();

  const [mapLongitude, setMapLongitude] = useState(
    company.geo_coordinates.longitude
  );
  const [mapLatitude, setMapLatitude] = useState(
    company.geo_coordinates.latitude
  );
  const [mapZoom, setMapZoom] = useState(13);
  const [map, setMap] = useState(null);

  const [showModal, setShowModal] = useState(show);

  //   const increaseZoom = () => {
  //     if (mapZoom < 25) {
  //       setMapZoom(mapZoom + 1);
  //     }
  //   };

  //   const decreaseZoom = () => {
  //     if (mapZoom > 1) {
  //       setMapZoom(mapZoom - 1);
  //     }
  //   };
  //   const updateMap = () => {
  //     map.setCenter([parseFloat(mapLongitude), parseFloat(mapLatitude)]);
  //     map.setZoom(mapZoom);
  //   };
  useEffect(() => {
    let map = tt.map({
      key: "moqPmYQ96dQAdbOGZJWtdm4bf6UQhdih",
      container: mapElement.current,
      center: [mapLongitude, mapLatitude],
      zoom: mapZoom,
    });
    setMap(map);

    return () => map.remove();
  }, []);

  // console.log(company_type);
  if (map) {
    var markerElement = document.createElement("div");

    markerElement.innerHTML =
      company_type === "Retailer"
        ? `<i class='fas fa-home text-xl' style='color:blue'></i>`
        : "<i class='fas fa-warehouse text-xl' style='color:red'></i>";

    // let marker = new tt.Marker({ element: markerElement })
    //   .setLngLat([mapLatitude, mapLongitude])
    //   .addTo(map);

    var popupOffsets = {
      top: [0, 0],
      bottom: [0, -40],
      "bottom-right": [0, -70],
      "bottom-left": [0, -70],
      left: [25, -35],
      right: [-25, -35],
    };

    const popUpContent =
      "<div>" +
      `<p style='font:20px'>${company.name}</p>` +
      `<p>${company_type}</p>` +
      `<p>${company.address}</p>` +
      `<p>${company.geo_coordinates.longitude},${company.geo_coordinates.latitude}</p>` +
      "</div>";

    var popup = new tt.Popup({ offset: popupOffsets }).setHTML(popUpContent);
    let marker = new tt.Marker({ element: markerElement })
      .setLngLat([mapLatitude, mapLongitude])
      .addTo(map)
      .setPopup(popup)
      .addTo(map);
  }

  if (showModal === false) {
    return null;
  } else
    return (
      <>
        <div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-2/3 max-w-3xl mx-auto my-6 h-2/3">
            {/*content*/}
            <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                <div>
                  <h3 className="text-3xl font-semibold">{`${type} Location`}</h3>
                  <h3 className="mt-4 text-2xl font-semibold text-gray-600">
                    {company.name}
                  </h3>
                </div>
                <button
                  className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                  onClick={() => setShowModal(false)}
                >
                  <span className="block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              {/*body*/}
              <div className="relative flex-auto w-full h-full p-6 ">
                <div ref={mapElement} id="map" className="w-full h-96" />
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
                <button
                  className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                  onClick={modalSwitch}
                >
                  Close
                </button>
                {/* <button
                  className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-green-500 rounded shadow outline-none active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  Save Changes
                </button> */}
              </div>
            </div>
          </div>
        </div>
        <div className="fixed inset-0 z-30 bg-black opacity-25"></div>
      </>
    );
}
