import React, { useState, useEffect, useRef } from "react";
import "@tomtom-international/web-sdk-maps/dist/maps.css";
import * as tt from "@tomtom-international/web-sdk-maps";
import axios from "axios";
import Template from "../components/template";
import { useGetAllCountries } from "../Settings/CountryConfiguration/CountryService";
import debounce from "lodash.debounce";
import { useGetMapBranches } from "./ClientMapServices";

export default function ClientMaps() {
  const mapElement = useRef();
  const [value, setValue] = useState("");
  const [search, setSearch] = useState("");
  const [mapZoom, setMapZoom] = useState(8);
  const [map, setMap] = useState(null);
  const [mapLongitude, setMapLongitude] = useState(-0.1969);
  const [mapLatitude, setMapLatitude] = useState(5.55602);
  const [selectedCountry, setSelectedCountry] = useState(getCountry().id || "");
  const [headingClicked, setHeadingClicked] = useState(false);

  function isMobileOrTablet() {
    var i,
      a = !1;
    return (
      (i = navigator.userAgent || navigator.vendor || window.opera),
      (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        i
      ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          i.substr(0, 4)
        )) &&
        (a = !0),
      a
    );
  }
  window.isMobileOrTablet = window.isMobileOrTablet || isMobileOrTablet;

  function getUser() {
    const userString = sessionStorage.getItem("user");
    const userCred = JSON.parse(userString);
    return userCred;
  }

  function getCountry() {
    const userString = sessionStorage.getItem("country");
    const userCountry = JSON.parse(userString);
    return userCountry;
  }

  const [countries] = useGetAllCountries();

  const onChangeSearchInput = (e) => {
    setValue(e.target.value);
    setTimeout(() => {
      setSearch(e.target.value);
    }, 2000);
  };

  const [branches, dataLoading, error] = useGetMapBranches(
    selectedCountry,
    search,
    false
  );

  useEffect(() => {
    let map;
    if (process.env.REACT_APP_MAP_API_KEY) {
      map = tt.map({
        key: process.env.REACT_APP_MAP_API_KEY,
        container: mapElement.current,
        center: [mapLongitude, mapLatitude],
        zoom: mapZoom,
        dragPan: !isMobileOrTablet(),
      });
      setMap(map);
    } else {
      console.log("no api key for map found");
    }
    if (map) {
      map.addControl(new tt.FullscreenControl());
    }

    return () => map.remove();
  }, [search]);

  const mapMarker = () => {
    // let marker;

    if (branches && branches.length !== 0) {
      const markerShops = [];
      const list = document.getElementById("store-list");
      document.querySelectorAll(".list-entry").forEach((e) => e.remove());
      document
        .querySelectorAll(".list-entry-search")
        .forEach((e) => e.remove());
      branches.forEach((store, index) => {
        if (store.geo_coordinates) {
          var markerElement = document.createElement("div");
          const store_type = store.branch_type.type;

          markerElement.innerHTML =
            store.branch_type.type === "Retailer"
              ? `<i class='fas fa-home text-xl' style='color:blue'></i>`
              : "<i class='fas fa-warehouse text-xl' style='color:red'></i>";

          var popupOffsets = {
            top: [0, 0],
            bottom: [0, -40],
            "bottom-right": [0, -70],
            "bottom-left": [0, -70],
            left: [25, -35],
            right: [-25, -35],
          };

          const popUpContent =
            "<div>" +
            `<p style='font:20px'>${store.name}</p>` +
            `<p style='font:20px'>${store.company.name}</p>` +
            `<p>${store.branch_type.type}</p>` +
            `<p>${store.city}</p>` +
            `<p>${
              store.afap_number ? store.afap_number : "No AFAP number assigned"
            }</p>` +
            "</div>";

          var popup = new tt.Popup({ offset: popupOffsets }).setHTML(
            popUpContent
          );
          let marker;
          try {
            marker = new tt.Marker({ element: markerElement })
              .setLngLat([
                store.geo_coordinates.longitude,
                store.geo_coordinates.latitude,
              ])
              .setPopup(popup)
              .addTo(map);

            function buildLocation(htmlParent, text, class_name) {
              const details = htmlParent.appendChild(
                document.createElement("p")
              );
              details.className = class_name;
              details.innerHTML = text;
              return details;
            }

            markerShops[index] = { marker, store_type };

            let details;
            if (search.length == 0) {
              let storesTypeList = document.getElementById(store_type);
              document
                .querySelectorAll(".store_type_full")
                .forEach((e) => (e.style.display = "block"));
              if (storesTypeList === null) {
                const storesListHeading = list.appendChild(
                  document.createElement("h3")
                );
                storesListHeading.innerHTML = store_type;
                storesListHeading.className = `store_type_full`;
                storesTypeList = list.appendChild(
                  document.createElement("div")
                );
                storesTypeList.id = store_type;
                storesTypeList.className = "list-entries-container";
              }

              details = buildLocation(storesTypeList, store.name, "list-entry");
            } else {
              markerShops[index] = { marker, store_type };

              document
                .querySelectorAll(".store_type_full")
                .forEach((e) => (e.style.display = "none"));

              details = buildLocation(list, store.name, "list-entry-search");
            }

            marker._element.addEventListener(
              "click",
              (function (details, store_type) {
                const activeItem = document.getElementsByClassName("selected");
                return function () {
                  if (activeItem[0]) {
                    activeItem[0].classList.remove("selected");
                  }
                  details.classList.add("selected");
                };
              })(details, store_type)
            );

            details.addEventListener(
              "click",
              (function (marker) {
                const activeItem = document.getElementsByClassName("selected");
                return function () {
                  if (activeItem[0]) {
                    activeItem[0].classList.remove("selected");
                  }
                  details.classList.add("selected");
                  map.easeTo({
                    center: marker.getLngLat(),
                    zoom: 25,
                  });
                  closeAllPopups();
                  marker.togglePopup();
                };
              })(marker)
            );
            function closeAllPopups() {
              markerShops.forEach((markerCity) => {
                if (markerCity.marker.getPopup().isOpen()) {
                  markerCity.marker.togglePopup();
                }
              });
            }
          } catch (error) {
            console.log("map error", error);
          }
        } else return null;
      });

      accordionMenu("store_type_full");
    }
  };

  const accordionMenu = (className) => {
    var acc = document.getElementsByClassName(className);
    function toggleDisplay() {
      this.classList.toggle("active");
      console.log("this", this);

      var panel = this.nextElementSibling;

      console.log("next sib", panel);

      if (panel.style.display === "block") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    }
    for (var i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", toggleDisplay);
    }
  };

  useEffect(() => {
    mapMarker();
  }, [branches]);

  return (
    <Template>
      <div className="w-full p-4 mt-10 text-md">
        <h1 className="mb-8 text-4xl text-center text-green-700">Map</h1>
        <div className="flex w-full p-6 " style={{ height: "80vh" }}>
          <div className="top-0 left-0 w-2/5 h-full pb-4 overflow-hidden bg-white shadow-md">
            <div className="relative pt-4 bg-white border-gray-400 shadow-sm border-b-1">
              {countries && countries.length !== 0 ? (
                <select
                  onChange={(e) => setSelectedCountry(e.target.value)}
                  value={selectedCountry}
                  className="block w-full h-full px-4 py-2 pr-8 mb-4 text-xl leading-tight text-center text-gray-700 text-green-700 bg-white appearance-none "
                >
                  {countries.map((country) => (
                    <option
                      className="text-sm "
                      value={country.id}
                      key={country.id}
                    >
                      {country.name}
                    </option>
                  ))}
                </select>
              ) : null}
              {/* <h1 className="mb-4 text-4xl text-center text-green-700">
                {country ? country.name : ""}
              </h1> */}
              <div className="w-full h-12 px-2 bg-transparent border rounded lg:px-6">
                <div className="relative flex flex-wrap items-stretch w-full h-full mb-6">
                  <div className="flex">
                    <span className="flex items-center py-2 text-sm leading-normal whitespace-no-wrap bg-transparent border border-r-0 border-none rounded rounded-r-none lg:px-3 text-grey-500">
                      <svg
                        width="18"
                        height="18"
                        className="w-4 lg:w-auto"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.11086 15.2217C12.0381 15.2217 15.2217 12.0381 15.2217 8.11086C15.2217 4.18364 12.0381 1 8.11086 1C4.18364 1 1 4.18364 1 8.11086C1 12.0381 4.18364 15.2217 8.11086 15.2217Z"
                          stroke="#455A64"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.9993 16.9993L13.1328 13.1328"
                          stroke="#455A64"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="relative flex-auto flex-grow flex-shrink w-px px-3 font-thin leading-normal tracking-wide text-gray-500 border border-l-0 border-none rounded rounded-l-none focus:outline-none text-xxs xl:text-xs lg:text-base"
                    placeholder="search for branch"
                    onChange={(e) => onChangeSearchInput(e)}
                    value={value}
                  />
                  {value ? (
                    <i
                      className="my-auto text-center text-gray-500 fas fa-times-circle hover:text-green-700"
                      onClick={() => {
                        setValue("");
                        setSearch("");
                      }}
                    ></i>
                  ) : null}
                </div>
              </div>
            </div>

            <div id="store-list"></div>
          </div>

          <div ref={mapElement} id="map" className="w-full h-full" />
        </div>
      </div>
    </Template>
  );
}
