import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../auth/authHandler";
import Chart from "react-apexcharts";
import axios from "axios";
// import { array } from "prop-types";
import { useNavigate } from "react-router-dom";
import { useGetCompanyTypesReport } from "../CompanyManagement/CompanyService";

export default function CompanyTypes({ country_id, user }) {
  const navigate = useNavigate();
  // const [companyTotal, setCompanyTotal] = useState([]);

  const [companyTotal, dataLoading, error] = useGetCompanyTypesReport(
    country_id,
    user
  );

  const key = companyTotal
    ? companyTotal.map((company) => company.type)
    : ["loading"];
  const value = companyTotal
    ? companyTotal.map((company) => company.total)
    : ["loading"];

  let chartCompanyType = {
    options: {
      title: {
        text: "Company Types",
        align: "center",
      },
      colors: ["#6EE7B7", "#059669", "#047857"],
      chart: {
        width: "500",
        events: {
          dataPointSelection: (event, chartContext, config) => {
            navigate(
              `/companies/${config.w.config.labels[config.dataPointIndex]}`
            );
          },
        },
        toolbar: {
          show: false,
        },
      },

      labels: key,
      // labels: ["Manufacturer", "Hub", "Retailer"],
    },
    noData: {
      text: "Loading...",
    },

    series: value,
  };
  return (
    <div>
      <Chart
        options={chartCompanyType.options}
        series={chartCompanyType.series}
        type="pie"
        width="500"
      />
    </div>
  );
}
