import React, { useEffect, useState } from "react";
import { getApi } from "../hooks/getApi";

export function useGetAllProducts(countryId, pagination) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const [metaData, setMetaData] = useState(null);

  useEffect(() => {
    const getAllProducts = async () => {
      const data = await getApi(
        `admin/countries/${countryId}/products?page=${pagination}`,
        setDataLoading
      );

      if (data) {
        setProducts(data.data);
        setMetaData(data.meta);
      }
    };
    getAllProducts();
  }, [pagination]);

  return [products, metaData, dataLoading, error];
}
export function useGetProductCategories() {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getProductCategories = async () => {
      const data = await getApi(`admin/products/categories`, setDataLoading);

      if (data) {
        setCategories(data.data);
      }
    };
    getProductCategories();
  }, []);

  return [categories, dataLoading, error];
}
export function useGetSupportedProductCategories(category) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [supportedProductById, setSupportedProductById] = useState([]);

  useEffect(() => {
    const getSupportedProductByIB = async () => {
      const data = await getApi(
        `admin/products/categories/${category}/supported-products`,
        setDataLoading
      );

      if (data) {
        setSupportedProductById(data.data);
      }
    };
    getSupportedProductByIB();
  }, [category]);

  return [supportedProductById, dataLoading, error];
}
export function useGetProductManufacturers(countryId) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [manufacturers, setManufacturers] = useState([]);

  useEffect(() => {
    const getProductManufacturers = async () => {
      const data = await getApi(
        `admin/company/manufacturer/${countryId}?limit=false`,
        setDataLoading
      );

      if (data) {
        setManufacturers(data.data);
      }
    };
    getProductManufacturers();
  }, []);
  return [manufacturers, dataLoading, error];
}
export function useGetProductUnits() {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [units, setUnits] = useState([]);

  useEffect(() => {
    const getProductUnits = async () => {
      const data = await getApi(`packaging/units`, setDataLoading);

      if (data) {
        setUnits(data.data);
      }
    };
    getProductUnits();
  }, []);

  return [units, dataLoading, error];
}

export function useGetCompanyBranchProducts(storeID) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const getCompanyBranches = async () => {
      const data = await getApi(
        `/branches/${storeID}/products`,
        setDataLoading
      );

      if (data) {
        setProducts(data.data);
        // setProductsAll(response.data);
      }
    };
    getCompanyBranches();
  }, [storeID]);

  return [products, dataLoading, error];
}
export function useGetProductCountPerCategory() {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [productCategoryCount, setProductCategoryCount] = useState([]);

  useEffect(() => {
    const getCompanyBranches = async () => {
      const data = await getApi(
        `admin/reports/products/categories/total/stock-available`,
        setDataLoading
      );

      if (data) {
        setProductCategoryCount(data.data);
        // setProductsAll(response.data);
      }
    };
    getCompanyBranches();
  }, []);

  return [productCategoryCount, dataLoading, error];
}
