import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Template from "../components/template";
import axios from "axios";
// import env from "react-dotenv";

// axios.defaults.baseURL = window.env.API_URL;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export default function AddManufacturer() {
  // const [loaded, setLoaded] = useState({});
  const [error, setError] = useState(null);
  const [user, setUser] = useState({
    name: "",
    phone_number: "",
    address: "",
    email: "",
    city: "",
    country: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [countries, setCountries] = useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getAllCountries();
  }, []);

  function getUser() {
    const userString = sessionStorage.getItem("user");
    const userCred = JSON.parse(userString);
    // setLoaded(userCred);
    return userCred;
  }

  function getCountry() {
    const userString = sessionStorage.getItem("country");
    const userCountry = JSON.parse(userString);
    // setCountry(userCountry);
    return userCountry;
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  axios.defaults.headers.common["Authorization"] = `Bearer ${getUser().token}`;

  const getAllCountries = async () => {
    const data = await axios
      .get(`admin/countries`)
      .then((response) => {
        return response.data.data;
      })
      .catch((err) => {
        console.log(err?.response?.status);
        console.log("country", err?.response?.data?.message);
      });
    setCountries(data);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      let response = await axios.post(
        "admin/company/manufacturer",
        {
          name: user.name,
          phone_number: user.phone_number,
          address: user.address,
          email: user.email,
          country_id: user.country,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      setShowModal(true);
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);

      if (error.response.status >= 500) {
        setError("Well, this unexpected. We are sorry");
      } else {
        setError(error.response.data.message);
      }
    }
  };

  return (
    <Template>
      <div className="w-full mt-10  p-4 text-md">
        <h1 className="text-center text-4xl mb-8 text-green-700">
          Add Manufacturer
        </h1>
        <div className="mt-5 md:mt-0 md:col-span-2">
          {showModal ? (
            <>
              <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                // onClick={() => setShowModal(false)}
              >
                <div className="relative w-auto my-6  mx-auto max-w-3xl">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5  rounded-t">
                      <h3 className="text-3xl font-semibold px-6 pt-10">
                        Successfully Added Manufacturer
                      </h3>
                      {/* <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowModal(false)}
                      >
                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button> */}
                    </div>
                    {/*body*/}
                    <div className="w-full flex justify-center">
                      <svg
                        className="w-64 h-64 text-green-600 flex text-center"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6  rounded-b">
                      <button
                        className="bg-green-600 w-full text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        style={{ transition: "all .15s ease" }}
                        onClick={() => navigate("/companies")}
                      >
                        Okay
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
          <form onSubmit={onSubmit}>
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                {error && <p className="text-red-500  mt-2">{error}</p>}
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 ">
                    <label
                      htmlFor="first_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Manufacturer name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={user.name}
                      onChange={handleChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full  sm:text-sm border px-2 py-2  border-green-600 rounded"
                    />
                  </div>

                  <div className="col-span-6 ">
                    <label
                      htmlFor="email_address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      E-Mail
                    </label>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      value={user.email}
                      onChange={handleChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full  sm:text-sm border px-2 py-2 border-green-600 rounded"
                    />
                  </div>
                  <div className="col-span-6 ">
                    <label
                      htmlFor="email_address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Phone Number
                    </label>
                    <input
                      type="text"
                      name="phone_number"
                      id="phone_number"
                      value={user.phone_number}
                      onChange={handleChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full  sm:text-sm border px-2 py-2 border-green-600 rounded"
                    />
                  </div>
                  <div className="col-span-6">
                    <label
                      htmlFor="street_address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Address
                    </label>
                    <input
                      type="text"
                      name="address"
                      id="address"
                      value={user.address}
                      onChange={handleChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full  sm:text-sm border px-2 py-2 border-green-600 rounded"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6 lg:col-span-3">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium text-gray-700"
                    >
                      City
                    </label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      value={user.city}
                      onChange={handleChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full  sm:text-sm border px-2 py-2 border-green-600 rounded"
                    />
                  </div>

                  {/* <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label
                      htmlFor="state"
                      className="block text-sm font-medium text-gray-700"
                    >
                      State / Province
                    </label>
                    <input
                      type="text"
                      name="state"
                      id="state"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full  sm:text-sm border px-2 py-2 border-green-600 rounded"
                    />
                  </div> */}

                  <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Country / Region
                    </label>
                    <select
                      id="country"
                      name="country"
                      value={user.country}
                      onChange={handleChange}
                      className="mt-1 block w-full px-2 py-2  border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option>Select country</option>
                      {getUser() &&
                      getUser().role &&
                      getUser().role.role === "Admin" ? (
                        countries && countries.length != 0 ? (
                          countries.map((country) => {
                            return (
                              <option key={country.id} value={country.id}>
                                {country.name}
                              </option>
                            );
                          })
                        ) : null
                      ) : (
                        <option value={getCountry().id}>
                          {getCountry().name}
                        </option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 flex justify-between text-right sm:px-6">
                <Link
                  to="/companies/"
                  className="inline-flex justify-center rounded bg-red-800 px-4   py-2 text-white  mr-2"
                >
                  Cancel
                </Link>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="inline-flex justify-center rounded bg-green-800 px-4  py-2 text-white  mr-2"
                >
                  {isSubmitting ? "saving..." : "save"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Template>
  );
}
