import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import Map from "../components/Map";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Template from "../components/template";
import { useGetCompanyBranch } from "./CompanyService";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export default function StoreByID() {
  const [mapModal, setMapModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const store = location.state.store;
  const company = location.state.company;

  const modalSwitch = () => {
    if (mapModal === true) {
      setMapModal(false);
    } else setMapModal(true);
  };
  function getUser() {
    const userString = sessionStorage.getItem("user");
    const userCred = JSON.parse(userString);
    return userCred;
  }

  const [storeInfo, dataLoading, error] = useGetCompanyBranch(store.id);

  return (
    <Template>
      {storeInfo ? (
        <div className="w-full mt-10  p-4 text-md">
          <h1 className="text-center mt-8 text-4xl mb-8 text-green-700">
            {company.name}
          </h1>
          {mapModal ? (
            <Map
              modalSwitch={modalSwitch}
              show={mapModal}
              company={storeInfo}
              company_type={storeInfo.branch_type.type}
              type="Branch"
            />
          ) : null}
          <div className="w-full flex justify-start mb-8 px-12">
            <Link
              className="flex rounded bg-green-800 px-4 py-2 text-white "
              to={{
                pathname: `/company/${company.name}`,
                state: company,
              }}
            >
              <svg
                className="w-6 h-6 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M7 16l-4-4m0 0l4-4m-4 4h18"
                />
              </svg>
              Back
            </Link>
          </div>
          <div className="pt-10 px-10 ">
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Store Name
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {storeInfo.name}
                    </dd>
                  </div>

                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Email address
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {storeInfo.email ? storeInfo.email : "not available"}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Address
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {storeInfo.address ? storeInfo.address : "not available"}
                    </dd>
                  </div>

                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Region
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {storeInfo.region
                        ? storeInfo.region.name
                        : "not available"}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      District
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {storeInfo.district
                        ? storeInfo.district.name
                        : "not available"}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      City/Township
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {storeInfo.city ? storeInfo.city : "not available"}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Phone Number
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {storeInfo.phone_number
                        ? storeInfo.phone_number
                        : "not available"}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      AFAP Number
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {storeInfo.afap_number
                        ? storeInfo.afap_number
                        : "not available"}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Geo Location
                    </dt>
                    {storeInfo.geo_coordinates &&
                    storeInfo.geo_coordinates.longitude &&
                    storeInfo.geo_coordinates.latitude ? (
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {
                          (storeInfo.geo_coordinates.longitude,
                          storeInfo.geo_coordinates.latitude)
                        }

                        <button
                          className=" ml-4  bg-green-700 text-sm px-2 py-1 text-white hover:bg-green-900 hover:text-yellow-300 rounded-md"
                          onClick={() => setMapModal(true)}
                        >
                          view on map
                        </button>
                      </dd>
                    ) : (
                      <p>not available</p>
                    )}
                  </div>
                  {/* <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Company Type</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim
                  incididunt cillum culpa consequat. Excepteur qui ipsum aliquip
                  consequat sint. Sit id mollit nulla mollit nostrud in ea
                  officia proident. Irure nostrud pariatur mollit ad adipisicing
                  reprehenderit deserunt qui eu.
                </dd>
              </div> */}
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Type</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {storeInfo.branch_type
                        ? storeInfo.branch_type.type
                        : "not available"}
                    </dd>
                  </div>
                  {storeInfo.supporting_documents ? (
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Supporting Documents
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {storeInfo.supporting_documents &&
                        storeInfo.supporting_documents.length > 0 ? (
                          storeInfo.supporting_documents.map((doc) => (
                            <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                              <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                <div className="w-0 flex-1 flex items-center">
                                  <svg
                                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  <span className="ml-2 flex-1 w-0 truncate">
                                    {doc.supported_documents.type}
                                  </span>
                                </div>
                                <div className="ml-4 flex-shrink-0">
                                  <a
                                    href={doc.url}
                                    className="font-medium text-indigo-600 hover:text-indigo-500"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                  >
                                    Download
                                  </a>
                                </div>
                              </li>
                            </ul>
                          ))
                        ) : (
                          <p>not available</p>
                        )}
                      </dd>
                    </div>
                  ) : null}
                </dl>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Template>
  );
}
