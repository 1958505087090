import { useEffect, useState } from "react";
import { getApi } from "../../hooks/getApi";

export function useGetAllCountries() {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const getAllCountries = async () => {
      const data = await getApi(`admin/countries`, setDataLoading);

      if (data) {
        setCountries(data.data);
      }
    };
    getAllCountries();
  }, []);

  return [countries, dataLoading, error];
}

export function useGetAllRegions(countryId) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    const getAllRegions = async () => {
      // const token = await getToken();

      setDataLoading(true);

      const data = await getApi(
        `admin/countries/${countryId}/regions`,
        setDataLoading
      );

      if (data) {
        setRegions(data.data);
      }
    };
    getAllRegions();
  }, [countryId]);

  return [regions, error, dataLoading];
}

export function useGetAllDistricts(selectedRegion, regions) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [districts, setDistricts] = useState(null);

  useEffect(() => {
    const getAllDistricts = async () => {
      const data = await getApi(
        `admin/countries/regions/${selectedRegion}/districts`,
        setDataLoading
      );

      if (data) {
        setDistricts(data.data);
      }
    };
    if (regions && regions.length > 0) {
      getAllDistricts();
    } else setDistricts([]);
  }, [selectedRegion, regions]);

  return [districts, error, dataLoading];
}
