import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Template from "../components/template";
import axios from "axios";
import { useGetRoles } from "./userManagementService";
import { useGetPlatformCompanies } from "../CompanyManagement/CompanyService";

export default function SendUserInvite() {
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState({
    email: "",
    role: "",
    platform_company: "",
  });

  function getUser() {
    const userString = sessionStorage.getItem("user");
    const userCred = JSON.parse(userString);
    return userCred;
  }

  const [roles] = useGetRoles();
  const [platformCompanies] = useGetPlatformCompanies();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  axios.defaults.headers.common["Authorization"] = `Bearer ${getUser().token}`;

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      let response = await axios.post(
        "companies/platform-company/invite/user",
        {
          email: user.email,
          company_id: user.platform_company,
          role_id: user.role,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      setShowModal(true);
    } catch (error) {
      if (error.response.status >= 500) {
        setError("Well, this unexpected. We are sorry");
      } else {
        setError(error?.response?.data?.message);
      }
    }
  };
  return (
    <Template>
      <div className="w-full h-5/6  mt-10  p-4 text-md">
        <h1 className="text-center text-4xl mb-8 text-green-700">
          Send Invite
        </h1>
        <div className="mt-5 md:mt-0 md:col-span-2  h-full">
          {showModal ? (
            <>
              <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                // onClick={() => setShowModal(false)}
              >
                <div className="relative w-auto my-6  mx-auto max-w-3xl">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5  rounded-t">
                      <h3 className="text-3xl font-semibold px-6 pt-10">
                        Invite Successfully Sent
                      </h3>
                      {/* <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowModal(false)}
                      >
                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button> */}
                    </div>
                    {/*body*/}
                    <div className="w-full flex justify-center">
                      <svg
                        className="w-64 h-64 text-green-600 flex text-center"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      {/* <h1 className="text-green-600 text-2xl text-center">
                        Approved
                      </h1> */}
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6  rounded-b">
                      <button
                        className="bg-green-600 w-full text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        style={{ transition: "all .15s ease" }}
                        onClick={() => setShowModal(false)}
                      >
                        Okay
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}

          <form onSubmit={onSubmit}>
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                {error && <p className="text-red-500  mt-2">{error}</p>}
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 ">
                    <label
                      htmlFor="email_address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      E-Mail
                    </label>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      value={user.email}
                      onChange={handleChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full  sm:text-sm border px-2 py-2 border-green-600 rounded"
                    />
                  </div>
                </div>
                <div className="col-span-3 sm:col-span-1">
                  <label
                    htmlFor="country"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Platform Company
                  </label>
                  <select
                    id="platform_company"
                    name="platform_company"
                    onChange={handleChange}
                    // value={}
                    // autocomplete="country"
                    className="mt-1 block w-full border py-2 border-green-600  px-3 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option>Select Company</option>
                    {getUser().role.role === "Admin" ? (
                      platformCompanies && platformCompanies.length !== 0 ? (
                        platformCompanies.map((company) => {
                          return (
                            <option value={company.id}>{company.name}</option>
                          );
                        })
                      ) : null
                    ) : (
                      <option value={getUser().company.id}>
                        {getUser().company.name}
                      </option>
                    )}
                  </select>
                </div>
                <div className="col-span-6 sm:col-span-1">
                  <label
                    htmlFor="country"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Role
                  </label>
                  <select
                    id="role"
                    name="role"
                    onChange={handleChange}
                    // value={packaging.packaging_unit_id}
                    // autocomplete="country"
                    className="mt-1 block w-full border py-2 border-green-600  px-3 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option>Select Unit</option>
                    {roles && roles.length !== 0
                      ? roles.map((role) => {
                          return <option value={role.id}>{role.role}</option>;
                        })
                      : null}
                  </select>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 flex justify-between text-right sm:px-6">
                <Link
                  to="/user_management"
                  className="inline-flex justify-center rounded bg-red-800 px-4   py-2 text-white  mr-2"
                >
                  Cancel
                </Link>
                <button
                  type="submit"
                  className="inline-flex justify-center rounded bg-green-800 px-4   py-2 text-white  mr-2"
                >
                  Send Invite
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Template>
  );
}
