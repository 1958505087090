import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Template from "../../components/template";
// import env from "react-dotenv";
import axios from "axios";
import moment from "moment";
import Pagination from "../../components/pagination";
import Table from "../../components/table";
import Loading from "../../components/Loading";
import { useGetSalesValue } from "./SalesService";
import {
  useGetAllCountries,
  useGetAllDistricts,
  useGetAllRegions,
} from "../../Settings/CountryConfiguration/CountryService";
import { yearRange } from "../../utilities/calculateYearRange";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export default function SalesValue() {
  const [selected, setSelected] = useState(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(
    `${moment().format("MMMM")}-${selectedYear}`
  );
  const [limit, setLimit] = useState(15);
  const [pagination, setPagination] = useState(1);
  const [sortPeriod, setSortPeriod] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(getCountry().id);
  const [selectedRegion, setSelectedRegion] = useState(null);

  const [selectedDistrict, setSelectedDistrict] = useState(null);

  function getUser() {
    const userString = sessionStorage.getItem("user");
    const userCred = JSON.parse(userString);
    return userCred;
  }

  function getCountry() {
    const userString = sessionStorage.getItem("country");
    const userCountry = JSON.parse(userString);

    return userCountry;
  }
  getCountry();
  const [sales, metaData, dataLoading, error] = useGetSalesValue(
    getCountry().id,
    selectedRegion,
    selectedDistrict,
    pagination,
    sortPeriod,
    selectedYear
  );
  const [regions] = useGetAllRegions(selectedCountry);
  const [districts] = useGetAllDistricts(selectedRegion);
  const [countries] = useGetAllCountries();

  return (
    <Template>
      {!selected && (
        <div className="w-full mt-10  p-4 text-md">
          <h1 className="text-center text-4xl mb-8 text-green-700">
            Sales Value
          </h1>
          {dataLoading ? (
            <Loading text={"Sales Value"} />
          ) : (
            <Table>
              <Table.Header>
                {countries && countries.length !== 0 ? (
                  <Table.Dropdown>
                    <select
                      onChange={(e) => setSelectedCountry(e.target.value)}
                      value={selectedCountry}
                      className="appearance-none h-full  block  w-full bg-white  text-gray-700 py-2 px-4 pr-8 leading-tight "
                    >
                      {countries.map((country) => (
                        <option value={country.id} key={country.id}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </Table.Dropdown>
                ) : null}
                {regions && regions.length !== 0 ? (
                  <Table.Dropdown>
                    <select
                      onChange={(e) => setSelectedRegion(e.target.value)}
                      value={selectedRegion}
                      className="appearance-none h-full  block  w-full bg-white  text-gray-700 py-2 px-4 pr-8 leading-tight "
                    >
                      <option value="">Select Region</option>
                      {regions.map((region) => (
                        <option value={region.id} key={region.id}>
                          {region.name}
                        </option>
                      ))}
                    </select>
                  </Table.Dropdown>
                ) : null}
                {selectedRegion && districts && districts.length !== 0 ? (
                  <Table.Dropdown>
                    <select
                      onChange={(e) => setSelectedDistrict(e.target.value)}
                      value={selectedDistrict}
                      className="appearance-none h-full  block  w-full bg-white  text-gray-700 py-2 px-4 pr-8 leading-tight "
                    >
                      <option value="">Select District</option>
                      {districts.map((district) => (
                        <option value={district.id} key={district.id}>
                          {district.name}
                        </option>
                      ))}
                    </select>
                  </Table.Dropdown>
                ) : null}
                <Table.Dropdown>
                  <select
                    onChange={(e) => setSortPeriod(e.target.value)}
                    value={sortPeriod}
                    className="appearance-none h-full  block  w-full bg-white  text-gray-700 py-2 px-4 pr-8 leading-tight "
                  >
                    <option value="false">Year</option>
                    <option value="true">Month</option>
                  </select>
                </Table.Dropdown>
                {yearRange().length > 0 ? (
                  <Table.Dropdown>
                    <select
                      onChange={(e) => setSelectedYear(e.target.value)}
                      value={selectedYear}
                      className="appearance-none h-full  block  w-full bg-white  text-gray-700 py-2 px-4 pr-8 leading-tight "
                    >
                      {yearRange().map((year) => (
                        <option value={year}>{year}</option>
                      ))}
                    </select>
                  </Table.Dropdown>
                ) : null}
                {sortPeriod == "true" ? (
                  <Table.Dropdown>
                    <select
                      onChange={(e) => setSelectedMonth(e.target.value)}
                      value={selectedMonth}
                      className="appearance-none h-full  block  w-full bg-white  text-gray-700 py-2 px-4 pr-8 leading-tight "
                    >
                      <option value={`January-${selectedYear}`}>January</option>
                      <option value={`February-${selectedYear}`}>
                        February
                      </option>
                      <option value={`March-${selectedYear}`}>March</option>
                      <option value={`April-${selectedYear}`}>April</option>
                      <option value={`May-${selectedYear}`}>May</option>
                      <option value={`June-${selectedYear}`}>June</option>
                      <option value={`July-${selectedYear}`}>July</option>
                      <option value={`August-${selectedYear}`}>August</option>
                      <option value={`September-${selectedYear}`}>
                        September
                      </option>
                      <option value={`October-${selectedYear}`}>October</option>
                      <option value={`November-${selectedYear}`}>
                        November
                      </option>
                      <option value={`December-${selectedYear}`}>
                        December
                      </option>
                    </select>
                  </Table.Dropdown>
                ) : null}
              </Table.Header>
              <div className="align-middle inline-block min-w-full shadow overflow-hidden bg-white shadow-dashboard px-8 pt-3 rounded-bl-lg rounded-br-lg">
                {sales && sales.length > 0 ? (
                  <div className="table-height overflow-y-scroll">
                    <table className="min-w-full">
                      <thead>
                        <tr>
                          <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-green-600 tracking-wider">
                            ID
                          </th>
                          <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                            Product Name
                          </th>

                          <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                            Sales Value(¢)
                          </th>
                          {/* <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                      Crop Seed Volume (MT)
                    </th>
                    <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                      CPP Volume (L)
                    </th> */}
                          {/* <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                      Sales Volume
                    </th> */}
                          {/* <th className="px-6 py-3 border-b-2 border-gray-300"></th> */}
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {sales &&
                          sales.map((sale, index) => {
                            return (
                              <tr>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                                  <div className="flex items-center">
                                    <div>
                                      <div className="text-sm leading-5 text-gray-800">
                                        {(pagination - 1) * limit + index + 1}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                                  <div className="text-sm leading-5 text-green-900">
                                    {sale.product_name}
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5">
                                  ¢ {sale.total_sales_value}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="text-center text-green-900 text-sm leading-5">
                    No sale records available
                  </p>
                )}
                <Pagination metaData={metaData} setPagination={setPagination} />
              </div>
            </Table>
          )}
        </div>
      )}
      {selected && (
        <div>
          <button
            className="flex rounded bg-green-800 px-4 absolute mt-12 ml-16  py-2 text-white justify-end mr-2"
            onClick={() => {
              setSelected(null);
            }}
          >
            <svg
              className="w-6 h-6 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M7 16l-4-4m0 0l4-4m-4 4h18"
              />
            </svg>
            Back
          </button>
          {/* <SaleByID sale={selected} /> */}
        </div>
      )}
    </Template>
  );
}
