import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Template from "../../components/template";
import axios from "axios";
import { africanCountires, languages } from "../../utilities/countiresData";
import { Formik, FieldArray } from "formik";

export default function AddCountry() {
  const [loaded, setLoaded] = useState({});
  const [error, setError] = useState(null);
  const [user, setUser] = useState({
    country: "",
    language: "",
    currency: "",
    currency_symbol: "",
  });
  const [showModal, setShowModal] = React.useState(false);
  const history = useNavigate();

  useEffect(() => {
    getUser();
  }, []);

  function getUser() {
    const userString = sessionStorage.getItem("user");
    const userCred = JSON.parse(userString);
    setLoaded(userCred);
    return userCred;
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  axios.defaults.headers.common["Authorization"] = `Bearer ${loaded.token}`;

  const onSubmit = async (values) => {
    let country = JSON.parse(values.country);
    let language = JSON.parse(values.language);
    try {
      let response = await axios.post(
        "admin/countries",
        {
          name: country.name,
          iso_letter_code: country.iso_code,
          language: language.name,
          short_language: language.code,
          currency: values.currency,
          currency_symbol: values.currency_symbol,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      onSubmitSupportedDocs(response.data.data.id, values.documents);
    } catch (error) {
      if (error?.response?.status >= 500) {
        setError("Well, this unexpected. We are sorry");
      } else {
        setError(error?.response?.data?.message);
      }
    }
  };
  const onSubmitSupportedDocs = async (id, values) => {
    try {
      let response = await axios.post(
        "admin/supported/documents",
        { country_id: id, documents: values },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      setShowModal(true);
    } catch (error) {
      if (error?.response?.status >= 500) {
        setError("Well, this unexpected. We are sorry");
      } else {
        setError(error?.response?.data?.message);
      }
    }
  };

  return (
    <Template>
      <div className="w-full mt-10  p-4 text-md">
        <h1 className="text-center text-4xl mb-8 text-green-700">
          Add Country
        </h1>
        <div className="mt-5 md:mt-0 md:col-span-2">
          {showModal ? (
            <>
              <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                // onClick={() => setShowModal(false)}
              >
                <div className="relative w-auto my-6  mx-auto max-w-3xl">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5  rounded-t">
                      <h3 className="text-3xl font-semibold px-6 pt-10">
                        Successfully Added Country
                      </h3>
                    </div>
                    {/*body*/}
                    <div className="w-full flex justify-center">
                      <svg
                        className="w-64 h-64 text-green-600 flex text-center"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      {/* <h1 className="text-green-600 text-2xl text-center">
                        Approved
                      </h1> */}
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6  rounded-b">
                      <button
                        className="bg-green-600 w-full text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        style={{ transition: "all .15s ease" }}
                        onClick={() =>
                          history.push("/settings/country_configuration")
                        }
                      >
                        Okay
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}

          <div className="shadow overflow-hidden sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              {error && <p className="text-red-500  mt-2">{error}</p>}
              <Formik
                enableReinitialize="true"
                initialValues={{
                  country: "",
                  language: "",
                  currency: "",
                  currency_symbol: "",
                  documents: [
                    {
                      type: "",
                      is_required: false,
                    },
                  ],
                }}
                onSubmit={(values) => {
                  onSubmit(values);
                }}
              >
                {(props) => (
                  <>
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 ">
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Coutry name
                        </label>

                        <select
                          id="country"
                          name="country"
                          value={props.values.country}
                          onChange={props.handleChange("country")}
                          className="mt-1 block w-full px-2 py-2  border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                          <option value="" selected disabled hidden>
                            Select Country
                          </option>
                          {africanCountires.map((country) => (
                            <option value={JSON.stringify(country)}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-span-6 ">
                        <label
                          htmlFor="language"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Language
                        </label>

                        <select
                          id="language"
                          name="language"
                          value={props.values.language}
                          onChange={props.handleChange("language")}
                          className="mt-1 block w-full px-2 py-2  border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                          <option value="" selected disabled hidden>
                            Select Language
                          </option>
                          {languages.map((language) => (
                            <option value={JSON.stringify(language)}>
                              {language.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-span-6 ">
                        <label
                          htmlFor="currency"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Currency
                        </label>
                        <input
                          type="text"
                          name="currency"
                          id="currency"
                          value={props.values.currency}
                          onChange={props.handleChange("currency")}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full  sm:text-sm border px-2 py-2 border-green-600 rounded"
                        />
                      </div>
                      <div className="col-span-6">
                        <label
                          htmlFor="currency_symbol"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Currency Symbol
                        </label>
                        <input
                          type="text"
                          name="currency_symbol"
                          id="currency_symbol"
                          value={props.values.currency_symbol}
                          onChange={props.handleChange("currency_symbol")}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full  sm:text-sm border px-2 py-2 border-green-600 rounded"
                        />
                      </div>

                      <FieldArray name="documents">
                        {({ insert, remove, push }) => (
                          <>
                            <div className="col-span-6 -mb-2 pt-2 mt-4 ">
                              <p className="text-lg font-bold ">
                                Supported documents
                              </p>
                            </div>
                            {props.values.documents.length > 0 &&
                              props.values.documents.map((document, index) => (
                                <div className="col-span-6" key={index}>
                                  <div className="col-span-6 mb-2  items-center">
                                    <label
                                      htmlFor="document"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Document Name
                                    </label>
                                    <div className="flex items-center">
                                      <input
                                        type="text"
                                        name="document"
                                        id="document"
                                        value={
                                          props.values.documents[index].type
                                        }
                                        onChange={props.handleChange(
                                          `documents.${index}.type`
                                        )}
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full  sm:text-sm border px-2 py-2 border-green-600 rounded"
                                      />
                                      <div
                                        className=" ml-2 text-red-800  cursor-pointer"
                                        onClick={() => remove(index)}
                                      >
                                        <svg
                                          className="w-6 h-6"
                                          fill="none"
                                          stroke="currentColor"
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            stroke-width="1.5"
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                          ></path>
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-span-6 flex ">
                                    <label
                                      htmlFor="document"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Is required?
                                    </label>
                                    <input
                                      type="checkbox"
                                      name="is_required"
                                      id="is_required"
                                      value={
                                        props.values.documents[index]
                                          .is_required
                                      }
                                      onChange={props.handleChange(
                                        `documents.${index}.is_required`
                                      )}
                                      className="mt-1 ml-2 focus:ring-indigo-500 focus:border-indigo-500 block  sm:text-sm border px-2 py-2 border-green-600 rounded"
                                    />
                                  </div>
                                </div>
                              ))}
                            <div className="col-span-6  flex justify-end mb-4  ">
                              <div
                                className="flex rounded bg-green-800 px-4  cursor-pointer py-2 text-white justify-end"
                                onClick={() =>
                                  push({ type: "", is_required: false })
                                }
                              >
                                <svg
                                  className="w-6 h-6 mr-2"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                                Add Document Field
                              </div>
                            </div>
                          </>
                        )}
                      </FieldArray>
                    </div>

                    <div className="px-4 py-3 bg-gray-50 flex justify-between text-right sm:px-6">
                      <Link
                        to="/settings/country_configuration"
                        className="inline-flex justify-center rounded bg-red-800 px-4   py-2 text-white  mr-2"
                      >
                        Cancel
                      </Link>
                      <button
                        onClick={props.handleSubmit}
                        className="inline-flex justify-center rounded bg-green-800 px-4   py-2 text-white  mr-2"
                      >
                        Save
                      </button>
                    </div>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
}
