import React, { useState, useContext } from "react";
import { useNavigate } from "react-router";
import { AuthContext } from "../authHandler";
import image from "../../assets/AFAP-Logo-white 1.png";
import { Link } from "react-router-dom";

export default function Login() {
  const { login, error } = useContext(AuthContext);
  const [email, setEMail] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  return (
    <div className="min-h-screen bg-green-600 flex flex-col justify-between w-screen">
      <div></div>
      <div className="flex items-center ">
        <div className="container mx-auto">
          <div className="flex justify-center items-center ">
            <div className=" p-4 rounded-lg bg-white">
              <img alt="" className=" text-center" src={image} />
            </div>
          </div>
          <div className="max-w-md mx-auto rounded-lg border shadow bg-white my-10">
            <div className="text-center">
              <h1 className="my-3 text-3xl font-semibold text-gray-700 ">
                Sign in
              </h1>
              <p className="text-gray-500 ">Sign in to access your account</p>
            </div>
            <div className="m-7">
              <form onSubmit={handleSubmit} autoComplete="off">
                {error && <p className="text-red-500 mb-6">{error}</p>}
                <div className="mb-6">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm text-gray-600 "
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="you@company.com"
                    onChange={(e) => setEMail(e.target.value)}
                    className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-100 focus:border-green-300 "
                  />
                </div>
                <div className="mb-6">
                  <div className="flex justify-between mb-2">
                    <label
                      htmlFor="password"
                      className="text-sm text-gray-600 "
                    >
                      Password
                    </label>
                    <Link
                      to="/forgotten_password"
                      className="text-sm text-gray-400 focus:outline-none focus:text-green-500 hover:text-green-500 "
                    >
                      Forgot password?
                    </Link>
                  </div>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Your Password"
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-100 focus:border-green-300 "
                  />
                </div>
                <div className="mb-6">
                  <button
                    type="submit"
                    className="w-full px-3 py-4 text-white bg-green-500 rounded-md focus:bg-green-800 focus:outline-none"
                  >
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <footer className="flex w-full items-center justify-around py-4 text-white capitalize font-semibold ">
        <a className="hover:underline" href="/user/helpandsupport">
          Help and Support
        </a>
        <a className="hover:underline" href="/privacy/policy">
          Privacy Policy
        </a>
        <a className="hover:underline" href="/terms_and_conditions">
          Terms and Conditions
        </a>
      </footer>
    </div>
  );
}

// Login.propTypes = {
//   setToken: PropTypes.func.isRequired,
// };
