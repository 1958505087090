import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Template from "../components/template";

export default function ProductsByID() {
  const navigate = useNavigate();
  const location = useLocation();
  const product = location.state;

  return (
    <Template>
      <div className="w-full p-4 mt-10 text-md">
        <h1 className="mt-8 mb-8 text-4xl text-center text-green-700">
          {product?.name}
        </h1>
        <div className="flex justify-start w-full px-12 mb-8">
          <Link
            className="flex px-4 py-2 text-white bg-green-800 rounded "
            to="/products/"
          >
            <svg
              className="w-6 h-6 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M7 16l-4-4m0 0l4-4m-4 4h18"
              />
            </svg>
            Back
          </Link>
        </div>
        <div className="px-10 pt-10 ">
          <div className="overflow-hidden bg-white shadow sm:rounded-lg">
            <div className="border-t border-gray-200">
              <dl>
                <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Product Name
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {product?.name ? product?.name : "not available"}
                  </dd>
                </div>

                <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Manufacturer
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {product?.made_by.name
                      ? product?.made_by.name
                      : "not available"}
                  </dd>
                </div>
                <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Category
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {product?.supported_products.category.name
                      ? product?.supported_products.category.name
                      : "not available"}
                  </dd>
                </div>
                <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Sub Category
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {product?.supported_products.label
                      ? product?.supported_products.label
                      : "not available"}
                  </dd>
                </div>
                <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Variation
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {product?.variations
                      ? product?.variations
                      : "not available"}
                  </dd>
                </div>
                <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Description
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {product?.description
                      ? product?.description
                      : "not available"}
                  </dd>
                </div>
                <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Weight</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {product?.packaging.weight &&
                    product?.packaging.unit.short_unit
                      ? `${product?.packaging.weight} ${product?.packaging.unit.short_unit}`
                      : "not available"}
                  </dd>
                </div>
                <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Unit</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {product?.packaging.unit.unit
                      ? product?.packaging.unit.unit
                      : "not available"}
                  </dd>
                </div>
                <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Product Code
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {product?.code ? product?.code : "not available"}
                  </dd>
                </div>

                {/* <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"></dd>
              </div> */}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
}
