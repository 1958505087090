import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../auth/authHandler";
import Template from "../components/template";
import moment from "moment";
import axios from "axios";
import Pagination from "../components/pagination";
import Table from "../components/table";
import Loading from "../components/Loading";
import { useGetUsers } from "./userManagementService";

export default function UserManagement() {
  const [pagination, setPagination] = useState(1);
  // const [metaData, setMetaData] = useState(null);
  // const [users, setUsers] = useState(null);
  // const [dataLoading, setDataLoading] = useState(false);
  const { user, setUser, country, setCountry } = useContext(AuthContext);

  function getUser() {
    const userString = sessionStorage.getItem("user");
    const userCred = JSON.parse(userString);
    return userCred;
  }

  const [users, metaData, dataLoading, error] = useGetUsers(
    user,
    getUser(),
    pagination
  );

  //

  return (
    <Template>
      <div className="w-full mt-10  p-4 text-md">
        <h1 className="text-center text-4xl  text-green-700">
          User Management
        </h1>
        <div className="w-full flex justify-end mb-8 px-12">
          <Link
            to="/user_management/send_invite"
            className="flex rounded bg-green-800 px-4  py-2 text-white justify-end mr-2"
          >
            <svg
              className="w-6 h-6 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            Invite User
          </Link>
        </div>
        {dataLoading ? (
          <Loading text={"Users"} />
        ) : (
          <Table>
            <div className="align-middle inline-block min-w-full shadow overflow-hidden bg-white shadow-dashboard px-8 pt-3 rounded-bl-lg rounded-br-lg">
              <div className="table-height overflow-y-scroll">
                <table className="min-w-full">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-green-600 tracking-wider">
                        ID
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                        Name
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                        Role
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                        Email
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                        Phone Number
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                        Added On
                      </th>
                      {/* <th className="px-6 py-3 border-b-2 border-gray-300"></th> */}
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {users &&
                      users.map((user, index) => {
                        return (
                          <tr>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                              <div className="flex items-center">
                                <div>
                                  <div className="text-sm leading-5 text-gray-800">
                                    {(pagination - 1) * 15 + index + 1}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                              <div className="text-sm leading-5 text-green-900">
                                {user.firstname} {user.lastname}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5">
                              {user.role.role}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5">
                              {user.email}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5">
                              {user.phone_number}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500 text-green-900 text-sm leading-5">
                              {moment(user.email_verified_at).format(
                                "MM/DD/YYYY"
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <Pagination metaData={metaData} setPagination={setPagination} />
            </div>
          </Table>
        )}
      </div>
    </Template>
  );
}
