import axios from "axios";
import { useEffect, useState } from "react";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import image from "../../assets/AFAP-Logo.png";
import moment from "moment";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

function AddClientUser({ match }) {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    otherNames: "",
    phoneNumber: "",
    address: "",
    gender: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [date, setDate] = useState(new Date());

  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [companyID, setCompanyID] = useState("");
  const [branchID, setBranchID] = useState("");
  const [roleID, setRoleID] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    getURLParams();
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  function getURLParams() {
    const { email, company_id, branch_id, token, role_id } = match.params;
    setEmail(email);
    setToken(token);
    setCompanyID(company_id);
    setBranchID(branch_id);
    setRoleID(role_id);
  }
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const onSubmit = (event) => {
    event.preventDefault();
    const formattedDate = moment(date[0]).format("YYYY-MM-DD");
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios
      .post(
        "users/invitation/process",
        {
          firstname: user.firstName,
          lastname: user.lastName,
          date_of_birth: formattedDate,
          gender: user.gender,
          phone_number: user.phoneNumber,
          address: user.address,
          email: email,
          password: user.password,
          password_confirmation: user.confirmPassword,
          company_id: companyID,
          branch_id: branchID,
          token: token,
          role_id: roleID,
        },
        {
          headers: {
            "Content-Type": "application/json",
            // Accept: "application/json",
          },
        }
      )
      .then((response) => {
        setSuccess(true);
      })
      .catch((error) => {
        if (error.response.status >= 500) {
          setError("Well, this unexpected. We are sorry");
        } else {
          setError(error.response.data.message);
        }
      });
  };

  if (!success) {
    return (
      <div className="flex items-center w-screen min-h-screen ">
        <div className="hidden w-1/2 h-screen p-4 bg-green-800 md:block">
          <div>
            <img alt="" className="h-10 ml-4 text-center " src={image} />
          </div>
        </div>
        <div className="mx-auto w-ful md:w-1/2">
          <div className="block py-2 bg-green-800 md:hidden">
            <img alt="" className="h-10 ml-4 text-center " src={image} />
          </div>
          <div className="px-8 mx-auto my-10">
            <div className="text-center">
              <h1 className="my-3 text-3xl font-semibold text-gray-700 ">
                Company User Invitation
              </h1>
              <p className="text-gray-500 ">Sign up to access your account</p>
              {error && <p className="mt-2 text-red-500 ">{error}</p>}
            </div>
            <div className="m-1 md:m-7">
              <form className="grid w-full gap-6 md:grid-cols-6">
                <div className="col-span-6 mb-1">
                  <label
                    htmlFor="email"
                    className="block mb-1 text-sm text-gray-600 "
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    className="block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  />
                </div>
                <div className="col-span-6 mb-1">
                  <label
                    htmlFor="email"
                    className="block mb-1 text-sm text-gray-600 "
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    value={user.password}
                    className="block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-span-6 mb-2 ">
                  <div className="flex justify-between mb-1">
                    <label
                      htmlFor="password"
                      className="text-sm text-gray-600 "
                    >
                      Confirm Password
                    </label>
                  </div>
                  <input
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    value={user.confirmPassword}
                    className="block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-span-6 mb-2 md:col-span-3">
                  <div className="flex justify-between mb-1">
                    <label
                      htmlFor="firstName"
                      className="text-sm text-gray-600 "
                    >
                      First Name
                    </label>
                  </div>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    value={user.firstName}
                    className="block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-span-6 mb-2 md:col-span-3">
                  <div className="flex justify-between mb-1">
                    <label
                      htmlFor="lastName"
                      className="text-sm text-gray-600 "
                    >
                      Last Name
                    </label>
                  </div>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={user.lastName}
                    className="block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-span-6 mb-2 md:col-span-3">
                  <div className="flex justify-between mb-1">
                    <label
                      htmlFor="otherNames"
                      className="text-sm text-gray-600 "
                    >
                      Other Names
                    </label>
                  </div>
                  <input
                    type="text"
                    name="otherNames"
                    id="otherNames"
                    value={user.otherNames}
                    className="block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-span-6 mb-2 md:col-span-3">
                  <div className="flex justify-between mb-1">
                    <label htmlFor="date" className="text-sm text-gray-600  ">
                      Date Of Birth
                    </label>
                  </div>

                  <Flatpickr
                    value={date}
                    onChange={(date) => setDate(date)}
                    className="block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  />
                </div>
                <div className="col-span-6 mb-2 md:col-span-3">
                  <div className="flex justify-between mb-1">
                    <label htmlFor="gender" className="text-sm text-gray-600 ">
                      Gender
                    </label>
                  </div>
                  <div className="flex justify-between w-2/4">
                    <div className="mb-2">
                      <div className="flex justify-between mb-1">
                        <label
                          htmlFor="password"
                          className="mr-2 text-sm text-gray-600 "
                        >
                          Male
                        </label>
                        <input
                          type="radio"
                          value="male"
                          id="gender"
                          onChange={handleChange}
                          name="gender"
                        />
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="flex justify-between mb-1">
                        <label
                          htmlFor="password"
                          className="mr-2 text-sm text-gray-600 "
                        >
                          Female
                        </label>
                        <input
                          type="radio"
                          value="female"
                          id="gender"
                          onChange={handleChange}
                          name="gender"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-6 mb-2 md:col-span-3">
                  <div className="flex justify-between mb-1">
                    <label
                      htmlFor="phoneNumber"
                      className="text-sm text-gray-600 "
                    >
                      Phone Number
                    </label>
                  </div>
                  <input
                    type="text"
                    name="phoneNumber"
                    id="phoneNumber"
                    value={user.phoneNumber}
                    className="block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-span-6 mb-2">
                  <div className="flex justify-between mb-1">
                    <label htmlFor="address" className="text-sm text-gray-600 ">
                      Address
                    </label>
                  </div>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    value={user.address}
                    className="block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                    onChange={handleChange}
                  />
                </div>
              </form>
              <div className="mt-8">
                <button
                  onClick={onSubmit}
                  type="button"
                  className="items-center justify-center w-full h-12 p-4 mb-2 bg-yellow-300 rounded"
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex items-center min-h-screen ">
        <div className="container mx-auto">
          <div className="max-w-md mx-auto my-10">
            <div className="text-center">
              <h1 className="my-3 text-3xl font-semibold text-gray-700 ">
                Account Created
              </h1>
              <p className="text-gray-500 ">
                Hello {user.firstName}, your account has successfully been
                created.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddClientUser;
