import React, { useEffect, useState } from "react";
import { getApi } from "../../hooks/getApi";

export function useGetStockAvailability(
  supportedCategoryId,
  selectedCountry,
  productId,
  pagination,
  sortPeriod
) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState(null);
  const [productsMetaData, setProductsMetaData] = useState(null);

  useEffect(() => {
    const getAllProducts = async () => {
      const data = await getApi(
        `admin/reports/products/total/count/per-product?supported_products_by_categories_id=${supportedCategoryId}&country_id=${selectedCountry}&page=${pagination}&product_id=${productId}&group_by_countries=true`,
        setDataLoading
      );

      if (data) {
        setProducts(data.data);
        setProductsMetaData(data.meta);
      }
    };
    getAllProducts();
  }, [supportedCategoryId, selectedCountry, productId, pagination]);

  return [products, productsMetaData, dataLoading, error];
}

export function useGetStockAvailabilityBranches(
  branchType,
  selectedCountry,
  pagination
) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [branches, setBranches] = useState(null);
  const [branchesMetaData, setBranchesMetaData] = useState(null);

  useEffect(() => {
    const getAllProducts = async () => {
      const data = await getApi(
        `admin/reports/product-availability?branch_type_id=${branchType}&country_id=${selectedCountry}&page=${pagination}`,
        setDataLoading
      );

      if (data) {
        setBranches(data.data);
        setBranchesMetaData(data.meta);
      }
    };
    getAllProducts();
  }, [branchType, selectedCountry, pagination]);

  return [branches, branchesMetaData, dataLoading, error];
}
export const getCategoriesDataApi = async (
  productCategory,
  selectedCountry,
  setDataLoading
) => {
  const data = await getApi(
    `admin/reports/products/categories/${productCategory}/total/counts?country_id=${selectedCountry}`,
    setDataLoading
  );

  if (data) {
    return data.data;
  }
};
