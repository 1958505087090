import React, { useEffect, useState } from "react";
import { getApi } from "../../hooks/getApi";

export function useGetSalesValue(
  countryId,
  selectedRegion,
  selectedDistrict,
  pagination,
  sortPeriod,
  selectedYear
) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sales, setSales] = useState(null);
  const [metaData, setMetaData] = useState(null);

  useEffect(() => {
    const getSalesValue = async () => {
      const data = await getApi(
        `admin/reports/products/sales/value?group_by_year=${selectedYear}&page=${pagination}&group_by_month=${sortPeriod}&group_by_countries=true&country_id=${countryId}&region_id=${
          selectedRegion ? selectedRegion : ""
        }&district_id=${selectedDistrict ? selectedDistrict : ""}`,
        setDataLoading
      );

      if (data) {
        setSales(data.data);
        setMetaData(data.meta);
      }
    };
    getSalesValue();
  }, [sortPeriod, selectedRegion, selectedDistrict, pagination, selectedYear]);

  return [sales, metaData, dataLoading, error];
}

export function useGetSalesVolume(
  countryId,
  selectedRegion,
  selectedDistrict,
  selectedYear,
  sortPeriod,
  productId
) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sales, setSales] = useState(null);

  useEffect(() => {
    const getSalesVolume = async () => {
      const data = await getApi(
        `admin/reports/products/sales/volume?group_by_year=${selectedYear}&country_id=${countryId}&region_id=${
          selectedRegion ? selectedRegion : ""
        }&district_id=${selectedDistrict ? selectedDistrict : ""}`,
        setDataLoading
      );

      if (data) {
        setSales(data.data);
      }
    };

    getSalesVolume();
  }, [sortPeriod, selectedRegion, selectedDistrict, selectedYear]);

  return [sales, dataLoading, error];
}

export function useGetSalesVolumePerProduct(
  countryId,
  selectedYear,
  sortPeriod,
  productId
) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sales, setSales] = useState(null);

  useEffect(() => {
    const getSalesVolume = async () => {
      const data = await getApi(
        `admin/reports/products/sales/volume?group_by_year=${selectedYear}&group_by_month=true&country_id=${countryId}&product_id=${productId}`,
        setDataLoading
      );

      if (data) {
        setSales(data.data);
      }
    };
    getSalesVolume();
  }, [sortPeriod, selectedYear]);

  return [sales, dataLoading, error];
}
