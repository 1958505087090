import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Template from "../components/template";
import axios from "axios";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import {
  useGetCompanies,
  useGetCompanyTypes,
} from "../CompanyManagement/CompanyService";

export default function CreateAnnouncement() {
  const [loaded, setLoaded] = useState({});
  const [error, setError] = useState(null);
  const [selectInput, setSelectInput] = useState("");
  const [user, setUser] = useState({
    title: "",
    message: "",
    url: "",
  });
  const [branchValues, setBranchValues] = useState(null);
  const [company, setCompany] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [companyValues, setCompanyValues] = useState(null);
  const [companyType, setCompanyType] = useState(null);
  const [branchType, setBranchType] = useState([]);
  const [branch, setBranch] = useState([]);
  const [sendToAll, setSendToAll] = useState(false);
  const [showModal, setShowModal] = React.useState(false);

  const selectStyle = {
    input: (styles) => ({
      ...styles,
      borderRadius: 3,
      borderColor: "#047857",
      outlineStyle: "none",
      outlineColor: "transparent",
    }),
    option: (styles, { isSelected, isFocused }) => {
      const color = "rgba(4, 120, 87,0.3)";
      return {
        ...styles,
        height: 40,
        backgroundColor: isSelected ? color : isFocused ? color : undefined,
        borderColor: "#047857",
        borderRadius: 3,
      };
    },
    menuList: (styles) => ({
      ...styles,
      height: 200,
    }),
    control: (styles, { isSelected, isFocused }) => ({
      ...styles,
      outlineStyle: "none",
      outlineColor: "transparent",
      borderColor: isSelected ? "#047857" : isFocused ? "#047857" : "#047857",
      borderWidth: isSelected ? 2 : isFocused ? 2 : 1,
      ":active": {
        ...styles[":active"],
        borderColor: "#047857",
      },
      ":hover": {
        ...styles[":hover"],
        borderColor: "#047857",
      },
      ":focus": {
        ...styles[":focus"],
        borderColor: "#047857",
      },
    }),

    multiValueLabel: (styles) => ({
      ...styles,

      backgroundColor: "#047857",
      color: "white",
    }),
    multiValueRemove: (styles) => ({
      ...styles,

      backgroundColor: "#047857",
      color: "white",
    }),
  };

  function getUser() {
    const userString = sessionStorage.getItem("user");
    const userCred = JSON.parse(userString);
    return userCred;
  }

  function getCountry() {
    const userString = sessionStorage.getItem("country");
    const userCountry = JSON.parse(userString);

    return userCountry;
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const [companyTypeList] = useGetCompanyTypes();

  const getCompanyType = async () => {
    let newArray = [];
    companyTypeList.map((data) => {
      let obj = {
        value: data.id,
        label: data.type,
      };

      newArray.push(obj);
    });

    return newArray;
  };

  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    setSelectInput(inputValue);
    return inputValue;
  };

  useEffect(() => {
    getCompany();
  }, [companyType]);

  const getCompany = async () => {
    if (loaded) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${
        getUser().token
      }`;
      const data = await axios
        .get(
          `admin/companies/${getCountry().id}?type=${
            companyType ? companyType.value : ""
          }&limit=false`
        )
        .then((response) => {
          let newArray = [];

          response.data.data.map((data) => {
            let obj = {
              value: data.id,
              label: data.name,
              branches: data?.branches,
            };

            newArray.push(obj);
          });
          if (selectInput.length === 0) {
            return newArray;
          } else {
            return newArray.filter((i) =>
              i.label.toLowerCase().includes(selectInput.toLowerCase())
            );
          }
        })
        .catch((err) => {
          console.log("error", err?.response?.data?.message);
        });
      if (data) {
        setCompanyList([{ label: "Select All", value: "all" }, ...data]);
        return [{ label: "Select All", value: "all" }, ...data];
      }
      // return data;
    }
  };

  const getBranches = () => {
    let branches = [];
    if (companyValues && companyValues.length !== 0) {
      companyValues.map((company) => {
        if (company.branches && company.branches.length !== 0) {
          company.branches.map((branch) => {
            if (branch) {
              let obj = {
                value: branch.id,
                label: branch.name,
              };
              branches.push(obj);
            }
          });
        }
      });
    }

    if (branches.length !== 0) {
      return [{ label: "Select All", value: "all" }, ...branches];
    } else {
      return branches;
    }
  };

  axios.defaults.headers.common["Authorization"] = `Bearer ${getUser().token}`;
  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      let response = await axios.post(
        "admin/broadcasts/messages",
        {
          title: user.title,
          body: user.message,
          url: user.url,
          publish_to_all_users: sendToAll,
          country_id: getCountry().id,
          // branch_type_ids: branchType,
          // company_type_ids: companyType,
          company_ids: company,
          branch_ids: branch,
          users: [1],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      setShowModal(true);
    } catch (error) {
      if (error.response.status >= 500) {
        setError("Well, this unexpected. We are sorry");
      } else {
        setError(error.response.data.message);
      }
    }
  };

  return (
    <Template>
      <div className="w-full h-5/6  mt-10  p-4 text-md">
        <h1 className="text-center text-4xl mb-8 text-green-700">
          Announcement
        </h1>
        <div className="mt-5 md:mt-0 md:col-span-2  h-full">
          {showModal ? (
            <>
              <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                // onClick={() => setShowModal(false)}
              >
                <div className="relative w-auto my-6  mx-auto max-w-3xl">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5  rounded-t">
                      <h3 className="text-3xl font-semibold px-6 pt-10">
                        Announcment Successfully Sent
                      </h3>
                      {/* <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowModal(false)}
                      >
                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button> */}
                    </div>
                    {/*body*/}
                    <div className="w-full flex justify-center">
                      <svg
                        className="w-64 h-64 text-green-600 flex text-center"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      {/* <h1 className="text-green-600 text-2xl text-center">
                        Approved
                      </h1> */}
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6  rounded-b">
                      <button
                        className="bg-green-600 w-full text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        style={{ transition: "all .15s ease" }}
                        onClick={() => setShowModal(false)}
                      >
                        Okay
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
          <form onSubmit={onSubmit} className="h-full">
            <div className="shadow h-full  overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 h-full bg-white sm:p-6">
                {error && <p className="text-red-500  mt-2">{error}</p>}
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 ">
                    <label
                      htmlFor="title"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      name="title"
                      id="title"
                      value={user.title}
                      onChange={handleChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full  sm:text-sm border px-2 py-2  border-green-600 rounded"
                    />
                  </div>

                  <div className="col-span-6 ">
                    <label
                      htmlFor="email_address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Message
                    </label>
                    <textarea
                      rows="6"
                      cols="50"
                      //   type="text"
                      name="message"
                      id="message"
                      value={user.message}
                      onChange={handleChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full  sm:text-sm border px-2 py-2 border-green-600 rounded"
                    />
                  </div>
                  <div className="col-span-6 ">
                    <label
                      htmlFor="url"
                      className="block text-sm font-medium text-gray-700"
                    >
                      URL Link
                    </label>
                    <input
                      type="text"
                      name="url"
                      id="url"
                      value={user.url}
                      onChange={handleChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full  sm:text-sm border px-2 py-2  border-green-600 rounded"
                    />
                  </div>

                  <div className="mb-1 col-span-3§ ">
                    <label
                      htmlFor="country"
                      className="block mb-2 text-sm text-gray-600 "
                    >
                      Send to all
                    </label>
                    <Select
                      styles={selectStyle}
                      name="country"
                      onChange={(value) => {
                        setSendToAll(value.value);
                      }}
                      cacheOptions
                      defaultOptions
                      options={[
                        { value: true, label: "Yes" },
                        { value: false, label: "No" },
                      ]}
                    />
                  </div>
                  {sendToAll === false ? (
                    <>
                      {branchType && branchType.length === 0 ? (
                        <div className="mb-1 col-span-3">
                          <label
                            htmlFor="company"
                            className="block mb-2 text-sm text-gray-600 "
                          >
                            Company Type
                          </label>
                          <AsyncSelect
                            styles={selectStyle}
                            name="company"
                            value={companyType}
                            onChange={(value) => {
                              setCompanyType(value);
                            }}
                            isClearable
                            cacheOptions
                            defaultOptions
                            loadOptions={getCompanyType}
                            onInputChange={handleInputChange}
                          />
                        </div>
                      ) : null}
                      {companyType ? (
                        <>
                          <div className="mb-1 col-span-3">
                            <label
                              htmlFor="company"
                              className="block mb-2 text-sm text-gray-600 "
                            >
                              Company
                            </label>
                            <Select
                              key={companyType.toString()}
                              styles={selectStyle}
                              name="company"
                              value={companyValues ? companyValues : null}
                              onChange={(values) => {
                                setCompanyValues(values);
                                let ids = [];
                                values.find((option) => option.value === "all")
                                  ? getCompany().then((res) => {
                                      res
                                        .slice(1)
                                        .map((value) => ids.push(value.value));
                                      setCompanyValues(res.slice(1));
                                    })
                                  : values.map((value) =>
                                      ids.push(value.value)
                                    );

                                setCompany(ids);
                              }}
                              cacheOptions={false}
                              isMulti
                              defaultOptions
                              options={companyList}
                              onInputChange={handleInputChange}
                            />
                          </div>
                          {/* <p>{JSON.stringify(company)}</p> */}
                          <div className="mb-1 col-span-3">
                            <label
                              htmlFor="branches"
                              className="block mb-2 text-sm text-gray-600 "
                            >
                              Branches
                            </label>
                            <Select
                              styles={selectStyle}
                              isMulti
                              cacheOptions
                              defaultOptions
                              options={getBranches()}
                              value={branchValues ? branchValues : null}
                              onChange={(values) => {
                                let ids = [];
                                if (
                                  values.find(
                                    (option) => option.value === "all"
                                  )
                                ) {
                                  getBranches()
                                    .slice(1)
                                    .map((value) => ids.push(value.value));

                                  setBranchValues(getBranches().slice(1));
                                } else {
                                  values.map((value) => ids.push(value.value));
                                  setBranchValues(values);
                                }

                                setBranch(ids);
                              }}
                            />
                          </div>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 flex justify-between text-right sm:px-6">
              <Link
                to="/announcements"
                className="inline-flex justify-center rounded bg-red-800 px-4   py-2 text-white  mr-2"
              >
                Cancel
              </Link>
              <button
                type="submit"
                className="inline-flex justify-center rounded bg-green-800 px-4   py-2 text-white  mr-2"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </Template>
  );
}
