import { useEffect, useState } from "react";
import { getApi } from "../hooks/getApi";

export function useGetRoles() {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const getRoles = async () => {
      const data = await getApi(`admin/roles`, setDataLoading);

      if (data) {
        setRoles(data.data);
      }
    };
    getRoles();
  }, []);

  return [roles, dataLoading, error];
}

export function useGetUsers(user, getUser, pagination) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [metaData, setMetaData] = useState(null);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    const getUsers = async () => {
      const data = await getApi(
        `admin/company/platform-company/${
          user ? user.company?.id : getUser().company?.id
        }/users?page=${pagination}`,
        setDataLoading
      );

      if (data) {
        setUsers(data.data);
        setMetaData(data.meta);
      }
    };
    getUsers();
  }, [pagination]);

  return [users, metaData, dataLoading, error];
}
