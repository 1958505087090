import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Map from "../components/Map";
import Template from "../components/template";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export default function CompanyRequestByID() {
  const [showModal, setShowModal] = useState(null);
  // const [showModal2, setShowModal2] = useState(false);
  const [feedbackAccept, setFeedbackAccept] = useState(null);
  const [feedbackDeclined, setFeedbackDeclined] = useState(null);

  const [remarks, setRemarks] = useState("");
  const location = useLocation();
  const company = location.state;

  useEffect(() => {
    function getUser() {
      const userString = sessionStorage.getItem("user");
      const userCred = JSON.parse(userString);
      return userCred;
    }
    getUser();
    axios.defaults.headers.common["Authorization"] = `Bearer ${
      getUser().token
    }`;
  }, []);

  console.log(company);

  const onSubmit = async (is_approved, feedback) => {
    // const token = await getToken();
    const data = await axios
      .put(`admin/company/approvals/${company.id}`, {
        remarks,
        is_approved,
      })
      .then((response) => {
        return response.data.data;
      })
      .catch((err) => {
        console.log(err?.response?.status);
        console.log(err?.response?.data?.message);
      });

    feedback(true);
  };

  return (
    <Template>
      <div className="w-full mt-12  p-4 text-md">
        <h1 className="text-center text-4xl mb-8 text-green-700">
          Company Approval Request
        </h1>

        <div className="w-full flex justify-start mb-8 px-12">
          <Link
            className="flex rounded bg-green-800 px-4 py-2 text-white "
            to="/company_requests/"
          >
            <svg
              className="w-6 h-6 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M7 16l-4-4m0 0l4-4m-4 4h18"
              />
            </svg>
            Back
          </Link>
        </div>
        <div className=" px-10 ">
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Company Name
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {company.company.name
                      ? company.company.name
                      : "not available"}
                  </dd>
                </div>

                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Email address
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {company.company.email
                      ? company.company.email
                      : "not available"}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Address</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {company.company.address
                      ? company.company.address
                      : "not available"}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Phone Number
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {company.company.phone_number
                      ? company.company.phone_number
                      : "not available"}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">AFAP ID</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {company.company.afap_number
                      ? company.company.afap_number
                      : "not available"}
                  </dd>
                </div>

                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Type</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {company.company.type.type
                      ? company.company.type.type
                      : "not available"}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Status</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {company.is_approved === 1 && (
                      <span className="relative inline-block px-3 py-2 font-semibold text-green-600 leading-tight ">
                        <span
                          aria-hidden
                          className="absolute inset-0 bg-green-200  opacity-50 rounded"
                        ></span>
                        <span className="relative">accepted</span>
                      </span>
                    )}
                    {company.is_approved === 0 && (
                      <span className="relative inline-block px-3 py-1 font-semibold text-red-600 leading-tight">
                        <span
                          aria-hidden
                          className="absolute inset-0 bg-red-200  opacity-50 rounded"
                        ></span>
                        <span className="relative">declined</span>
                      </span>
                    )}
                    {company.is_approved === null && (
                      <span className="relative inline-block px-3 py-1 font-semibold text-yellow-600 leading-tight ">
                        <span
                          aria-hidden
                          className="absolute inset-0 bg-yellow-200  opacity-50 rounded"
                        ></span>
                        <span className="relative">pending</span>
                      </span>
                    )}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {company.remarks ? company.remarks : "not available"}
                  </dd>
                </div>

                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Business Registration Certificate
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                      <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                        <div className="w-0 flex-1 flex items-center">
                          <svg
                            className="flex-shrink-0 h-5 w-5 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span className="ml-2 flex-1 w-0 truncate">
                            Business Certificate
                          </span>
                        </div>
                        {company.company.business_certificate ? (
                          <div className="ml-4 flex-shrink-0 cursor-pointer">
                            <a
                              href={company.company.business_certificate}
                              className="font-medium text-indigo-600 hover:text-indigo-500"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              Download
                            </a>
                          </div>
                        ) : (
                          <p>not available</p>
                        )}
                      </li>
                    </ul>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
        {/* {company.is_approved === null && ( */}
        <div className="flex justify-end pr-20 mt-8">
          <button
            type="button"
            // style={{ transition: "all .15s ease" }}
            onClick={() => setShowModal(2)}
            className="flex rounded bg-red-800 px-4  py-2 text-white justify-end mr-2"
          >
            Decline
          </button>
          <button
            type="button"
            // style={{ transition: "all .15s ease" }}
            onClick={() => setShowModal(1)}
            className="flex rounded bg-green-800 px-4  py-2 text-white justify-end mr-2"
          >
            Approve
          </button>
        </div>
        {/* )} */}
        {showModal === 1 && (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
              // onClick={() => setShowModal(false)}
            >
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                    <h3 className="text-3xl font-semibold">Approve Request</h3>
                  </div>
                  {/*body*/}
                  <div className="p-4">
                    {!feedbackAccept ? (
                      <>
                        <label
                          htmlFor="first_name"
                          className="block text-lg font-medium text-gray-700"
                        >
                          Remarks
                        </label>
                        <textarea
                          id="w3review"
                          name="w3review"
                          rows="4"
                          cols="50"
                          className="p-4 border rounded border-gray-200"
                          onChange={(e) => {
                            setRemarks(e.target.value);
                          }}
                        ></textarea>
                      </>
                    ) : (
                      <div className="px-20">
                        <svg
                          className="w-64 h-64 text-green-600 flex j"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        <h1 className="text-green-600 text-2xl text-center">
                          Approved
                        </h1>
                      </div>
                    )}
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                    {feedbackAccept && (
                      <Link to="/company_requests">
                        <button
                          className="flex rounded bg-gray-500 px-4  py-2 text-white justify-end mr-2"
                          type="button"
                          // style={{ transition: "all .15s ease" }}
                          onClick={() => setShowModal(null)}
                        >
                          Close
                        </button>
                      </Link>
                    )}

                    {!feedbackAccept && (
                      <>
                        <button
                          className="flex rounded bg-gray-500 px-4  py-2 text-white justify-end mr-2"
                          type="button"
                          // style={{ transition: "all .15s ease" }}
                          onClick={() => setShowModal(null)}
                        >
                          Close
                        </button>
                        <button
                          className="flex rounded bg-green-800 px-4  py-2 text-white justify-end mr-2"
                          type="button"
                          // style={{ transition: "all .15s ease" }}
                          onClick={() => onSubmit(1, setFeedbackAccept)}
                        >
                          Approve
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        )}
        {!showModal && null}
        {/* {!showModal2 && null} */}
        {showModal === 2 && (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
              // onClick={() => setShowModal(false)}
            >
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                    <h3 className="text-3xl font-semibold">Decline Request</h3>
                    {/* <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal2(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button> */}
                  </div>
                  {/*body*/}
                  <div className="p-4">
                    {!feedbackDeclined ? (
                      <>
                        <label
                          htmlFor="first_name"
                          className="block text-lg font-medium text-gray-700"
                        >
                          Remarks
                        </label>
                        <textarea
                          id="w3review"
                          name="w3review"
                          rows="4"
                          cols="50"
                          className="p-4 border rounded border-gray-200"
                          onChange={(e) => {
                            setRemarks(e.target.value);
                          }}
                        ></textarea>
                      </>
                    ) : (
                      <div className="px-20">
                        <svg
                          className="w-64 h-64 text-red-600 "
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>

                        <h1 className="text-red-600 text-2xl text-center">
                          Declined
                        </h1>
                      </div>
                    )}
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                    {feedbackDeclined && (
                      <Link to="/company_requests">
                        <button
                          className="flex rounded bg-gray-500 px-4  py-2 text-white justify-end mr-2"
                          type="button"
                          // style={{ transition: "all .15s ease" }}
                          onClick={() => setShowModal(null)}
                        >
                          Close
                        </button>
                      </Link>
                    )}

                    {!feedbackDeclined && (
                      <>
                        <button
                          className="flex rounded bg-gray-500 px-4  py-2 text-white justify-end mr-2"
                          type="button"
                          // style={{ transition: "all .15s ease" }}
                          onClick={() => setShowModal(null)}
                        >
                          Close
                        </button>
                        <button
                          className="flex rounded bg-red-800 px-4  py-2 text-white justify-end mr-2"
                          type="button"
                          // style={{ transition: "all .15s ease" }}
                          onClick={() => onSubmit(0, setFeedbackDeclined)}
                        >
                          Decline
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        )}
      </div>
    </Template>
  );
}
