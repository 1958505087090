import { useContext, useEffect, useState } from "react";
import Chart from "react-apexcharts";

export default function PieCharts({ data, colors, title }) {
  const values = [];
  const label = [];

  data.map((category) => {
    values.push(parseInt(category.total));
    label.push(category.name);
  });


  let chart = {
    options: {
      responsive: [
        {
          breakpoint: 1536,
          options: {
            legend: {
              position: "bottom"
            }
          }
        }
      ],
      title: {
        text: title,
        align: "center",
      },
      colors: colors,
      chart: {
        toolbar: {
          show: false,
        },
      },
      // labels: key,
      labels: label,
    },
    noData: {
      text: "Loading...",
    },

    series: values,
  };
  return (
    <div>
      <Chart
        options={chart.options}
        series={chart.series}
        type="pie"
        width="500"
      />
    </div>
  );
}
