import Chart from "react-apexcharts";

export default function LineCharts({ data, colors, title }) {
  const values = [];
  const label = [];

  data.map((category) => {
    values.push(parseInt(category.total_sales_volume));
    label.push(category.month);
  });


  let chart = {

    options: {
      responsive: [
        {
          breakpoint: 1536,
          options: {
            legend: {
              position: "bottom"
            },
            chart:{
              width:"750",
            }
          }
        }
      ],
      title: {
        text: title,
        align: "center",
      },
      stroke: {
        curve: "straight",
      },
      colors: colors,
      chart: {
        toolbar: {
          show: false,
        },
      },
      markers: {
        size: 5,
      },
      dataLabels: {
        enabled: true,
      },
      // labels: key,
      xaxis: { categories: label },
    },
    noData: {
      text: "Loading...",
    },

    series: [{ name: "sales volume", data: values }],
  };
  return (
    <div>
      <Chart
        options={chart.options}
        series={chart.series}
        type="line"
        width="1200"
        height="500"
      />
    </div>
  );
}
