import { useEffect, useState } from "react";
import axios from "axios";
import CardStats from "../components/CardStats.js";
import { numberSummary } from "../utilities/numberSummary";
import {
  useGetTotalCompanyTypes,
  useGetTotalProducts,
  useGetTotalSales,
  useGetTotalUsers,
} from "./HeaderStatsServices.js";

export default function HeaderStats({ country_id, user }) {
  const [userTotal] = useGetTotalUsers(user, country_id);
  const [saleAggregate] = useGetTotalSales(user, country_id);
  const [companyTotal] = useGetTotalCompanyTypes(user, country_id);
  const [totalProductCount] = useGetTotalProducts(user);

  const value = companyTotal
    ? companyTotal.map((company) => company.total)
    : ["loading"];
  const users = userTotal ? userTotal.total_count : 0;
  const sum =
    value && value.length
      ? value.reduce((result, number) => result + number)
      : 0;

  return (
    <>
      {/* Header */}
      <div className="relative bg-lightBlue-600 ">
        <div className="w-full px-4 mx-auto md:px-0">
          <div>
            {/* Card stats */}
            <div className="grid gap-2 lg:grid-cols-4">
              <div className="w-full px-4">
                <CardStats
                  statSubtitle="All USERS"
                  statTitle={users}
                  statArrow="up"
                  statPercent="100"
                  statPercentColor="text-green-400"
                  // statDescripiron="Since last month"
                  statIconName="fas fa-users"
                  statIconColor="bg-green-600"
                />
              </div>
              {/* <div className="w-full px-4 lg:w-6/12 xl:w-3/12">
                <CardStats
                  statSubtitle="UNIQUE PRODUCT COUNT"
                  statTitle="10"
                  statArrow="up"
                  statPercent="100"
                  statPercentColor="text-green-400"
                  // statDescripiron="Since last month"
                  statIconName="fas fa-box-open"
                  statIconColor="bg-green-600"
                />
              </div> */}
              <div className="w-full px-4">
                <CardStats
                  statSubtitle="COMPANIES"
                  statTitle={sum}
                  statArrow="up"
                  statPercent="100"
                  statPercentColor="text-green-400"
                  // statDescripiron="Since last week"
                  statIconName="fas fa-building"
                  statIconColor="bg-green-600"
                />
              </div>

              <div className="w-full px-4">
                <CardStats
                  statSubtitle="Credit SALES"
                  statTitle={`${saleAggregate[0].symbol} ${saleAggregate[0].total_credit_sales_amount}`}
                  statArrow="up"
                  statPercent="100"
                  statPercentColor="text-green-500"
                  // statDescripiron="Since yesterday"
                  statIconName="fas fa-coins"
                  statIconColor="bg-green-600"
                />
              </div>

              <div className="w-full px-4">
                <CardStats
                  statSubtitle="SALES"
                  statTitle={`${saleAggregate[0].symbol} ${numberSummary(
                    saleAggregate[0].total,
                    1
                  )}  `}
                  statArrow="up"
                  statPercent="100"
                  statPercentColor="text-green-500"
                  statDescripiron="approx."
                  statIconName="fas fa-coins"
                  statIconColor="bg-green-600"
                />
              </div>
              {/* <div className="w-full px-4 lg:w-6/12 xl:w-3/12">
                <CardStats
                  statSubtitle="PERFORMANCE"
                  statTitle="49,65%"
                  statArrow="up"
                  statPercent="12"
                  statPercentColor="text-emerald-500"
                  statDescripiron="Since last month"
                  statIconName="fas fa-percent"
                  statIconColor="bg-lightBlue-500"
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
