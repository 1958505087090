import { useContext, useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import Login from "../auth/pages/Login";
import { AuthContext } from "../auth/authHandler";
import Home from "../pages/Home";
import ResetPassword from "../auth/pages/ResetPassword";
import Orders from "../pages/Orders";
import CompanyRequest from "../CompanyRequest/CompanyRequest";
import Products from "../Products/Products";
import Sales from "../Sales/Sales";
import UserManagement from "../UserManagement/UserManagement";
import Companies from "../CompanyManagement/Companies";
import AddProduct from "../Products/AddProduct";
import AddManufacturer from "../Products/AddManufacturer";
import StockAvailability from "../Reports/Stock Availability/StockAvailability";
import SalesVolume from "../Reports/SalesVolume/SalesVolume";
import AddClientUser from "../auth/pages/AddClientUser";
import ClientMaps from "../ClientMap/ClientMaps";
import HelpAndSupport from "../pages/HelpAndSupport";
import CountryConfig from "../Settings/CountryConfiguration/CountryConfig";
import AddCountry from "../Settings/CountryConfiguration/AddCountry";
import AddRegion from "../Settings/CountryConfiguration/AddRegion";
import SalesValue from "../Reports/SalesVolume/SalesValue";
import CreateAnnouncement from "../Announcements/CreateAnnouncement";
import EditCountry from "../Settings/CountryConfiguration/EditCountry";
import CompanyByID from "../CompanyManagement/CompaniesById";
import StoreByID from "../CompanyManagement/StoreById";
import CompanyRequestByID from "../CompanyRequest/CompanyRequestByID";
import ProductsByID from "../Products/ProductsById";
import SalesVolumePerProduct from "../Reports/SalesVolume/SalesVolumePerProduct";
import ShowStockAvailabilitySelected from "../Reports/Stock Availability/ShowStockAvailabilitySelected";
import SendUserInvite from "../UserManagement/SendUserInvite";
import AddPlatformCompanyUser from "../auth/pages/AddPlatformCompanyUser";
import ViewCountry from "../Settings/CountryConfiguration/ViewCountry";
import PageNotFound from "../pages/404Page";
import ForgottenPassword from "../auth/pages/ForgottenPassword";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsAndConditions from "../pages/TermsAndConditions";
import DeleteUser from "../pages/DeleteUser";

function MyRoutes() {
  const { user, setUser, country, setCountry } = useContext(AuthContext);
  // const [loaded, setLoaded] = useState({});

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    getCountry();
  }, []);

  function getUser() {
    const userString = sessionStorage.getItem("user");
    const userCred = JSON.parse(userString);
    setUser(userCred);
    return userCred;
  }

  function getCountry() {
    const userString = sessionStorage.getItem("country");
    const userCountry = JSON.parse(userString);
    setCountry(userCountry);
    return userCountry;
  }

  function ProtectedRoutes({ user }) {
    let location = useLocation();
    if (!user) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <Outlet />;
  }

  // function Route({ children, ...rest }) {
  //   return <Route {...rest} render={() => (user ? children : <Login />)} />;
  // }
  return (
    <div>
      <Routes>
        <Route
          exact
          path="/reset/password/:token/:email"
          element={<ResetPassword />}
        />
        <Route path="login" element={<Login />} />

        <Route path="/user/delete" element={<DeleteUser />} />
        <Route path="/user/helpandsupport" element={<HelpAndSupport />} />
        <Route path="/privacy/policy" element={<PrivacyPolicy />} />
        <Route path="/terms_and_conditions" element={<TermsAndConditions />} />
        <Route path="/forgotten_password" element={<ForgottenPassword />} />
        <Route
          path="/add/user/:email/:company_id/:branch_id/:role_id/:token"
          element={<AddClientUser />}
        />
        <Route
          path="/company/platform-company/add/user/:email/:company_id/:role_id/:token"
          element={<AddPlatformCompanyUser />}
        />
        <Route
          path="/company/manufacturer/add/user/:email/:company_id/:role_id/:token"
          element={<AddPlatformCompanyUser />}
        />
        <Route element={<ProtectedRoutes user={user} />}>
          <Route path="/user/maps" exact element={<ClientMaps />} />

          <Route exact path="/" element={<Home />} />

          <Route exact path="/user_management" element={<UserManagement />} />
          <Route
            path="/user_management/send_invite"
            element={<SendUserInvite />}
          />

          <Route
            exact
            path="/settings/country_configuration"
            element={<CountryConfig />}
          />

          <Route
            path="/settings/country_configuration/add"
            element={<AddCountry />}
          />

          <Route
            path="/settings/country_configuration/edit/:country"
            element={<EditCountry />}
          />

          <Route
            path="/settings/country_configuration/add_regions/:id?"
            element={<AddRegion />}
          />

          <Route
            path="/settings/country_configuration/:country/regions"
            element={<ViewCountry />}
          />

          <Route
            exact
            path="/reports/stock_availability/:id?"
            element={<StockAvailability />}
          />

          <Route
            path="/reports/stock_availability/branch/:branch_name"
            element={<ShowStockAvailabilitySelected />}
          />

          <Route exact path="/reports/sale_volume" element={<SalesVolume />} />

          <Route
            path="/reports/sale_volume/:product_name"
            element={<SalesVolumePerProduct />}
          />

          <Route path="/reports/sale_value" element={<SalesValue />} />

          <Route exact path="/company_requests" element={<CompanyRequest />} />

          <Route
            path="/company_requests/:company_name"
            element={<CompanyRequestByID />}
          />
          <Route
            path="/companies/manufacturer/add"
            element={<AddManufacturer />}
          />

          <Route
            exact
            path="/companies/:company_type?"
            element={<Companies />}
          />

          <Route
            exact
            path="/company/:company_name"
            element={<CompanyByID />}
          />

          <Route
            path="/company/:company_name/branch/:branch_name"
            element={<StoreByID />}
          />

          <Route path="/products" exact element={<Products />} />

          <Route
            path="/products/product/:product_name"
            element={<ProductsByID />}
          />

          <Route path="/products/add" element={<AddProduct />} />

          <Route exact path="/sales" element={<Sales />} />

          <Route exact path="/announcements" element={<CreateAnnouncement />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default MyRoutes;
