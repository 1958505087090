import React from "react";
import image from "../assets/AFAP-Logo-white 1.png";

const TermsAndConditions = () => {
  return (
    <div className="flex flex-col justify-between w-screen min-h-screen py-20 bg-green-600">
      <div></div>
      <div className="flex items-center ">
        <div className="container mx-auto">
          <div className="flex flex-col items-center justify-center mb-10 space-y-2">
            <div className="p-4 space-x-4 bg-white rounded-lg w-fit">
              <img alt="" className="text-center " src={image} />
            </div>
            <h1 className="text-4xl text-center text-white">
              Terms and Conditions
            </h1>
          </div>
          <div className="space-y-10 text-white ">
            <div className="space-y-4">
              <div>
                <p className="font-semibold">
                  This General Terms and Conditions Agreement is a legal
                  agreement made and entered into as of the date of your
                  affirmative acceptance of this Agreement (the “Effective
                  Date”) by and between You and African Fertilizer and
                  Agribusiness Partnership Inc, an external company registered
                  in Ghana acting through its local manager with registration
                  number ET000732012 and having its registered address at Unit
                  10B First Floor Accra, A&C Square East Legon Accra-Ghana
                  (hereinafter referred to as "The Company")
                </p>
                <p>
                  This General Terms and Conditions Agreement and
                  <a href="https://afap-aims.com/privacy/policy">
                    Privacy Policy
                  </a>
                  shall govern your use of this mobile application or the legal
                  entity on whose behalf you are registering an account. Please
                  read these Terms and Conditions carefully as they affect your
                  rights and liabilities under the law. If you do not agree to
                  these Terms and Conditions, please do not access and or use
                  the mobile application. If you have any questions on the Terms
                  and Conditions, please contact the local manager via email on
                  nmohammed@afap-partnership.org or via telephone on
                  +233242657689. By accessing this mobile application, you are
                  indicating your acceptance of these Terms and Condition, the{" "}
                  <a href="https://afap-aims.com/privacy/policy">
                    Privacy Policy
                  </a>{" "}
                  and any other applicable document.
                </p>
              </div>
              <div className="space-y-4">
                <div className="font-bold">
                  <ol>
                    <li>
                      <span>1. DEFINITIONS&nbsp;</span>
                    </li>
                  </ol>
                </div>
                <p>
                  <span>
                    The definitions and rules of interpretation in this clause
                    apply in this Terms and Conditions.
                  </span>
                </p>
                <div className="space-y-4">
                  <p className="ml-6">
                    1.1. Account &nbsp;means the account you register, open or
                    sign-in on this mobile application to use the Services
                    described in the definition and the company reserve the
                    right to suspend or terminate the Account by reason of
                    inaccurate, untrue, or incomplete information, or failure to
                    comply with the account registration requirements or the
                    law.
                  </p>
                  <p className="ml-6">
                    1.2 Applicable Laws means all laws in force and effect as of
                    the date hereof and which may be enacted or brought into
                    force and effect hereinafter in the Republic of Ghana ,
                    including statutes, rules, regulations, directions,
                    bye-laws, notifications, ordinances and judgments having
                    force of law, or any final interpretation by a court of law
                    having jurisdiction over the matter in question as may be in
                    force and effect during the subsistence of these Terms and
                    Conditions;
                  </p>
                  <p className="ml-6">
                    1.3 Mobile Application means the Inventory Management System
                    created by the Company on which the services defined herein
                    In the definition are provided;
                  </p>
                  <p className="ml-6">
                    1.4 Services means services provided to You by the Company
                    on this mobile application. This mobile application will,
                    track inventory and have a centralized view of sales and
                    products; Improve the time for managing the inventory;
                    Minimize errors in recording inventory, Meet customer
                    expectations by improving delivery and eliminating stock
                    outs; Control cost by making available stock reports for
                    analysis; Generate report on Sales Volume, Sales Value, and
                    Product Availability; and any other legal function or
                    objective that you are able to achieve by use of the mobile
                    application.
                  </p>
                  <p className="ml-6">
                    1.5 User means any person including both natural and
                    artificial persons who register or sign on to use the mobile
                    application for the services provided on the mobile
                    application.
                  </p>
                </div>

                <div>
                  <ol>
                    <li>
                      <strong>
                        <span>2. AMENDMENTS&nbsp;</span>
                      </strong>
                    </li>
                  </ol>
                </div>
                <p>
                  <span>
                    We may update these Terms from time to time for legal or
                    regulatory reasons or for the proper operation of the mobile
                    application. Any changes will be notified to you via the
                    email address, SMS, Whatsapp message or other mode of
                    correspondence provided by you on registration or via a
                    suitable announcement on the mobile application. The changes
                    will apply to the use of this mobile application after we
                    have given notice. If you do not wish to accept the new
                    Terms you should not continue to use the mobile application.
                    If you continue to use the mobile application after the date
                    on which the change comes into effect, your use of the
                    mobile application indicates your agreement to be bound by
                    the new Terms and Conditions.&nbsp;
                  </span>
                </p>
                <div>
                  <ol>
                    <li>
                      <strong>
                        <span>3. INTRODUCTION</span>
                      </strong>
                    </li>
                  </ol>
                </div>
                <p>
                  <span>
                    This mobile application enables You to access the Services
                    particularly described In the definition.&nbsp;
                  </span>
                </p>

                <div>
                  <ol>
                    <li>
                      <strong>
                        <span>4. REGISTRATION</span>
                      </strong>
                    </li>
                  </ol>
                </div>
                <p>
                  <strong>
                    <span>Eligibility</span>
                  </strong>
                </p>
                <div className="ml-6">
                  <span>
                    4.1 This application is open for use to persons over the age
                    18. You may not register on this mobile application if you
                    are under 18 years of age. Any access to or use of our
                    mobile application or our Services by anyone under 18 is
                    unauthorized, unlicensed, and in violation of these Terms
                    and Conditions. By accessing or using the Mobile
                    application, you represent and warrant that you are 18 or
                    older.&nbsp;
                  </span>
                </div>

                <div>
                  <span className="ml-6">
                    4.2 In addition to 4.1 above, registration and/or sign up as
                    a user on this mobile application shall only be conducted by
                    individuals who can form legally binding contracts in Ghana.
                    To access this mobile application, You may be asked to
                    provide certain registration details as further set out in
                    clause 4.7 herein. You are required to provide accurate and
                    complete information.&nbsp;
                  </span>
                </div>

                <div>
                  <ol>
                    <li>
                      <span className="ml-6">
                        4.3 It is a condition of Your use that all the
                        information You provide on this mobile application is
                        accurate, current and complete. If the Company believes
                        that the information You provide is not correct, or
                        complete, the Company has the right to refuse You access
                        to the mobile application or any of its resources, and
                        to terminate or suspend Your access at any time.&nbsp;
                      </span>
                    </li>
                  </ol>
                </div>

                <div className="ml-6">
                  <span>
                    4.4 Notwithstanding Your provision of the information
                    required by the Company, access to the mobile application or
                    the Services remains at the discretion of the Company.&nbsp;
                  </span>
                </div>

                <div className="ml-6">
                  <span>
                    4.5 In order to qualify for the Services, you must satisfy
                    the following legal requirements;&nbsp;
                  </span>
                </div>

                <div className="ml-20">
                  <span>
                    4.5.1 You must have legal capacity to enter into a contract
                    in the Republic of Ghana;
                  </span>
                </div>

                <div className="ml-20">
                  <span>
                    4.5.2 In the event that you are registering on behalf of a
                    company, the company must be incorporated or set-up In
                    accordance with the Companies Act,2019 (Act 992),
                    Incorporated Private Partnerships Act, 1962 (Act 152 of
                    1962) (as amended) or any other required and relevant
                    legislation in the Republic of Ghana.
                  </span>
                </div>

                <div className="ml-20">
                  <span>
                    4.5.3 You must be registered on the Mobile Application. You
                    must satisfy any regulatory requirements required in Ghana
                    for You to access the mobile application.
                  </span>
                </div>
                <p>
                  <strong>
                    <span>Access</span>
                  </strong>
                </p>
                <ol class="decimal_type ml-6">
                  <li>
                    <span>
                      4.6 To access the Services, you must register to create an
                      Account.
                    </span>
                  </li>
                  <li>
                    <span>
                      4.7 When You register for &nbsp;an Account as a user or a
                      company, from time to time thereafter, we may require You
                      to provide and/or confirm information documentation that
                      will allow Us identify You and legal entity you are on
                      boarding &nbsp;such as;&nbsp;
                    </span>
                  </li>
                </ol>
                <p className="ml-20">
                  <span>4.7.1. Your name;&nbsp;</span>
                </p>
                <p className="ml-20">
                  <span>4.7.2. Email address;</span>
                </p>
                <p className="ml-20">
                  <span>4.7.3.&nbsp; &nbsp;Password</span>
                </p>
                <p className="ml-20">
                  <span>4.7.4.&nbsp; &nbsp;Date of birth;</span>
                </p>
                <p className="ml-20">
                  <span>4.7.5.&nbsp; &nbsp;Gender</span>
                </p>
                <p className="ml-20">
                  <span>4.7.6.&nbsp; &nbsp;Home address;</span>
                </p>
                <p className="ml-20">
                  <span>4.7.7.&nbsp; &nbsp;Phone number;&nbsp;</span>
                </p>
                <p className="ml-20">
                  <span>4.7.8.&nbsp; &nbsp;Company Name</span>
                </p>
                <p className="ml-20">
                  <span>4.7.9.&nbsp; &nbsp;Company Email</span>
                </p>
                <p className="ml-20">
                  <span>4.7.10.&nbsp;Company Address</span>
                </p>
                <p className="ml-20">
                  <span>4.7.11.&nbsp;AFAP number</span>
                </p>
                <p className="ml-20">
                  <span>4.7.12.&nbsp;Certificate of Incorporation</span>
                </p>
                <p className="ml-20">
                  <span>
                    4.7.13.&nbsp;Plant Protection and Regulatory Services
                    Certificate
                  </span>
                </p>
                <p className="ml-20">
                  <span>
                    4.7.14.&nbsp;Environmental Protection Agency Certificate
                  </span>
                </p>
                <p className="ml-20">
                  <span>
                    4.7.15.&nbsp;A copy of Your government-issued photo ID, such
                    as national ID, international passport, permanent
                    voter&rsquo;s card or driver&rsquo;s licence;&nbsp;
                  </span>
                </p>
                <p className="ml-20">
                  <span>
                    4.7.16.&nbsp;Such other information and documentation that
                    we may require from time to time.&nbsp;
                  </span>
                </p>

                <p>
                  <strong>
                    <span>User Information</span>
                  </strong>
                </p>
                <p className="ml-6">
                  <span>
                    4.8.&nbsp; &nbsp; &nbsp;&nbsp;After you have registered, you
                    will be sent a five (5) digit verification code to the email
                    address provided in section 4.7. You will enter the
                    verification code into the form displayed on the Account
                    verification page In the Mobile Application. The code
                    expires 60minutes after it has been issued. Should you not
                    receive an email, you can request for a new verification
                    code by selecting the resend verification code button.&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    4.9.&nbsp; &nbsp; &nbsp;&nbsp;When You register to use the
                    mobile application, you will be asked to create a password;
                    to prevent fraud, You must keep this password confidential
                    and must not disclose it or share it with anyone. If You
                    know or suspect that someone else knows Your password, You
                    should notify us by contacting [ghana@afap-partnership.org]
                    immediately.
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    4.10.&nbsp; &nbsp;&nbsp;Your Account shall be used
                    exclusively by You and You shall not transfer Your account
                    to any third party. If You authorize any third party to
                    access or manage Your account on Your behalf, this shall be
                    at Your own risk and the Company shall not be liable to You
                    in any way whatsoever for any loss or liability arising from
                    the use of Your account contrary to the provisions of these
                    Terms.&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    4.11.&nbsp; &nbsp;&nbsp;You must follow reasonable
                    instructions which we give You or publish on our mobile
                    application from time to time which are intended to help You
                    to safely use the Services.&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    4.12.&nbsp; &nbsp;&nbsp;You agree to promptly notify Us if
                    You change Your User Information by updating the Account; If
                    the Company has a reason to believe that there is likely to
                    be a breach of security or misuse of the mobile application,
                    we may require You to change Your password or we may suspend
                    Your Account.&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    4.13.&nbsp; &nbsp;&nbsp;We may perform a background check on
                    You.&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    4.14.&nbsp; &nbsp;&nbsp;If we approve your registration, You
                    will be authorised to use the Services, subject to these
                    Terms and Conditions.
                  </span>
                </p>

                <div>
                  <ol>
                    <li>
                      <strong>
                        <span>
                          5. LICENSE AND USE OF THE MOBILE APPLICATION
                        </span>
                      </strong>
                    </li>
                  </ol>
                </div>
                <p className="ml-6">
                  <span>
                    5.1.&nbsp; &nbsp; &nbsp;&nbsp;Subject to your compliance
                    with the Terms and Conditions, We grant you a limited,
                    non-exclusive, non-sub licensable, revocable and
                    non-transferable license to; access and use the Services in
                    the Republic of Ghana.&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    5.2.&nbsp; &nbsp; &nbsp;&nbsp;You must not use the mobile
                    application in connection with prohibited use or other
                    illegal activity including but not limited to
                    money-laundering, fraud and the funding of terrorist
                    organizations. If we reasonably believe You are using the
                    mobile application in connection with illegal activity or
                    for any fraudulent purpose, or are permitting a third party
                    to do so, we shall report You to the appropriate legal
                    authorities and revoke you&rsquo;re access to the
                    application.&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    5.3.&nbsp; &nbsp; &nbsp;&nbsp;When using the Account or the
                    Services, You must do the following;&nbsp;
                  </span>
                </p>
                <p className="ml-20">
                  <span>
                    5.3.1.&nbsp; &nbsp;comply with these Terms and Conditions as
                    well as all laws applicable in Ghana; provide confirmation
                    of any information You provide to us, including proof of
                    identity;
                  </span>
                </p>
                <p className="ml-20">
                  <span>
                    5.3.2.&nbsp; &nbsp;co-operate in any investigation that we
                    reasonably carry out, or that is carried out by any law
                    enforcement agency, government agency or regulatory
                    authority;&nbsp;
                  </span>
                </p>
                <p className="ml-20">
                  <span>
                    5.3.3.&nbsp; &nbsp;not provide false, inaccurate or
                    misleading information; and&nbsp;
                  </span>
                </p>
                <p className="ml-20">
                  <span>
                    5.3.4.&nbsp; &nbsp;not use an anonymising proxy.&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    5.4.&nbsp; &nbsp; &nbsp;&nbsp;Provided it would not be
                    unlawful for us to do so, and it would not compromise
                    reasonable security measures, we will contact You by phone
                    or email if there is an actual or suspected fraud affecting
                    Your use of the mobile application or a security threat
                    affecting the mobile application or Your Account.&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    5.5.&nbsp; &nbsp; &nbsp;&nbsp;You hereby authorise Us to
                    share, receive, and use data/information collected from your
                    use of the Services with other affiliated third parties in
                    accordance with our Privacy Policy. You also consent to our
                    transferring Your data to recipients in foreign countries to
                    process such data, subject to compliance with our Privacy
                    Policy.&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    5.6.&nbsp; &nbsp; &nbsp;&nbsp;You authorize Us to
                    periodically generate reports on sale volume, sales values
                    and product availability, Map representation of all agro
                    dealers and manage and control companies on boarded unto the
                    mobile application.
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    5.7.&nbsp; &nbsp; &nbsp;&nbsp;Nothing in these Terms and
                    Conditions or in any information provided by the Company as
                    part of the Services covered by these Terms and Condition is
                    intended to be, nor should it be construed to be, legal or
                    other advice. You must consult Your own professional
                    advisers as to the effect of laws which may apply to Your
                    use of the Services. &nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    5.8.&nbsp; &nbsp; &nbsp;&nbsp;You acknowledge and agree that
                    any agreement for sale of goods between users of this mobile
                    application or agro dealers and customers or any agreement
                    procured and secured through the usage of this mobile
                    application is made exclusively without the company being a
                    party to any such agreement. For the avoidance of doubt, the
                    Company is not a party to any legal relationship created by
                    users of this application between users of this application
                    or users and non-users of this application.
                  </span>
                </p>
                <p>
                  <span>
                    6.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <strong>PROHIBITED ACTS</strong>&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    6.1.&nbsp; &nbsp; &nbsp;&nbsp;You are prohibited from
                    performing any of the following acts when using the mobile
                    application;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    6.1.1.&nbsp; &nbsp;use of the mobile application for any
                    purpose that is contrary to Applicable Law;&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    6.1.2.&nbsp; &nbsp;infringement on intellectual property
                    rights, portrait rights, publicity rights, or other rights
                    of the Company, customer or third parties;&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    6.1.3.&nbsp; &nbsp;infringement on the privacy of the
                    Company, Customers, Retailers, Manufacturers and any user
                    information sourced from this application;&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    6.1.4.&nbsp; &nbsp;defamation, insults, or obstruction of
                    business with respect to the Company, or other users of this
                    application;&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    6.1.5.&nbsp; &nbsp;acts that lead to fraud, blackmail, and
                    other criminal activities;&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    6.1.6.&nbsp; &nbsp;sending, transmitting, or recommending
                    harmful computer programs such as computer viruses;&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    6.1.7.&nbsp; &nbsp;deliberate exposure or posting of false
                    data;&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    6.1.8.&nbsp; &nbsp;sending, posing or communicating to the
                    account holders, information deemed by the Company as
                    falling under the following items:&nbsp;
                  </span>
                </p>
                <p className="ml-20">
                  <span>
                    6.1.8.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp;&nbsp;expressions that induce, solicit or encourage
                    suicide or self-injury behaviours, indecent expressions,
                    information about dealing drugs or dangerous drugs, or
                    expression that encourage the use of such drugs;&nbsp;
                  </span>
                </p>
                <p className="ml-20">
                  <span>
                    6.1.8.2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp;&nbsp;promotion and advertisement on religious
                    activities, religious organisations, political activities or
                    political organization;&nbsp;
                  </span>
                </p>
                <p className="ml-20">
                  <span>
                    6.1.8.3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp;&nbsp;information that may have harmful effect on
                    children;&nbsp;
                  </span>
                </p>
                <p className="ml-20">
                  <span>
                    6.1.8.4.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;cruel
                    expressions, sexual expressions and other information that
                    may bring discomfort to others.&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    6.2.&nbsp; &nbsp; &nbsp;&nbsp;In the event You engage in any
                    of the prohibited acts listed in clause 6.1 above, subject
                    to the terms of the agreement between You and the third
                    party partners, the Company reserves the right to
                    immediately terminate your account on the mobile
                    application; and You agree to indemnify the Company to the
                    full extent of any liability arising from Your engagement in
                    any prohibited acts listed in clause 6.1. above.&nbsp;
                  </span>
                </p>

                <div>
                  <ol>
                    <li>
                      <strong>
                        <span>7. PRIVACY STATEMENT</span>
                      </strong>
                    </li>
                  </ol>
                </div>
                <p>
                  <span>
                    Your use of this mobile application signifies Your
                    continuing consent to the Company&rsquo;s [
                    <span>Privacy Policy, insert hyperlink]</span> which You can
                    examine any time by clicking on the &ldquo;privacy&rdquo;
                    link. Personal information that You supply to the Company
                    and any information about Your use of the mobile application
                    that we obtain will be subject to the Company&rsquo;s
                    Privacy Statement on this Mobile application.&nbsp;
                  </span>
                </p>
                <div>
                  <ol>
                    <li>
                      <strong>
                        <span>8. AVAILABILITY OF THE MOBILE APPLICATION</span>
                      </strong>
                      <span>&nbsp;&nbsp;</span>
                    </li>
                  </ol>
                </div>
                <p className="ml-6">
                  <span>
                    8.1.&nbsp; &nbsp; &nbsp;&nbsp;Although we aim to offer You
                    the best service possible, we make no promise that the
                    mobile application will meet Your requirements. We cannot
                    guarantee that the Services will be fault-free. If a fault
                    occurs with the mobile application. You should report it to
                    the local manager through email at
                    nmohammed@afap-partnership.org and we will attempt to
                    correct the fault as soon as we reasonably can.
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    8.2.&nbsp; &nbsp; &nbsp;&nbsp;We do not guarantee that the
                    Services will work seamlessly with your mobile device. The
                    usage of our Services is contingent upon the terms outlined
                    in your agreements with the manufacturer of your mobile
                    device. It is not permissible to utilize a modified device
                    for accessing the Services if such modifications violate the
                    manufacturer&apos;s guidelines for software or hardware,
                    which includes actions like disabling hardware or software
                    controls, commonly known as &quot;jailbreaking.&quot;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    8.3.&nbsp; &nbsp; &nbsp;&nbsp;Your access to the mobile
                    application may be occasionally restricted to allow for
                    repairs, maintenance or the introduction of new facilities
                    or services. We will attempt to restore the service as soon
                    as we reasonably can.&nbsp;
                  </span>
                </p>
                <div>
                  <ol>
                    <li>
                      <strong>
                        <span>9. PROPRIETARY INFORMATION</span>
                      </strong>
                    </li>
                  </ol>
                </div>
                <p className="ml-6">
                  <span>
                    9.1.&nbsp; &nbsp; &nbsp;&nbsp;The material and content
                    accessible from this mobile application, and any other
                    worldwide web mobile application owned, operated, licensed
                    or controlled by the Company is the proprietary information
                    of the Company. The Company retains all rights, title, and
                    interest in the content. Accordingly, the content may not be
                    copied, distributed, republished, uploaded, posted or
                    transmitted in any way without the prior written consent of
                    the Company, except that You may print out a copy of the
                    Content solely for Your personal use. In doing so, You may
                    not remove or alter, or cause to be removed or altered, any
                    copyright, trademark, trade name, service mark, or any other
                    proprietary notice or legend appearing on any of the
                    Content. Modification or use of the content except as
                    expressly provided in these Terms and Conditions violates
                    the Company&rsquo;s intellectual property rights. Neither
                    title nor intellectual property rights are transferred to
                    You by access to this Mobile application.&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    9.2.&nbsp; &nbsp; &nbsp;&nbsp;The Company may scan,
                    transcribe and publish online, Your listings, logos and
                    other materials required. You grant to the Company a
                    royalty- free, perpetual, unrestricted license to use and
                    distribute any materials provided by You, for the purpose of
                    advertising the Company&rsquo;s services.&nbsp;
                  </span>
                </p>

                <div>
                  <ol>
                    <li>
                      <strong>
                        <span>10. TRADEMARK&nbsp;</span>
                      </strong>
                    </li>
                  </ol>
                </div>
                <p>
                  <span>
                    Trademarks, service marks, and logo appearing in this mobile
                    application are the property of the Company or the party
                    that provided the trademarks, service marks and logos to the
                    Company. The Company and any party that provided trademarks,
                    service marks and logos to the Company retain all rights
                    with respect to any of their respective trademarks, service
                    marks, and logos appearing in this Mobile application.&nbsp;
                  </span>
                </p>
                <p>
                  <span>&nbsp;</span>
                </p>
                <div>
                  <ol>
                    <li>
                      <strong>
                        <span>
                          11.THE COMPANY&rsquo;S RIGHT TO SUSPEND OR CANCEL YOUR
                          REGISTRATION
                        </span>
                      </strong>
                    </li>
                  </ol>
                </div>
                <p className="ml-6">
                  <span>
                    11.1.&nbsp; &nbsp;&nbsp;We may suspend or cancel Your
                    registration immediately at our reasonable discretion or if
                    You breach any of Your obligations under these Terms and
                    Conditions or if You engage in any of the prohibited acts
                    listed in clause 6.1.&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    11.2.&nbsp; &nbsp;&nbsp;You can cancel Your registration at
                    any time by informing us in writing at{" "}
                    <strong>ghana@afap-partnership.org</strong> If You do so,
                    You must immediately stop using the mobile
                    application.&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    11.3.&nbsp; &nbsp;&nbsp;The suspension or cancellation of
                    Your registration and Your right to use the mobile
                    application shall not affect our statutory or contractual
                    rights against You. &nbsp;
                  </span>
                </p>
                <p>
                  <span>&nbsp;</span>
                </p>
                <div>
                  <ol>
                    <li>
                      <strong>
                        <span>
                          12. LIMITATION OF LIABILITIES AND DISCLAIMER
                        </span>
                      </strong>
                    </li>
                  </ol>
                </div>
                <p className="ml-6">
                  <span>
                    12.1.&nbsp; &nbsp;&nbsp;
                    <strong>
                      THE MOBILE APPLICATION AND ALL INFORMATION, CONTENT,
                      MATERIALS, PRODUCTS AND OTHER SERVICES INCLUDED ON OR
                      OTHERWISE MADE AVAILABLE TO YOU THROUGH THE MOBILE
                      APPLICATION ARE PROVIDED BY THE COMPANY ON AN &quot;AS
                      IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, UNLESS
                      OTHERWISE SPECIFIED IN WRITING. THE COMPANY MAKES NO
                      REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR
                      IMPLIED, AS TO THE OPERATION OF THE APP, OR THE
                      INFORMATION, CONTENT, MATERIALS, PRODUCTS OR OTHER
                      SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU
                      THROUGH THE APP, UNLESS OTHERWISE SPECIFIED IN WRITING.
                      YOU EXPRESSLY AGREE THAT YOUR USE OF THE APP IS AT YOUR
                      SOLE RISK.
                    </strong>
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    12.2.&nbsp; &nbsp;&nbsp;
                    <strong>
                      TO THE FULL EXTENT PERMISSIBLE BY LAW, THE COMPANY
                      DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING,
                      BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY
                      AND FITNESS FOR A PARTICULAR PURPOSE. THE COMPANY DOES NOT
                      WARRANT THAT THE APP, INFORMATION, CONTENT, MATERIALS,
                      PRODUCTS OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE
                      AVAILABLE TO YOU THROUGH THE APP, OR ELECTRONIC
                      COMMUNICATIONS SENT FROM THE COMPANY ARE FREE OF VIRUSES
                      OR OTHER HARMFUL COMPONENTS. TO THE FULL EXTENT
                      PERMISSIBLE BY THE APPLICABLE LAW, THE COMPANY WILL NOT BE
                      LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF
                      THE APP, OR FROM ANY INFORMATION, CONTENT, MATERIALS,
                      PRODUCTS OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE
                      AVAILABLE TO YOU THROUGH THE APP, INCLUDING, BUT NOT
                      LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND
                      CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN
                      WRITING.
                    </strong>
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    12.3.&nbsp; &nbsp;&nbsp;You understand that the Company
                    cannot and does not guarantee or warrant that files
                    available for downloading from the mobile application will
                    be free of viruses, worms, Trojan horses, or other code that
                    may manifest contaminating or destructive properties.
                    However, the Company will take all reasonable measures to
                    prevent and/or address any form of virus attack reported by
                    You. The Company does not assume any responsibility or risk
                    for Your use of the mobile application.&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    12.4.&nbsp; &nbsp;&nbsp;
                    <strong>
                      THE COMPANY, IT&rsquo;S SUBSIDIARIES, AFFILIATES,
                      LICENSORS, SERVICE PROVIDERS, CONTENT PROVIDERS,
                      EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS WILL NOT BE
                      LIABLE FOR ANY INCIDENTAL, DIRECT, INDIRECT, PUNITIVE,
                      ACTUAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR OTHER
                      DAMAGES, EVEN IF THE COMPANY HAS BEEN ADVISED OF THE
                      POSSIBILITY OF SUCH DAMAGES.
                    </strong>
                    &nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    12.5.&nbsp; &nbsp;&nbsp;
                    <strong>
                      SHOULD A USER COMMIT FRUAD BY REGISTERING IN THE NAME OF A
                      PERSON OR AN ORGANIZATION WHO DID NOT CONSENT TO BE
                      REGISTERED, THE INDIVIDUAL WHO REGISTERED WITH THE FALSE
                      INFORMATION, REFERRED TO IN THIS CASE AS THE INTENDED
                      USER, AGREES TO INDEMNIFY THE COMPANY OF ANY POTENTIAL
                      LOSSES OR DAMAGES THAT ACCRUES AS A RESULT OF THE INTENDED
                      USER&rsquo;S ACTIONS&nbsp;
                    </strong>
                  </span>
                </p>
                <p>
                  <strong>
                    <span>&nbsp;</span>
                  </strong>
                </p>
                <div>
                  <ol>
                    <li>
                      <strong>
                        <span>13. SECURITY</span>
                      </strong>
                    </li>
                  </ol>
                </div>
                <p>
                  <span>
                    You are prohibited from using any services or facilities
                    provided in connection with this mobile application to
                    compromise security or tamper with system resources and or
                    accounts. The use or distribution of tools designed for
                    compromising security (e.g, password guessing programs,
                    cracking tools, or network probing tools) is strictly
                    prohibited. If You become involved in any violation of
                    system security, the Company reserves the right to
                    investigate suspected violations of Terms of Use.&nbsp;
                  </span>
                </p>
                <p>
                  <span>
                    The Company reserves the right to fully cooperate with any
                    law enforcement authorities or court order requesting or
                    directing the Company to disclose the identity of anyone
                    posting any e-mail messages or publishing or otherwise
                    making available any materials that are believed to violate
                    these Terms and Conditions.{" "}
                    <strong>
                      BY ACCEPTING THESE TERMS AND CONDITIONS YOU WAIVE AND HOLD
                      HARMLESS THE COMPANY FROM ANY CLAIMS RESULTING FROM OR ANY
                      ACTION TAKEN BY THE COMPANY DURING OR AS A RESULT OF ITS
                      INVESTIGATIONS BY EITHER OR LAW ENFORCEMENT
                      AUTHORITIES.&nbsp;
                    </strong>
                  </span>
                </p>
                <p>
                  <strong>
                    <span>&nbsp;</span>
                  </strong>
                </p>
                <div>
                  <ol>
                    <li>
                      <strong>
                        <span>14. INDEMNITY</span>
                      </strong>
                    </li>
                  </ol>
                </div>
                <p>
                  <span>
                    You will indemnify and hold the Company, its subsidiaries,
                    affiliates, licensors, content providers, service providers,
                    employees, agents, officers, directors and contractors (the
                    indemnified parties) harmless from any breach of these Terms
                    and Conditions by You, including any use of content other
                    than as expressly authorized in these Terms and Conditions.
                    You agree that the indemnified parties will have no
                    liability in connection with any such breach or unauthorized
                    use, and you agree to indemnify any and all resulting loss,
                    damages, judgments, awards, costs, expenses and attorney
                    fees of the indemnified parties in connection therewith. You
                    will also indemnify and hold the indemnified parties
                    harmless from and against any claims brought by third
                    parties arising out of Your use of the information accessed
                    from this Mobile application.
                  </span>
                </p>
                <p>
                  <span>&nbsp;</span>
                </p>
                <div>
                  <ol>
                    <li>
                      <strong>
                        <span>15. TERMS AND TERMINATION</span>
                      </strong>
                      <span>&nbsp;</span>
                    </li>
                  </ol>
                </div>
                <p className="ml-6">
                  <span>
                    15.1.&nbsp; &nbsp;&nbsp;This Agreement is valid as soon as
                    You agree to these Terms and Conditions, or use the service
                    provided by the mobile application and remains valid
                    indefinitely, until termination by either party. Termination
                    can occur at any time, within a period of notice of four (4)
                    weeks, in writing, by email, post or fax. The right to
                    immediate termination by the Company remains
                    unaffected.&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    15.2.&nbsp; &nbsp;&nbsp;The right to immediate termination
                    by the Company in particular covers the case where You
                    engage in prohibited acts on the Mobile application.
                    Repeatedly providing misleading information or withholding
                    information required to present Your item is also a ground
                    for immediate termination. Typos, mistakes and transmission
                    errors are excluded from this, as long as they are not
                    caused with intent or by gross negligence.&nbsp;
                  </span>
                </p>
                <p>
                  <span>&nbsp;</span>
                </p>
                <div>
                  <ol>
                    <li>
                      <strong>
                        <span>6. DISPUTE RESOLUTION</span>
                      </strong>
                    </li>
                  </ol>
                </div>
                <p className="ml-6">
                  <span>
                    16.1.&nbsp; &nbsp;&nbsp;These Terms and Conditions will be
                    subject to the laws of the Republic of Ghana. We will try to
                    solve any disagreements quickly and efficiently. You agree
                    that any dispute or claims that You may have against us will
                    in the first instance be attempted to be settled amicably by
                    the Parties.
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    16.2.&nbsp; &nbsp;&nbsp; If the dispute, difference or claim
                    is not settled amicably within 30 working days (thirty)
                    days, the dispute, difference or claim shall be referred to
                    a mediator. You shall bear your own costs and would be
                    liable to contribute the same amount in respect of fees to
                    be paid to the mediator. If mediation is not concluded
                    within 3 (three) months of commencement of the mediation,
                    You reserve Your right to seek redress in the High Court of
                    Accra.
                  </span>
                </p>
                <p>
                  <span>&nbsp;</span>
                </p>
                <div>
                  <ol>
                    <li>
                      <strong>
                        <span>17. MISCELLANEOUS</span>
                      </strong>
                    </li>
                  </ol>
                </div>
                <p className="ml-6">
                  <span>
                    17.1.&nbsp; &nbsp;&nbsp;You may not transfer any of Your
                    rights under these Terms and Conditions to any other person.
                    We may transfer our rights under these Terms and Conditions
                    to another business where we reasonably believe Your rights
                    will not be affected.&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    17.2.&nbsp; &nbsp;&nbsp;If You breach these Terms and
                    Conditions and the Company chooses to ignore this, this will
                    not constitute or be deemed to be a waiver by the Company of
                    these Terms and Conditions or its rights under applicable
                    law.&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    17.3.&nbsp; &nbsp;&nbsp;The Company shall not be responsible
                    for any breach of these Terms and Conditions caused by
                    circumstances beyond its reasonable control.&nbsp;
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    17.4.&nbsp; &nbsp;&nbsp;This Terms and Conditions is a
                    complete statement of the agreement between you and the
                    Company regarding the Services use.
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    17.5.&nbsp; &nbsp;&nbsp;If any provision of this terms and
                    condition is invalid or unenforceable under applicable law,
                    then it will be changed and interpreted to accomplish the
                    objectives of such provision to the greatest extent possible
                    under applicable law, and the remaining provisions will
                    continue in full force and effect.
                  </span>
                </p>
                <p className="ml-6">
                  <span>
                    17.6.&nbsp; &nbsp;&nbsp;This Terms and Conditions do not
                    limit any rights that we may have under trade secret,
                    copyright, patent, or other laws. No waiver of any term of
                    this Terms and Conditions will be deemed a further or
                    continuing waiver of such term or any other term.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
