import React, { useEffect, useState } from "react";
import Template from "../components/template";
import axios from "axios";
import { Link } from "react-router-dom";
import CompanyRequestByID from "./CompanyRequestByID";
import Pagination from "../components/pagination";
import Table from "../components/table";
import Loading from "../components/Loading";
import { useGetCompanyRequests } from "../CompanyManagement/CompanyService";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export default function CompanyRequest() {
  const [country, setCountry] = useState(null);
  const [selected, setSelected] = useState(null);
  const [pagination, setPagination] = useState(1);

  function getCountry() {
    const userString = sessionStorage.getItem("country");
    const userCountry = JSON.parse(userString);
    return userCountry;
  }
  getCountry();

  const [companiesData, metaData, dataLoading, error] = useGetCompanyRequests(
    getCountry().id,
    pagination
  );

  return (
    <Template>
      <div className="w-full mt-10  p-4 text-md">
        <h1 className="text-center text-4xl mb-8 text-green-700">
          Company Request
        </h1>
        {dataLoading ? (
          <Loading text={"Company Requests"} />
        ) : companiesData && companiesData.length > 0 ? (
          <Table>
            <div className="align-middle  inline-block min-w-full shadow  bg-white shadow-dashboard px-8 pt-3 rounded-bl-lg rounded-br-lg">
              <div className="table-height overflow-y-scroll">
                <table className="min-w-full ">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-green-600 tracking-wider">
                        ID
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                        Company Name
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                        Email
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                        Company Type
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                        Status
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                        AFAP Number
                      </th>

                      <th className="px-6 py-3 border-b-2 border-gray-300"></th>
                    </tr>
                  </thead>
                  <tbody className="bg-white  ">
                    {companiesData &&
                      companiesData.map((company, index) => {
                        return (
                          <tr>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                              <div className="flex items-center">
                                <div>
                                  <div className="text-sm leading-5 text-gray-800">
                                    {(pagination - 1) * 15 + index + 1}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                              <div className="text-sm leading-5 text-green-900">
                                {company.company.name}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5">
                              {company.company.email}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5">
                              {company.company.type.type}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b text-green-900 border-gray-500 text-sm leading-5">
                              {company.is_approved === 1 && (
                                <span className="relative inline-block px-3 py-1 font-semibold text-green-600 leading-tight">
                                  <span
                                    aria-hidden
                                    className="absolute inset-0 bg-green-200  opacity-50 rounded-full"
                                  ></span>
                                  <span className="relative text-xs">
                                    accepted
                                  </span>
                                </span>
                              )}
                              {company.is_approved === 0 && (
                                <span className="relative inline-block px-3 py-1 font-semibold text-red-600 leading-tight">
                                  <span
                                    aria-hidden
                                    className="absolute inset-0 bg-red-200  opacity-50 rounded-full"
                                  ></span>
                                  <span className="relative text-xs">
                                    declined
                                  </span>
                                </span>
                              )}
                              {company.is_approved === null && (
                                <span className="relative inline-block px-3 py-1 font-semibold text-yellow-600 leading-tight">
                                  <span
                                    aria-hidden
                                    className="absolute inset-0 bg-yellow-200  opacity-50 rounded-full"
                                  ></span>
                                  <span className="relative text-xs">
                                    pending
                                  </span>
                                </span>
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500 text-green-900 text-sm leading-5">
                              {company.company.afap_number}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-500 text-sm leading-5">
                              <div className=" border-green-600 border text-green-600 rounded transition duration-300 hover:bg-green-600 hover:text-white focus:outline-none">
                                <Link
                                  to={{
                                    pathname: `/company_requests/${company.company.name}`,
                                    state: company,
                                  }}
                                  className="w-full text-center justify-center flex px-3 py-2 h-full"
                                >
                                  View Details
                                </Link>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>

              <Pagination metaData={metaData} setPagination={setPagination} />
            </div>
          </Table>
        ) : (
          <p className="text-center text-green-900 text-sm leading-5">
            No company requests received
          </p>
        )}
      </div>
    </Template>
  );
}
