import React, { useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import image from "../assets/AFAP-Logo-white 1.png";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export default function DeleteUser() {
  const [error, setError] = useState();
  const [email, setEMail] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("/login", {
        email,
        password,
      });

      const data = response.data;

      if (data) {
        const authToken = `Bearer ${data.token}`;
        axios.defaults.headers.common["Authorization"] = authToken;

        await axios.post("/users/account/delete");
        navigate("/login");
      }
    } catch (error) {
      setError("Email or Password is Invalid");
    }
  };

  return (
    <div className="flex flex-col justify-between w-screen min-h-screen bg-green-600">
      <div></div>
      <div className="flex items-center ">
        <div className="container mx-auto">
          <div className="flex items-center justify-center ">
            <div className="p-4 bg-white rounded-lg ">
              <img alt="" className="text-center " src={image} />
            </div>
          </div>
          <div className="max-w-md py-4 mx-auto my-10 bg-white border rounded-lg shadow">
            <div className="px-4 text-center">
              <h1 className="my-3 text-3xl font-semibold text-gray-700 ">
                DeleteUser
              </h1>
              <p className="mb-2 text-gray-500">
                If you're sure that you want to delete your account, please
                enter your username and password below, then press "Continue".
              </p>
              <p className="text-gray-500 ">
                Your account will be deactivated, which means you will lose all
                data attached ot this account.
              </p>
            </div>
            <div className="m-7">
              <form onSubmit={handleSubmit} autoComplete="off">
                {error && <p className="mb-6 text-red-500">{error}</p>}
                <div className="mb-6">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm text-gray-600 "
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="you@company.com"
                    onChange={(e) => setEMail(e.target.value)}
                    className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-100 focus:border-green-300 "
                  />
                </div>
                <div className="mb-6">
                  <label htmlFor="password" className="text-sm text-gray-600 ">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Your Password"
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-100 focus:border-green-300 "
                  />
                </div>
                <div className="mb-6">
                  <button
                    type="submit"
                    className="w-full px-3 py-4 text-white bg-green-500 rounded-md focus:bg-green-800 focus:outline-none"
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <footer className="flex items-center justify-around w-full py-4 font-semibold text-white capitalize ">
        <a className="hover:underline" href="/user/helpandsupport">
          Help and Support
        </a>
        <a className="hover:underline" href="/privacy/policy">
          Privacy Policy
        </a>
        <a className="hover:underline" href="/terms_and_conditions">
          Terms and Conditions
        </a>
      </footer>
    </div>
  );
}
