import { useEffect, useState } from "react";
import { getApi } from "../hooks/getApi";

export function useGetTotalUsers(user, countryId) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userTotal, setUserTotal] = useState(null);

  useEffect(() => {
    const getUsersTotal = async () => {
      const data = await getApi(
        `admin/users/total/count/${countryId}`,
        setDataLoading
      );

      if (data) {
        setUserTotal(data.data);
      }
    };
    getUsersTotal();
  }, [user]);

  return [userTotal, dataLoading, error];
}
export function useGetTotalCompanyTypes(user, countryId) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [companyTotal, setCompanyTotal] = useState([]);

  useEffect(() => {
    const getTotalCompanyTypes = async () => {
      const data = await getApi(
        `admin/reports/company-type/total/${countryId}`,
        setDataLoading
      );

      if (data) {
        setCompanyTotal(data.data);
      }
    };
    getTotalCompanyTypes();
  }, [user]);

  return [companyTotal, dataLoading, error];
}
export function useGetTotalSales(user, countryId) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [saleAggregate, setSaleAggregate] = useState([
    {
      symbol: "",
      total: 0,
      total_credit_sales_amount: 0,
    },
  ]);

  useEffect(() => {
    const getSaleTotal = async () => {
      const data = await getApi(
        `admin/reports/sales/total?country_id=${countryId}`,
        setDataLoading
      );

      if (data) {
        setSaleAggregate(data.data);
      }
    };
    getSaleTotal();
  }, [user]);

  return [saleAggregate, dataLoading, error];
}
export function useGetTotalProducts(user) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalProductCount, setTotalProductCount] = useState(null);

  useEffect(() => {
    const getSaleTotal = async () => {
      const data = await getApi(
        `admin/reports/products/total-count/`,
        setDataLoading
      );

      if (data) {
        setTotalProductCount(data.data);
      }
    };
    getSaleTotal();
  }, [user]);

  return [totalProductCount, dataLoading, error];
}
