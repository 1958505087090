import React, { useEffect, useState } from "react";
import axios from "axios";
import Template from "../../components/template";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddRegion from "./AddRegion";
import AddDistrict from "./AddDIstrict";

export default function ViewCountry() {
  const history = useNavigate();
  const location = useLocation();
  const country = location.state;
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState({ region: "", districts: [] });

  useEffect(() => {
    function getUser() {
      const userString = sessionStorage.getItem("user");
      const userCred = JSON.parse(userString);
      return userCred;
    }
    getUser();

    axios.defaults.headers.common["Authorization"] = `Bearer ${
      getUser().token
    }`;
    const getAllRegions = async () => {
      const data = await axios
        .get(`admin/countries/${country.id}/regions`)
        .then((response) => {
          return response.data.data;
        })
        .catch((err) => {
          console.log("regions error", err?.response?.data?.message);
        });
      setRegions(data);
    };
    getAllRegions();
  }, []);

  return (
    <Template>
      <div className="w-full mt-10  p-4 text-md">
        <h1 className="text-center text-4xl mb-8 text-green-700">
          Country Configuration
        </h1>
        <AddRegion country={country} />

        <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 pr-10  lg:px-8">
          <div className="align-middle rounded-tl-lg rounded-tr-lg inline-block w-full py-4 overflow-hidden bg-white shadow-lg px-12"></div>
          <div className="align-middle  inline-block min-w-full shadow  bg-white shadow-dashboard px-8 pt-3 rounded-bl-lg rounded-br-lg">
            <div className="table-height overflow-y-scroll">
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-green-600 tracking-wider">
                      ID
                    </th>
                    <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider">
                      Region
                    </th>

                    <th className="px-6 py-3 border-b-2 border-gray-300"></th>
                  </tr>
                </thead>
                <tbody className="bg-white w-full ">
                  {regions &&
                    regions.map((region) => {
                      return (
                        <>
                          <tr>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                              <div className="flex items-center">
                                <div>
                                  <div className="text-sm leading-5 text-gray-800">
                                    {region.id}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                              <div className="text-sm leading-5 text-green-900">
                                {region.name}
                              </div>
                            </td>

                            <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-500 text-sm leading-5">
                              <button
                                onClick={async () => {
                                  const data = await axios
                                    .get(
                                      `admin/countries/regions/${region.id}/districts`
                                    )
                                    .then((response) => {
                                      return response.data.data;
                                    })
                                    .catch((err) => {
                                      console.log(err?.response?.status);
                                      console.log(
                                        "districts error",
                                        err?.response?.data?.message
                                      );
                                    });
                                  setDistricts({
                                    region: region.id,
                                    districts: data,
                                  });
                                }}
                                className="px-5 py-2 border-green-600 border text-green-600 rounded transition duration-300 hover:bg-blue-700 hover:text-white focus:outline-none"
                              >
                                View Districts
                              </button>
                            </td>
                          </tr>
                          {districts.region == region.id ? (
                            <>
                              <tr className="px-6 w-full">
                                <td
                                  colSpan="12"
                                  className="px-6 py-4 whitespace-no-wrap border-b border-gray-500"
                                >
                                  <div className="w-full flex justify-start ">
                                    <div
                                      className="flex rounded bg-red-800 px-4 cursor-pointer -mb-4 py-2 text-white justify-end"
                                      onClick={() =>
                                        setDistricts({
                                          region: "",
                                          districts: [],
                                        })
                                      }
                                    >
                                      <svg
                                        className="w-6 h-6 mr-2"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          stroke-width="2"
                                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                        ></path>
                                      </svg>
                                      Close
                                    </div>
                                  </div>

                                  <AddDistrict
                                    id={region.id}
                                    country={country}
                                  />

                                  {districts && districts.districts.length !== 0
                                    ? districts.districts.map(
                                        (district, index) => {
                                          return (
                                            <div className="w-full flex mb-1 whitespace-no-wrap border-b border-gray-500 py-4">
                                              <p className="mr-1">
                                                {index + 1}.
                                              </p>
                                              <p>{district.name}</p>
                                            </div>
                                          );
                                        }
                                      )
                                    : null}
                                </td>
                              </tr>
                            </>
                          ) : null}
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="sm:flex-1 sm:flex sm:items-center sm:justify-end mt-4 work-sans py-4">
              {/* <div>
                  <p className="text-sm leading-5 text-green-700">
                    Showing
                    <span className="font-medium"> 1 </span>
                    to
                    <span className="font-medium"> 200 </span>
                    of
                    <span className="font-medium"> 2000 </span>
                    results
                  </p>
                </div> */}
              <div>
                <nav className="relative z-0 inline-flex shadow-sm">
                  <div>
                    {/* {metaData &&
                      metaData.links.map((link, index) =>
                        link.url ? (
                          <button
                            key={index}
                            // href={link.url}
                            onClick={() => {
                              let page_number = link.url.split("page=");
                              setPagination(page_number[1]);
                            }}
                            className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-green-700 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-tertiary active:text-gray-700 transition ease-in-out duration-150 hover:bg-tertiary"
                          >
                            {link.label}
                          </button>
                        ) : (
                          <button
                            key={index}
                            // href="null"
                            className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-green-700 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-tertiary active:text-gray-700 transition ease-in-out duration-150 hover:bg-tertiary"
                          >
                            {link.label}
                          </button>
                        )
                      )} */}
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
}
