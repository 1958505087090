import { useEffect, useState } from "react";
import { getApi } from "../hooks/getApi";

export function useGetMapBranches(selectedCountry, search, limit) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(null);
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    const getBranches = async () => {
      const data = await getApi(
        `admin/${selectedCountry}/branches?search=${search}&limit=${limit}`,
        setDataLoading
      );
      if (data) {
        setBranches(data.data);
      }
    };

    if (selectedCountry) {
      getBranches();
    }
  }, [selectedCountry, search]);
  return [branches, dataLoading, error];
}
